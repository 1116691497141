import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0597F2",
        secondary: "#ff5a00",
        success: "#1DBF04"
      }
    },
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
});
