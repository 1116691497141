import { render, staticRenderFns } from "./Walkthough.vue?vue&type=template&id=154ede6c&scoped=true"
import script from "./Walkthough.vue?vue&type=script&lang=js"
export * from "./Walkthough.vue?vue&type=script&lang=js"
import style0 from "./Walkthough.vue?vue&type=style&index=0&id=154ede6c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "154ede6c",
  null
  
)

export default component.exports