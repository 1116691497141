<template>
  <v-container>
    <div class="d-flex align-center mx-5 contest-header ml-0">
      <h2 class="mr-5 contest-heading">Find the best Contests</h2>
      <div
        class=""
        style="width: 300px"
        v-if="user && user.roles[0].role_name === 'admin'"
      >
        <v-select
          label=""
          hide-details
          dense
          outlined
          :items="contestTypeList"
          v-model="selectedContestType"
          @change="filterContestsByType"
        ></v-select>
      </div>
      <v-btn
        color="primary"
        @click="createContestDialog = true"
        v-if="user && user.roles[0].role_name === 'admin'"
        class="create-contest-btn"
      >
        <v-icon class="mr-2">mdi-plus-circle</v-icon>
        New contest
      </v-btn>
    </div>
    <loader v-if="loading"></loader>
    <empty-state
      v-else-if="contests.length === 0"
      :imgWidth="500"
    ></empty-state>
    <div
      class="contests-row d-flex mt-10 mx-2 flex-wrap"
      v-if="viewType === 'card'"
    >
      <contest-card
        v-for="(contest, index) in contests"
        :key="index"
        :contest="contest"
        class="contest-card"
        :user="user"
        @edit="editContest"
        @publish="publishContest"
        @delete="deleteContest"
        :loading="loading"
        @refresh="getContests"
        @showHideWinners="showHideWinners"
      ></contest-card>
    </div>
    <div class="contests-row d-flex flex-wrap mt-10" v-else>
      <public-card
        v-for="(contest, index) in contests"
        :key="index"
        :contest="contest"
        class="contest-card"
        :user="user"
        :loading="loading"
        @view-contest="viewContest"
      ></public-card>
    </div>
    <create-new-contest
      v-if="createContestDialog"
      @close="handleCreateContestDialogClose"
      :visible="createContestDialog"
      :contest="contest"
      @create="createContest"
      :loading="loading"
      :isEdit="isEdit"
      @update="updateContest"
    ></create-new-contest>
    <confirm-dialog
      @confirm="handleConfirm"
      :visible="confirmDialog"
      @close="confirmDialog = false"
      :text="confirmDialogText"
    ></confirm-dialog>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useAuthStore } from "../../store/auth.store";
import CreateNewContest from "../../components/modals/contests/CreateNewContest.vue";
import { handleError } from "../../common/api/api.middleware";
import { uploadFile } from "../../helpers/file-upload";
import { NOTIFICATION_TYPE } from "../../common/constants";
import { showToastNotification } from "../../plugins/vue-toast-notifications";
import {
  addDoc,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { contestsRef } from "../../config/firebase";
import ContestCard from "./ContestCard.vue";
import ConfirmDialog from "../../components/shared/ConfirmDialog.vue";
// import ListView from "./views/ListView.vue";
import Loader from "../../components/shared/Loader.vue";
import EmptyState from "../../components/shared/EmptyState.vue";
import PublicCard from "./PublicCard.vue";
export default {
  components: {
    CreateNewContest,
    ContestCard,
    ConfirmDialog,
    // ListView,
    Loader,
    EmptyState,
    PublicCard,
  },
  computed: {
    ...mapState(useAuthStore, ["user"]),
  },
  created() {
    if (this.user && this.user.roles[0].role_name === "admin") {
      this.viewType = this.$route.query.view || "card";
    } else {
      this.viewType = this.$route.query.view || "list";
    }
    this.$router.push({ query: { view: this.viewType } });
    this.getContests();
  },
  data() {
    return {
      contests: [],
      createContestDialog: false,
      contest: {
        title: "",
        description: "",
        rules: "",
        startDate: "",
        lastDateToRegister: "",
        isActive: true,
        entryFee: "",
        bannerImg: "",
        prizeMoney: "",
        resultDate: "",
        productPrize: null,
        judge: {
          name: "",
          description: "",
          image: "",
        },
        roundStatus: "first-round-not-started",
      },
      loading: false,
      isEdit: false,
      confirmDialog: false,
      contestId: null,
      confirmDialogText: "",
      viewType: "list",
      contestTypeList: [
        { text: "Active Contests", value: "active" },
        { text: "Pending Result", value: "pending-result" },
        { text: "History", value: "history" },
      ],
      selectedContestType: "active",
      confirmDialogKey: "",
    };
  },
  methods: {
    async createContest() {
      try {
        this.loading = true;
        if (typeof this.contest.bannerImg === "object") {
          this.contest.bannerImg = await uploadFile(
            this.contest.bannerImg,
            "contests"
          );
        }

        if (typeof this.contest.judge.image === "object") {
          this.contest.judge.image = await uploadFile(
            this.contest.judge.image,
            "contests"
          );
        }
        this.contest.createdAt = new Date();
        await addDoc(contestsRef, this.contest);
        showToastNotification("Contest created.", NOTIFICATION_TYPE.SUCCESS);
        this.loading = false;
        this.isEdit = false;

        this.createContestDialog = false;
        this.contest = {};
        this.getContests();
      } catch (error) {
        handleError(error);
        this.loading = false;
      }
    },
    async getContests(type) {
      if (type) {
        this.filterContestsByType(type);
        return;
      }
      this.loading = true;
      this.contests = [];
      const role = this.user ? this.user.roles[0].role_name : null;
      if (role === "admin") {
        const q = query(
          contestsRef,
          where("isActive", "==", true),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          this.contests.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        this.loading = false;
      } else {
        const q = query(
          contestsRef,
          where("status", "==", "published"),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          this.contests.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        this.loading = false;
      }
      // this.filterContests(this.contests);
    },
    handleCreateContestDialogClose() {
      this.createContestDialog = false;
      this.isEdit = false;
      this.contest = {};
    },
    editContest(id) {
      this.contest = this.contests.find((contest) => contest.id === id);
      this.isEdit = true;
      this.createContestDialog = true;
    },
    publishContest(id) {
      this.contestId = id;
      this.confirmDialogText =
        "Are you sure want to publish this contest? Once published cannot be edited later";
      this.confirmDialog = true;
      this.confirmDialogKey = "publish";
    },
    async confirmPublish() {
      const documentRef = doc(contestsRef, this.contestId);
      await setDoc(documentRef, { status: "published" }, { merge: true });
      this.confirmDialog = false;
      this.contestId = null;
      this.confirmDialogKey = "";
      showToastNotification("Contest published", NOTIFICATION_TYPE.SUCCESS);
      this.getContests();
    },
    async deleteContest(docId) {
      this.confirmDialog = true;
      this.confirmDialogText = "Are you sure want to delete this contest?";
      this.confirmDialogKey = "delete";
      this.contestId = docId;
    },
    async updateContest() {
      try {
        this.loading = true;
        if (typeof this.contest.bannerImg === "object") {
          this.contest.bannerImg = await uploadFile(
            this.contest.bannerImg,
            "contests"
          );
        }

        if (typeof this.contest.judge.image === "object") {
          this.contest.judge.image = await uploadFile(
            this.contest.judge.image,
            "contests"
          );
        }

        await updateDoc(doc(contestsRef, this.contest.id), this.contest, {
          merge: true,
        });
        showToastNotification("Contest updated", NOTIFICATION_TYPE.SUCCESS);

        this.loading = false;
        this.isEdit = false;
        this.createContestDialog = false;
        this.contest = {};
        this.getContests();
      } catch (error) {
        this.loading = false;
        handleError(error);
      }
    },
    viewContest(id) {
      this.$router.push(`/contest?id=${id}`);
    },
    filterContests(contests) {
      // display only the contests which have result date greater than today.
      this.contests = contests
        .filter((contest) => {
          const resultDate = new Date(contest.resultDate);
          const today = new Date();
          return resultDate > today;
        })
        .sort((a, b) => new Date(a.resultDate) - new Date(b.resultDate));
    },
    async filterContestsByType(type) {
      this.loading = true;
      const querySnapshot = await getDocs(contestsRef);

      let contests = [];
      querySnapshot.forEach((doc) => {
        contests.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      this.loading = false;

      if (type === "active") {
        // this.filterContests(contests);
      } else {
        this.contests = contests
          .filter((contest) => {
            const resultDate = new Date(contest.resultDate);
            const today = new Date();
            return resultDate < today;
          })
          .sort((a, b) => new Date(b.resultDate) - new Date(a.resultDate));
      }
    },
    handleConfirm() {
      if (this.confirmDialogKey === "publish") {
        this.confirmPublish();
      } else if (this.confirmDialogKey === "delete") {
        this.confirmDelete();
      }
    },
    async confirmDelete() {
      try {
        const documentRef = doc(contestsRef, this.contestId);
        await deleteDoc(documentRef);
        this.confirmDialog = false;
        this.contestId = null;
        this.confirmDialogKey = "";
        showToastNotification(
          "Contest deleted successfully",
          NOTIFICATION_TYPE.SUCCESS
        );
        this.getContests(this.selectedContestType);
      } catch (error) {
        showToastNotification(
          "Error while deleting the contest",
          NOTIFICATION_TYPE.ERROR
        );
      }
    },
    async showHideWinners(contest) {
      if (
        window.confirm(
          `Are you sure want to ${
            contest.showWinners ? "hide" : "show"
          } winners?`
        )
      ) {
        try {
          const documentRef = doc(contestsRef, contest.id);
          await setDoc(
            documentRef,
            { showWinners: !contest.showWinners },
            { merge: true }
          );
          showToastNotification("Update successful", NOTIFICATION_TYPE.SUCCESS);
          this.getContests();
        } catch (error) {
          handleError(error);
        }
      }
    },
  },
};
</script>

<style scoped>
h2 {
  color: #4f4f4f;
  font-weight: 500;
}

.contest-card {
  width: 340px;
  margin-right: 2rem;
  margin-bottom: 5rem;
  /* min-height: 400px; */
  height: 580px;
}

.create-contest-btn {
  margin-left: auto;
}

>>> .v-card__actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .contest-header {
    margin-left: 1.5rem !important;
  }

  .contest-heading {
    font-size: 18px;
  }

  .contest-card {
    width: 100%;
    margin: 0 auto 1rem auto;
  }

  .contests-row {
    margin-top: 1rem !important;
  }
}
</style>