<template>
  <div>
    <div>
      <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>First round - {{ contest.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="saveFirstRoundResult()">
                Save and close
              </v-btn>
              <v-btn dark text color="error" @click="submitFirstRoundResult()">
                Submit result to next round
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <div
              class="my-3 d-flex justify-content-between"
              v-if="selectionAreaImages.length > 0"
            >
              <p>{{ selectedImages.length }} images selected</p>
            </div>
            <div class="image-playground-container">
              <div
                class="image-playground-selection-area mt-3 pt-3"
                v-if="selectionAreaImages.length > 0"
              >
                <div
                  class="image-playground-selection-area-selection-images images"
                >
                  <div
                    v-for="(image, index) in selectionAreaImages"
                    :key="index"
                    class="mb-3 mr-5"
                  >
                    <div class="img-actions d-flex align-center">
                      <v-checkbox
                        v-model="image.selected"
                        @click="selectImage(index)"
                      ></v-checkbox>
                      <v-btn icon color="error" @click="moveToTrash(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                    <img
                      :src="image.src"
                      class="img-thumbnail"
                      @click="handleSelectionAreaImageClick(index)"
                    />
                  </div>
                </div>
                <v-divider class="my-2"></v-divider>
                <div class="image-playground-selection-area-selected-images">
                  <h3 class="text-center">Selected images</h3>
                  <div class="images">
                    <div
                      v-for="(image, index) in selectedImages"
                      :key="index"
                      class="mb-3 mr-5"
                    >
                      <img
                        :src="image.src"
                        class="img-thumbnail"
                        @click="handleSelectedAreaImageClick(index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <v-divider vertical></v-divider>
              <div class="image-selection-delete-area">
                <h3 class="text-center">Trash</h3>
                <div class="text-center mt-2">Click on image to restore</div>
                <div class="trash-images pt-3" v-if="trash.length > 0">
                  <div
                    v-for="(image, index) in trash"
                    :key="index"
                    class="mb-3 mr-5"
                    @click="restoreImage(index)"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          :src="image.src"
                          class="img-thumbnail-deleted"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Restore</span>
                    </v-tooltip>
                  </div>
                </div>
                <empty-state text="Nothing in trash" v-else></empty-state>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showImageCarousel" max-width="800px">
        <v-card>
          <v-card-text>
            <div class="carousel-header-action-container">
              <div class="carousel-header-action-right">
                <v-checkbox
                  v-model="activeImage.selected"
                  @click="handleCarouselImageSelect()"
                ></v-checkbox>
                <v-btn icon color="error" @click="handleCarouselImageDelete()">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn icon @click="showImageCarousel = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="carousel-body">
              <v-btn
                icon
                color="primary"
                @click="handleCarouselImagePrevious()"
                class="carousel-prev-btn"
              >
                <v-icon size="40">mdi-chevron-left-box</v-icon>
              </v-btn>
              <img :src="activeImage.src" class="img-carousel-image" />
              <v-btn
                icon
                color="primary"
                @click="handleCarouselImageNext()"
                class="carousel-next-btn"
              >
                <v-icon size="40">mdi-chevron-right-box</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <confirm-dialog
      :visible="confirmDialog"
      @confirm="submitFirstRoundResultConfirm"
      title="Submit first round result"
      message="Are you sure you want to submit the first round result?"
      @close="confirmDialog = false"
    ></confirm-dialog>
  </div>
</template>

<script>
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { handleError } from "../../common/api/api.middleware";
import { NOTIFICATION_TYPE } from "../../common/constants";
import { db } from "../../config/firebase";
import { showToastNotification } from "../../plugins/vue-toast-notifications";
import EmptyState from "../../components/shared/EmptyState.vue";
import ConfirmDialog from "../../components/shared/ConfirmDialog.vue";

export default {
  components: { EmptyState, ConfirmDialog },
  props: ["visible", "contest"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      selectedImages: [],
      allImages: [],
      trash: [],
      selectionAreaImages: [],
      showImageCarousel: false,
      activeImage: { src: "" },
      confirmDialog: false,
    };
  },
  created() {
    this.fetchImages();
  },
  methods: {
    async fetchImages() {
      try {
        this.allImages = [];
        const parentDocRef = doc(db, "contests", this.contest.id);
        const subCollectionRef = collection(parentDocRef, "first-round");
        const querySnapshot = await getDocs(subCollectionRef);

        querySnapshot.forEach((doc) => {
          this.allImages.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        this.filterImages();
      } catch (error) {
        handleError(error);
      }
    },
    filterImages() {
      this.selectedImages = this.allImages.filter((image) => image.selected);
      this.trash = this.allImages.filter((image) => image.deleted);
      this.selectionAreaImages = this.allImages.filter(
        (image) => !image.deleted
      );
    },
    moveToTrash(index) {
      const image = this.allImages[index];
      image.selected = false;
      image.deleted = true;
      this.updateDocument(image);
    },
    restoreImage(index) {
      const image = this.trash[index];
      image.deleted = false;
      this.updateDocument(image);
    },
    async submitFirstRoundResult() {
      this.confirmDialog = true;
    },
    selectImage(index) {
      const image = this.selectionAreaImages[index];
      this.updateDocument(image);
    },
    async updateDocument(image) {
      const parentDocRef = doc(db, "contests", this.contest.id);
      const subCollectionRef = collection(parentDocRef, "first-round");
      const docRef = doc(subCollectionRef, image.id);

      const data = {
        deleted: image.deleted,
        selected: image.selected,
      };
      await setDoc(docRef, data, { merge: true });
      this.fetchImages();
    },
    saveFirstRoundResult() {
      this.show = false;
      showToastNotification(
        "First round result saved",
        NOTIFICATION_TYPE.SUCCESS
      );
    },
    handleSelectionAreaImageClick(index) {
      this.activeImage = this.selectionAreaImages[index];
      this.showImageCarousel = true;
    },
    handleCarouselImagePrevious() {
      const index = this.selectionAreaImages.findIndex(
        (image) => image.id === this.activeImage.id
      );
      if (index === 0) {
        this.activeImage =
          this.selectionAreaImages[this.selectionAreaImages.length - 1];
      } else {
        this.activeImage = this.selectionAreaImages[index - 1];
      }
    },
    handleCarouselImageNext() {
      const index = this.selectionAreaImages.findIndex(
        (image) => image.id === this.activeImage.id
      );
      if (index === this.selectionAreaImages.length - 1) {
        this.activeImage = this.selectionAreaImages[0];
      } else {
        this.activeImage = this.selectionAreaImages[index + 1];
      }
    },
    handleCarouselImageSelect() {
      this.activeImage.selected = !this.activeImage.selected;
      this.updateDocument(this.activeImage);
    },
    handleCarouselImageDelete() {
      this.activeImage.deleted = true;
      this.updateDocument(this.activeImage);
    },
    handleSelectedAreaImageClick(index) {
      this.activeImage = this.selectedImages[index];
      this.showImageCarousel = true;
    },
    submitFirstRoundResultConfirm() {
      this.confirmDialog = false;
      this.show = false;
      showToastNotification(
        "First round result submitted",
        NOTIFICATION_TYPE.SUCCESS
      );
    },
  },
};
</script>

<style scoped>
.images {
  overflow-y: scroll;
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;
}

.images > div {
  position: relative;
}

.images > .selected-overlay {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: #000;
  opacity: 0.5;
}

.img-thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;
}

.image-playground-container {
  display: flex;
}

.image-playground-selection-area {
  width: 70%;
}

.image-playground-selection-area-selection-images {
  height: 50%;
  overflow-y: scroll;
}

.image-selection-delete-area {
  width: 30%;
}

.img-thumbnail-deleted {
  cursor: pointer;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.image-selection-delete-area {
  padding: 0 2rem;
}

.trash-images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem !important;
}

.carousel-header-action-container {
  display: flex;
  justify-content: space-between;
}

.carousel-header-action-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.carousel-body {
  display: flex;
  align-items: center;
}

.img-carousel-image {
  width: 90%;
  height: 600px;
  object-fit: cover;
}

.carousel-prev-btn {
  width: 5%;
}

.carousel-next-btn {
  width: 5%;
}
</style>