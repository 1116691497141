import { defineStore } from "pinia";
import { apiClient } from "../common/api/api.middleware";
import { apis } from "../common/api/api.constants";

export const useUserModuleStore = defineStore("userModule", {
  state: () => ({
    userModule: {},
  }),
  actions: {
    async getUserModuleByUserId(userId) {
      try {
        const response = await apiClient.get(`${apis.userModule}/${userId}`);
        const data = response.data.data[0];
        this.userModule = data;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
