<template>
  <v-dialog v-model="show" width="800" persistent>
    <v-card>
      <v-card-title>
        Upload your work
        <v-spacer></v-spacer>
        <v-btn color="red" icon @click="show = false">
          <v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text class="mt-5">
        <div
          @drop.prevent="onDrop($event)"
          @dragover.prevent="dragover = true"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="dragover = false"
          @click="$refs.file.click()"
          class="drop-zone"
        >
          <div
            class="d-flex justify-content-center mt-5 mb-4 align-center text-center"
            dense
          >
            <v-icon icon="cloud-upload" class="h3 mr-2">
              mdi-cloud-upload
            </v-icon>
            <p>Drop your file here, or click to select.</p>
          </div>
        </div>
        <div
          class="my-3 d-flex justify-content-between"
          v-if="images.length > 0"
        >
          <p>{{ images.length }} images selected</p>
        </div>
        <div class="images mt-3 pt-3" v-if="images.length > 0">
          <div v-for="(image, index) in createdImages" :key="index">
            <img :src="image" class="img-thumbnail mb-3 mr-3" />
            <v-icon class="remove-image" @click="removeImage(index)"
              >mdi-close</v-icon
            >
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          class="d-block mx-auto"
          @click="uploadWork()"
          :loading="loading"
          :disabled="loading"
          >Upload</v-btn
        >
      </v-card-actions>
    </v-card>
    <input
      type="file"
      ref="file"
      @change="handleChange"
      style="display: none"
      accept="image/*"
      multiple
    />
  </v-dialog>
</template>
  
  <script>
import { NOTIFICATION_TYPE } from "../../../common/constants";
import { uploadFile } from "../../../helpers/file-upload";
import { showToastNotification } from "../../../plugins/vue-toast-notifications";
import { addDoc, photographerWorksRef } from "../../../config/firebase";
import { handleError } from "../../../common/api/api.middleware";

export default {
  props: ["visible", "user"],
  data() {
    return {
      dragover: false,
      images: [],
      createdImages: [],
      loading: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) this.$emit("close");
      },
    },
  },
  methods: {
    onDrop(event) {
      event.preventDefault();
      event.dataTransfer.files.forEach((file) => {
        if (this.checkFileType(file)) {
          this.images.push(file);
        }
      });
      this.createImages();
    },
    handleChange() {
      Array.from(this.$refs.file.files).forEach((file) => {
        this.images.push(file);
      });
      this.createImages();
    },
    createImages() {
      this.createdImages = [];
      for (let i = 0; i < this.images.length; i++) {
        this.createdImages.push(URL.createObjectURL(this.images[i]));
      }
    },
    removeImage(index) {
      this.images.splice(index, 1);
      this.createdImages.splice(index, 1);
    },
    checkFileType(file) {
      return this.allowedImageTypes.includes(file.type);
    },
    async uploadWork() {
      if (this.images.length === 0) {
        showToastNotification(
          "Select atleast 1 image to upload",
          NOTIFICATION_TYPE.WARNING
        );
        return;
      }
      this.uploadImages();
    },
    async uploadImages() {
      this.loading = true;
      try {
        let count = 0;
        for (let image of this.images) {
          const imgURL = await uploadFile(image, "photographer-work");
          const work = {
            user_id: this.user.user_id,
            src: imgURL,
            createdAt: new Date(),
          };
          await addDoc(photographerWorksRef, work);
          count++;
          if (count === this.images.length) {
            this.loader = false;
            this.show = false;
            this.images = [];
            this.createdImages = [];
            showToastNotification(
              "Work has been uploaded",
              NOTIFICATION_TYPE.SUCCESS
            );
            this.$emit("refetch");
          }
        }
      } catch (error) {
        this.loading = false;
        handleError(error);
      }
    },
  },
};
</script>
  
<style scoped>
.drop-zone {
  cursor: pointer;
  border: 1px dashed #ccc;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  background-color: #fafafa;
  color: #ccc;
  font-size: 1.2rem;
  font-weight: bold;
  transition: all 0.3s ease;
}

.images {
  overflow-y: scroll;
  min-height: 400px;
  height: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.images > div {
  position: relative;
}

.remove-image {
  position: absolute;
  top: -5px;
  right: 15px;
  cursor: pointer;
  color: #fff;
  background-color: #f00;
  border-radius: 50%;
}

img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.upload-btn {
  background-color: transparent;
  color: var(--primary-color);
}

p {
  margin-bottom: 0;
}
@media screen and (max-width: 600px) {
  .drop-zone > div:first-child {
    display: block !important;
  }

  .mdi-cloud-upload {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }
}
</style>
  