<template>
  <v-tour :steps="steps" :name="name"> </v-tour>
</template>

<script>
export default {
  props: ["steps", "name"],
  mounted() {
    this.$emit("mounted");
  },
};
</script>

<style scoped>
/* >>>.v-step__button {
  background-color: #0597f2 !important;
  color: white !important;
  text-align: center !important;
}

>>>.v-step__button:hover {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #0597f2 !important;
}

>>> .v-step {
  background-color: #fff !important;
  color: black !important;
  border-radius: 5px !important;
  padding: 20px !important;
  border: 1px solid #0597f2 !important;
} */
</style>