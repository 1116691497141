<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>First round - {{ contest.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="submitFirstRoundResult()">
            Submit first round result
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <div
          class="my-3 d-flex justify-content-between"
          v-if="participants.length > 0"
        >
          <p>{{ selectedImages.length }} images selected</p>
        </div>
        <div class="images mt-3 pt-3" v-if="participants.length > 0">
          <div v-for="(image, index) in participants" :key="index">
            <v-checkbox v-model="image.selected"></v-checkbox>
            <img :src="image.src" class="img-thumbnail mb-3 mr-3" />
            <!-- <v-icon class="remove-image" @click="removeImage(index)"
                >mdi-close</v-icon
              > -->
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import { getDoc, where } from "firebase/firestore";
import { handleError } from "../../common/api/api.middleware";
import { NOTIFICATION_TYPE } from "../../common/constants";
import { addDoc, firstRoundRef, secondRoundRef } from "../../config/firebase";
import { showToastNotification } from "../../plugins/vue-toast-notifications";

export default {
  props: ["visible", "contest", "participants"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      selectedImages: [],
    };
  },
  created() {
    this.getContestDetails();
  },
  methods: {
    async submitFirstRoundResult() {
      try {
        const selectedImages = this.participants.filter(
          (image) => image.selected
        );
        for (let image of selectedImages) {
          await addDoc(firstRoundRef, image);
        }
        this.show = false;
        showToastNotification(
          "First round result saved",
          NOTIFICATION_TYPE.SUCCESS
        );
      } catch (error) {
        handleError(error);
      }
    },
    async getContestDetails() {
      const document = await getDoc(
        secondRoundRef,
        where("id", "==", this.$route.params.id)
      );
      console.log(document.data());
    },
  },
};
</script>
  
  <style scoped>
.images {
  overflow-y: scroll;
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;
}

.images > div {
  position: relative;
}

.images > .selected-overlay {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: #000;
  opacity: 0.5;
}

img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
</style>