<template>
  <section>
    <div class="">
      <otp-verify
        v-if="otpView"
        :phone_number="data.phone_number"
        :email="data.email"
        :password="data.password"
        :userType="userType"
        @verified="handleVerifiedOtp"
      ></otp-verify>
      <v-form ref="form" v-else>
        <h1 class="text-center mb-4">Signup here</h1>
        <v-text-field
          label="Name"
          required
          outlined
          prepend-inner-icon="mdi-account"
          type="text"
          v-model="data.name"
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          label="Email address"
          required
          outlined
          prepend-inner-icon="mdi-email"
          type="email"
          v-model="data.email"
          :rules="[rules.required, rules.email]"
        ></v-text-field>
        <v-text-field
          label="Password"
          required
          outlined
          prepend-inner-icon="mdi-lock"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.password]"
          v-model="data.password"
          hint="Password should be at least 6 characters long and should contain at least one number and one alphabet (E.g. Abc123)"
        ></v-text-field>
        <v-row>
          <v-col cols="3" v-if="!$vuetify.breakpoint.mobile">
            <v-select
              v-model="selectedCountryCode"
              :items="countryCodes"
              label="Code"
              outlined
            ></v-select>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 9">
            <v-text-field
              label="Phone number"
              required
              outlined
              prepend-inner-icon="mdi-phone"
              type="text"
              v-model="data.phone_number"
              :rules="[rules.required, rules.phoneNumber]"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12" md="12">
            <label>Date of Birth</label>
            <v-row>
              <v-col cols="4">
                <v-select
                  label="Date"
                  outlined
                  v-model="dateOfBirth.date"
                  :items="dateOfBirthItems.date"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  label="Month"
                  outlined
                  v-model="dateOfBirth.month"
                  :items="dateOfBirthItems.month"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  label="Year"
                  outlined
                  v-model="dateOfBirth.year"
                  :items="dateOfBirthItems.year"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-select
          label="Gender"
          outlined
          v-model="data.gender"
          :items="genders"
          :rules="[rules.required]"
        ></v-select> -->
        <!-- <v-row>
          <v-col cols="12">
            <label>I am a </label>
            <v-row>
              <v-col cols="12" md="6">
                <v-btn
                  outlined
                  block
                  :class="`${
                    userType === 'photographer' ? 'user-type--active' : ''
                  }`"
                  @click="handleUserTypeClick('photographer')"
                >
                  <v-icon class="mr-1">mdi-camera</v-icon>
                  Photographer
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn
                  outlined
                  block
                  :class="`${userType === 'user' ? 'user-type--active' : ''}`"
                  @click="handleUserTypeClick('user')"
                >
                  <v-icon class="mr-1">mdi-account</v-icon>
                  Guest/ User
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->
        <v-checkbox v-model="privacyAccepted" :rules="[rules.acceptPrivacy]">
          <template v-slot:label>
            <div>
              I agree with the
              <a target="_blank" href="/privacy-policy" @click.stop>
                privacy policy
              </a>
              and
              <a target="_blank" href="/terms-and-conditions" @click.stop>
                terms and conditions
              </a>
            </div>
          </template>
        </v-checkbox>
        <v-btn
          color="primary"
          class="d-block mx-auto mt-2 signup-btn"
          @click="handleSignup()"
          :loading="isLoading"
          :disabled="isLoading"
          >Signup</v-btn
        >
        <div class="action-container mt-5">
          <div class="text-right">
            Already have an account?
            <v-btn link text color="primary" to="/login" class="pl-0"
              >Login here</v-btn
            >
          </div>
        </div>
      </v-form>
    </div>
    <v-dialog v-model="userTypeDialog" width="500">
      <v-card>
        <v-card-title class="mb-2">
          <div>I am a</div>
          <v-btn
            icon
            @click="handleUserTypeDialogClose"
            color="red"
            class="ml-auto"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-btn
                outlined
                block
                :class="`${
                  userType === 'photographer' ? 'user-type--active' : ''
                }`"
                @click="handleUserTypeClick('photographer')"
              >
                <v-icon class="mr-1">mdi-camera</v-icon>
                Photographer
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn
                outlined
                block
                :class="`${userType === 'user' ? 'user-type--active' : ''}`"
                @click="handleUserTypeClick('user')"
              >
                <v-icon class="mr-1">mdi-account</v-icon>
                Guest/ User
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="handleUserTypeSave()"
            class="ml-auto"
            :loading="isLoading"
            >Save and Login</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { COUNTRY_CODES, NOTIFICATION_TYPE } from "../../common/constants";
import { apiClient, handleError } from "../../common/api/api.middleware";
import { showToastNotification } from "../../plugins/vue-toast-notifications";
import OtpVerify from "./OtpVerify.vue";
import { apis } from "../../common/api/api.constants";
import { setAccountAndRedirect } from "../../utils/common.utils";
export default {
  components: { OtpVerify },
  data() {
    return {
      data: {
        name: null,
        email: null,
        password: null,
        signup_as: "photographer",
        phone_number: null,
        // date_of_birth: "",
        // gender: null,
      },
      countryCodes: COUNTRY_CODES,
      selectedCountryCode: COUNTRY_CODES[0],
      show: false,
      rules: {
        required: (value) => !!value || "This field is required",
        acceptPrivacy: (value) =>
          !!value || "Please accept privacy policy and terms of use",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        password: (value) => {
          return (
            // password should be at least 6 characters long and should contain at least one number and one alphabet and can contain special characters
            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&+-/_(){}=^:;'"]{6,}$/.test(
              value
            ) ||
            "Password should be at least 6 characters long and should contain at least one number and one alphabet"
          );
        },
        phoneNumber: (value) => {
          const pattern = /^[6-9]{1}[0-9]{9}$/;
          return pattern.test(value) || "Invalid mobile number";
        },
        // dateOfBirth: (value) => {
        //   // date of birth should be in dd-mm-yyyy format
        //   const pattern = /^([0-2][0-9]|(3)[0-1])-(0[0-9]|1[0-2])-\d{4}$/;
        //   return (
        //     pattern.test(value) ||
        //     "Date of birth should be in dd-mm-yyyy format"
        //   );
        // },
      },
      signup_as: [
        {
          label: "Photographer",
          value: "photographer",
        },
      ],
      isLoading: false,
      privacyAccepted: false,
      otpView: false,
      // genders: ["Male", "Female", "Other"],
      // dateOfBirthItems: {
      //   date: [],
      //   month: [],
      //   year: [],
      // },
      // dateOfBirth: {
      //   date: null,
      //   month: null,
      //   year: null,
      // },
      userType: null,
      userTypeDialog: false,
    };
  },
  created() {
    // this.initializeDateOfBirthItems();
  },
  methods: {
    // initializeDateOfBirthItems() {
    //   const date = [];
    //   for (let i = 1; i <= 31; i++) {
    //     date.push(i.length === 1 ? `0${i}` : i);
    //   }
    //   this.dateOfBirthItems.date = date;

    //   const month = [];
    //   for (let i = 1; i <= 12; i++) {
    //     month.push(i.length === 1 ? `0${i}` : i);
    //   }
    //   this.dateOfBirthItems.month = month;

    //   const year = [];
    //   for (let i = 1950; i <= new Date().getFullYear(); i++) {
    //     year.push(i);
    //   }
    //   this.dateOfBirthItems.year = year;
    // },
    async handleSignup() {
      try {
        if (!this.$refs.form.validate()) return;
      
        this.isLoading = true;
        const requestBody = {
          name: this.data.name,
          email_address: this.data.email,
          password: this.data.password,
          signup_as: this.data.signup_as,
          phone_number: `${
            this.selectedCountryCode ? this.selectedCountryCode : "+91"
          }${this.data.phone_number}`,
          // date_of_birth: `${this.dateOfBirth.date}-${this.dateOfBirth.month}-${this.dateOfBirth.year}`,
          // gender: this.data.gender,
        };
        const response = await apiClient.post(apis.users, requestBody);
        this.isLoading = false;
        this.$refs.form.resetValidation();
        this.otpView = true;
        showToastNotification(response.data.message, NOTIFICATION_TYPE.SUCCESS);
      } catch (error) {
        this.isLoading = false;
        this.otpView = false;
        handleError(error);
      }
    },
    handleUserTypeClick(userType) {
      this.userType = userType;
    },
    async handleVerifiedOtp() {
      this.userTypeDialog = true;
    },
    handleUserTypeDialogClose() {
      this.userType = null;
      this.userTypeDialog = false;
    },
    async handleUserTypeSave() {
      if (!this.userType) {
        showToastNotification(
          "Please select if you are a photographer or user",
          NOTIFICATION_TYPE.WARNING
        );
        return;
      }
      try {
        this.isLoading = true;
        const requestBody = {
          email_address: this.data.email,
          password: this.data.password,
        };
        const response = await apiClient.post(apis.loginUser, requestBody);
        showToastNotification(
          "Login successful. Redirecting...",
          NOTIFICATION_TYPE.SUCCESS,
          "top",
          500
        );

        const { user } = response.data.data;

        await apiClient.put(`${apis.userModule}/${user.user_id}`, {
          is_photographer: this.userType === "photographer",
          user_id: user.user_id,
        });
        setAccountAndRedirect(response.data.data, this.$route.query.redirect);
      } catch (error) {
        this.isLoading = false;
        handleError(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2rem;
  width: 500px;
  margin: 2rem auto 0 auto;
}

.signup-with-google-container {
  width: 500px;
  margin: 2rem auto 0 auto;
}

.google-signup-btn {
  width: 320px;
  display: flex;
  align-items: center;
  font-size: 18px;
  background-color: #4286f5;
  color: #fff;
  border-radius: 3px;
  padding: 5px;
  margin: 0 auto;
}

.google-signup-btn > img {
  width: 40px;
  margin-right: 10px;
}

.signup-btn {
  width: 200px;
}

a {
  /* color: #000 !important; */
}

.row {
  margin-top: 0;
}

.col {
  padding-top: 0;
}

.col-12 {
  margin-top: 0;
  padding-top: 0;
}

label {
  display: block;
  margin-bottom: 5px;
}

.user-type--active {
  /* background-color: #0597f2 !important; */
  color: #0597f2 !important;
  border-color: #0597f2;
}

@media screen and (max-width: 600px) {
  section {
    margin-top: 1.5rem;
  }

  form {
    width: 90%;
    margin-top: 0;
    padding: 1rem;
  }
  .signup-with-google-container {
    width: 100%;
  }

  .action-container {
    font-size: 14px;
  }

  >>> .v-input--selection-controls .v-input__slot > .v-label {
    font-size: 14px;
  }

  .text-right {
    text-align: left !important;
  }
}
</style>