<template>
  <v-dialog v-model="show" width="900" persistent>
    <v-card elevation="0">
      <v-card-title
        >Participants
        <v-spacer></v-spacer>
        <v-btn icon color="red" @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-data-table
          :headers="headers"
          :items="participants"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:[`item.payment_status`]="{ item }">
            <v-chip
              :color="item.payment_status === 'Paid' ? 'success' : 'error'"
              dark
              >{{ item.payment_status }}</v-chip
            >
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text>Export</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["visible", "participants"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) this.$emit("close");
      },
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        { text: "Email address", value: "email_address" },
        { text: "Payment status", value: "payment_status" },
        // { text: "Created at", value: "createdAt" },
      ],
    };
  },
};
</script>

<style>
</style>