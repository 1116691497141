<template>
  <div>
    <v-skeleton-loader type="card" v-if="loading"></v-skeleton-loader>
    <v-card max-width="344" v-else>
      <v-img
        :src="contest.bannerImg"
        height="200px"
        v-if="contest.bannerImg"
      ></v-img>
      <v-img
        src="https://firebasestorage.googleapis.com/v0/b/picsncart-5772f.appspot.com/o/contests%2F1687683554687625825fc8b169c0018f3682c.webp?alt=media&token=9ca6b266-10b8-4da7-825d-aa40920d27ff"
        height="200px"
        v-else
      ></v-img>

      <v-card-title> {{ contest.title }}</v-card-title>
      <!-- <v-card-subtitle class="contest-desc">
        {{ contest.rules }}</v-card-subtitle
      > -->
      <v-card-text v-if="contest.judge && contest.judge.name" class="pb-0">
        <h3>Judge</h3>
        <div class="mt-2 d-flex">
          <img
            :src="contest.judge.image"
            :alt="contest.judge.name"
            class="judge-image"
            v-if="contest.judge.image"
          />
          <v-avatar color="info" size="40" class="mb-3" v-else>
            <v-icon color="white">mdi-account-circle</v-icon>
          </v-avatar>
          <div class="ml-2">
            <h4>
              {{ contest.judge.name }}{{ " " }}({{ contest.judge.contact }})
            </h4>
            <p>{{ contest.judge.description }}</p>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="pt-0">
        <div>
          <div class="contest-date">
            Start date: {{ formatDate(contest.startDate) }}
          </div>
          <div class="contest-date">
            Last date to register: {{ formatDate(contest.lastDateToRegister) }}
          </div>
          <div class="contest-date">
            Result date: {{ formatDate(contest.resultDate) }}
          </div>
          <v-chip-group active-class="primary white--text" column>
            <v-chip color="secondary"
              >Entry Fee: {{ getEntryFee(contest.entryFee) }}</v-chip
            >
            <v-chip color="secondary"
              >Prize money: Rs.{{ contest.prizeMoney }}</v-chip
            >
            <v-chip color="secondary" v-if="contest.secondPrizeMoney"
              >2nd Prize money: Rs.{{ contest.secondPrizeMoney }}</v-chip
            >
          </v-chip-group>
          <div v-if="contest.productPrize" class="mt-2">
            <h4>Special prize</h4>
            <span class="product-prize-description">
              {{ contest.productPrize }}
            </span>
          </div>
          <v-btn
            color="primary"
            @click="checkIfLoggedIn()"
            v-if="!user || (user && user.roles[0].role_name !== 'admin')"
            class="d-block ml-auto mt-2"
            >Register</v-btn
          >
          <div
            class="mt-3 d-flex align-center"
            v-if="user && user.roles[0].role_name === 'admin'"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  v-if="contest.status !== 'published'"
                  color="primary"
                  @click="$emit('edit', contest.id)"
                  class="mr-2"
                  icon
                >
                  <v-icon>mdi-pencil-box-outline</v-icon>
                </v-btn>
              </template>
              <span>Edit contest</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  @click="$emit('publish', contest.id)"
                  v-if="contest.status !== 'published'"
                  class="mr-2"
                  icon
                >
                  <v-icon>mdi-upload</v-icon>
                </v-btn>
              </template>
              <span>Publish contest</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  class="mr-2"
                  @click="showParticipantsModal = true"
                  icon
                >
                  <v-icon>mdi-account-group</v-icon>
                </v-btn>
              </template>
              <span>View participants</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                  class="mr-2"
                  @click="$emit('delete', contest.id)"
                  icon
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete contest</span>
            </v-tooltip>
            <v-menu offset-y v-if="user && user.roles[0].role_name === 'admin'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="contest-actions"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="handleStartFirstRound()"
                  v-if="
                    contest.status === 'published' &&
                    contest.roundStatus === 'first-round-not-started'
                  "
                >
                  <v-list-item-title>Start first round</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="handleContinueFirstRound()"
                  v-if="
                    contest.status === 'published' &&
                    contest.roundStatus === 'first-round-in-progress'
                  "
                >
                  <v-list-item-title>Continue first round</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="generateSecondRoundLink()"
                  v-if="
                    contest.status === 'published' &&
                    contest.roundStatus === 'first-round-completed'
                  "
                >
                  <v-list-item-title
                    >Generate second round link</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  @click="$emit('showHideWinners', contest)"
                >
                  <v-list-item-title>
                    {{ contest.showWinners ? "Hide winners" : "Show winners" }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-card-actions>
    </v-card>
    <register-modal
      :contest="contest"
      :visible="showRegisterModal"
      @close="showRegisterModal = false"
      :user="user"
    ></register-modal>
    <view-participants
      :visible="showParticipantsModal"
      @close="showParticipantsModal = false"
      :participants="participants"
    ></view-participants>
    <first-round
      :visible="firstRoundModal"
      @close="firstRoundModal = false"
      :contest="contest"
    ></first-round>
  </div>
</template>

<script>
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { competitionsRef, contestsRef } from "../../config/firebase";
import RegisterModal from "./RegisterModal.vue";
import ViewParticipants from "./ViewParticipants.vue";
import FirstRound from "./FirstRound.vue";
import copy from "copy-to-clipboard";
import { showToastNotification } from "../../plugins/vue-toast-notifications";
import { NOTIFICATION_TYPE } from "../../common/constants";
import { handleError } from "../../common/api/api.middleware";

export default {
  components: { RegisterModal, ViewParticipants, FirstRound },
  props: ["contest", "user", "loading"],
  data() {
    return {
      showRegisterModal: false,
      showParticipantsModal: false,
      participants: [],
      firstRoundModal: false,
    };
  },
  created() {
    this.getParticipants();
  },
  methods: {
    async getParticipants() {
      let participants = [];
      const q = query(
        competitionsRef,
        where("contest_id", "==", this.contest.id)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        participants.push({
          id: doc.id,
          ...doc.data(),
          payment_status: "Paid",
        });
      });
      this.participants = participants;
    },
    generateSecondRoundLink() {
      const link = `${location.host}/contest/second-round/${this.contest.id}`;
      copy(link);
      showToastNotification(
        "Link copied to clipboard",
        NOTIFICATION_TYPE.SUCCESS
      );
    },
    checkIfLoggedIn() {
      if (!this.user) {
        return showToastNotification(
          "Please login to register",
          NOTIFICATION_TYPE.WARNING
        );
      }
      this.showRegisterModal = true;
    },
    formatDate(date) {
      const dateParts = date.split("-");
      const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
      return formattedDate;
    },
    getEntryFee(fee) {
      if (!fee) return "Free";
      else return `Rs ${fee}`;
    },
    // sometimes there will be the participant's will be repeated, let's remove the duplicates by user_id
    removeDuplicateParticipants(participants) {
      return participants.filter(
        (participant, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.user_id === participant.user_id &&
              t.contest_id === participant.contest_id
          )
      );
    },
    async handleStartFirstRound() {
      try {
        showToastNotification(
          "Preparing for the first round...",
          NOTIFICATION_TYPE.INFO,
          "top",
          3000
        );
        let allImages = [];

        const q = query(
          competitionsRef,
          where("contest_id", "==", this.contest.id)
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          let images = data.src;

          if (images && images.length) {
            images.forEach((image) => {
              allImages.push({
                src: image,
                user_id: data.user_id,
                selected: false,
                deleted: false,
              });
            });
          }
        });

        for (let image of allImages) {
          await this.addFirstRoundDocuments(image);
        }

        const documentRef = doc(contestsRef, this.contest.id);
        await setDoc(
          documentRef,
          {
            roundStatus: "first-round-in-progress",
          },
          { merge: true }
        );

        showToastNotification(
          "First round started successfully",
          NOTIFICATION_TYPE.SUCCESS,
          "top",
          4000
        );
        this.$emit("refresh");
      } catch (error) {
        handleError(error);
      }
    },
    addFirstRoundDocuments(document) {
      const parentDocRef = doc(contestsRef, this.contest.id);
      const subCollectionRef = collection(parentDocRef, "first-round");

      return new Promise((resolve, reject) => {
        addDoc(subCollectionRef, document)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    handleContinueFirstRound() {
      this.firstRoundModal = true;
    },
  },
};
</script>

<style scoped>
.v-card {
  position: relative;
  height: auto;
  max-height: 600px;
  min-height: 600px;
}

.v-card__actions {
  /* position: absolute; */
  bottom: 0.5rem;
  width: 100%;
}

.contest-actions {
  /* position: absolute; */
  right: 0;
  bottom: 0;
}

.judge-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.v-chip {
  cursor: auto;
  font-size: 12px;
}

.contest-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-size: 12px;
}

.contest-date {
  font-size: 12px;
}

.product-prize-description {
  font-size: 12px;
}

@media screen and (max-width: 600px) {
}
</style>