import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../features/home/Home.vue";
import Shop from "../features/vendor/Shop.vue";
import Contests from "../features/photography-competition/Contests.vue";
import Signup from "../features/auth/Signup.vue";
import ForgotPassword from "../features/auth/ForgotPassword.vue";
import PhotographerProfile from "../features/photographer/profile/PhotographerProfile.vue";
import PrivacyPolicy from "../features/legal/privacy-policy.vue";
import TermsAndConditions from "../features/legal/terms-and-conditions.vue";
import ResetPassword from "../features/auth/ResetPassword.vue";
import AdminDashboard from "../features/admin/Dashboard.vue";
import SecondRound from "../features/photography-competition/SecondRound.vue";
import Login from "../features/auth/Login.vue";
import Photographers from "../features/photographer/Photographers.vue";
import Faqs from "../features/legal/Faqs.vue";
import PhotographyContest from "../features/photography-competition/PhotographyContest.vue";
import Settings from "../features/settings/Settings.vue";
import Tutorial from "../features/tutorial/Tutorial.vue";
import Users from "../features/user/Users.vue";

import { apiClient, handleError } from "../common/api/api.middleware";
import { apis } from "../common/api/api.constants";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/shop",
    component: Shop,
  },
  {
    path: "/contests",
    component: Contests,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/signup",
    component: Signup,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/users/account/reset-password",
    component: ResetPassword,
  },
  {
    path: "/photographer",
    component: PhotographerProfile,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms-and-conditions",
    component: TermsAndConditions,
  },
  {
    path: "/dashboard",
    component: AdminDashboard,
  },
  {
    path: "/contest/second-round/:id",
    component: SecondRound,
  },
  {
    path: "/photographers",
    component: Photographers,
  },
  {
    path: "/faqs",
    component: Faqs,
  },
  {
    path: "/contest",
    component: PhotographyContest,
  },
  {
    path: "/settings",
    component: Settings,
  },
  {
    path: "/tutorial",
    component: Tutorial,
  },
  {
    path: "/users",
    component: Users,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const getAccessToken = () => {
  return localStorage.getItem("pcn_access_token") || null;
};

const getUserDetailsByAccessToken = async (token) => {
  try {
    const response = await apiClient.get(`${apis.userDetails}?token=${token}`);
    const user = response.data.data;
    return user;
  } catch (error) {
    handleError(error);
  }
};

router.beforeEach(async (to, from, next) => {
  // scroll to top on route change
  window.scrollTo(0, 0);

  const publicPages = [
    "/",
    "/login",
    "/signup",
    "/forgot-password",
    "/photographers",
    "/photographer",
    "/contests",
    "/faqs",
    "/shop",
    "/privacy-policy",
    "/terms-and-conditions",
    "/contest",
    "/users/account/reset-password",
    "/tutorial",
  ];
  const authRequired = !publicPages.includes(to.path);
  const accessToken = getAccessToken();
  if (authRequired && !accessToken) {
    return next("/login");
  } else if (accessToken) {
    if (to.path === "/login") {
      const user = await getUserDetailsByAccessToken(accessToken);
      if (
        user &&
        user.roles &&
        user.roles.length &&
        user.roles[0].role_id === 1
      ) {
        return next("/dashboard");
      } else if (
        user &&
        user.roles &&
        user.roles.length &&
        user.roles[0].role_id === 2
      ) {
        return next(`/`);
      }
    }
    return next();
  } else {
    return next();
  }
});

export default router;
