import axios from "axios";
import { NOTIFICATION_TYPE } from "../constants";
import { showToastNotification } from "../../plugins/vue-toast-notifications";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

const handleError = (error) => {
  const { response } = error;
  const { data } = response;
  const status = response.status;

  if (status === 500) {
    showToastNotification("Server error has occured", NOTIFICATION_TYPE.ERROR);
  } else {
    if (data && data.message) {
      showToastNotification(data.message, NOTIFICATION_TYPE.ERROR);
    } else {
      showToastNotification("Unknown error has occured", NOTIFICATION_TYPE.ERROR);
    }
  }
};

export { apiClient, handleError };
