<template>
  <div class="pcn-dashboard-container">
    <v-container>
      <v-row>
        <v-col cols="12">
          <stats></stats>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "pinia";
import Stats from "./dashboard/Stats.vue";
import { useAuthStore } from "../../store/auth.store";
export default {
  components: { Stats },
  computed: {
    ...mapState(useAuthStore, ["user"]),
  },
  // mounted() {
  //   if (!this.user || (this.user && this.user.roles[0].role_name !== "admin")) {
  //     this.$router.push("/login");
  //   }
  // },
};
</script>

<style scoped>
</style>