import { ROLES } from "../common/constants";
import router from "../router";
import { useAuthStore } from "../store/auth.store";
import { useUserModuleStore } from "../store/userModule.store";

export const setAccountAndRedirect = (loginResponse, redirect) => {
  const authStore = useAuthStore();
  const userModuleStore = useUserModuleStore();

  localStorage.setItem("pcn_access_token", loginResponse.pcn_access_token);
  authStore.user = loginResponse.user;

  userModuleStore.getUserModuleByUserId(loginResponse.user.user_id);

  redirectToRightPage(loginResponse.user, redirect);
};

const redirectToRightPage = (data, redirect) => {
  const role = data.roles[0];
  if (role.role_name === "admin") {
    // redirect to admin page
    window.location.href = "/dashboard";
  } else if (role.role_name === "photographer") {
    // check if redirect is present in the route. If yes, redirect to the route
    if (redirect) {
      window.location.href = redirect;
      return;
    }
    // redirect to photographer page
    // window.location.href = `/photographer?id=${data.user_id}`;
    window.location.href = `/`;
  } else if (role.role_name === ROLES[2]) {
    //redirect to vendor page
  } else {
    // redirect to users page
  }
};

export const logoutAndRedirect = () => {
  const authStore = useAuthStore();
  authStore.user = null;
  localStorage.removeItem("pcn_access_token");
  router.push("/");
  setTimeout(() => {
    window.location.reload();
  }, 500);
};

export const getUiAvatar = (name, background = "009eff", color = "fff") => {
  return `https://ui-avatars.com/api/?name=${name}&background=${background}&color=${color}`;
};

export const formatDate = (date) => {
  if (!date) return "";
  const dateParts = date.split("-");
  const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
  return formattedDate;
};
