<template>
  <section>
    <h1>Faqs</h1>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>