// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  collection,
  getFirestore,
  addDoc,
  getDocs,
  getCountFromServer,
  query,
  where,
  getDoc,
  orderBy,
  setDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth();
const googleAuthProvider = new GoogleAuthProvider();

//collections
const contestsRef = collection(db, "contests");
const competitionsRef = collection(db, "competition");
const firstRoundRef = collection(db, "firstRound");
const secondRoundRef = collection(db, "secondRound");
const photographerWorksRef = collection(db, "photographerWorks");
const contestPaymentsRef = collection(db, "contestPayments");

export {
  app,
  analytics,
  db,
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  contestsRef,
  addDoc,
  getDocs,
  competitionsRef,
  firstRoundRef,
  secondRoundRef,
  auth,
  googleAuthProvider,
  signInWithPopup,
  getCountFromServer,
  photographerWorksRef,
  query,
  where,
  getDoc,
  orderBy,
  setDoc,
  doc,
  contestPaymentsRef,
  deleteDoc,
};
