import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-sugar.css";

Vue.use(VueToast);

const showToastNotification = (
  message,
  type,
  position = "top",
  duration = 5000
) => {
  Vue.$toast.open({
    message: message,
    type: type,
    position: position,
    duration: duration,
  });
};

export { showToastNotification };
