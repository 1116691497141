export const REGEX_EMAIL =
  /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NOTIFICATION_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};

export const ROLES = ["admin", "photographer", "vendor"];

export const ROLES_MAP = {
  [ROLES[0]]: 1,
  [ROLES[1]]: 2,
  [ROLES[2]]: 3,
};

export const STORAGE_BUCKET_BASE_URL =
  "https://firebasestorage.googleapis.com/v0/b/picsncart-5772f.appspot.com/o";

export const COUNTRY_CODES = ["+91"];

export const photographerFilters = {
  categories: [
    "Wedding Photography",
    "Babies & Kids",
    "Fashion & Portfolio",
    "Commercial",
    "Nature",
    "Special Occasions",
    "Corporate Events",
    "Travel",
    "Videography",
    "Cinematorgraphy",
    "Drone Photography",
    "Others",
  ],
  locations: [
    "Bangalore",
    "Mumbai",
    "Delhi",
    "Chennai",
    "Hyderabad",
    "Pune",
    "Mangalore",
    "Udupi",
    "Manipal",
    "Others",
  ],
  sortBy: [
    "Rating (High to Low)",
    "Rating (Low to High)",
    "Price (High to Low)",
    "Price (Low to High)",
  ],
};

export const PICSNCART_LOGO_URL =
  "https://firebasestorage.googleapis.com/v0/b/picsncart-5772f.appspot.com/o/images%2Flogo%2Fpicsncart-logo-no-bg.png?alt=media&token=44094bad-f729-4f4b-85cd-6d7c5aadaadb";

export const CONTEST_TYPES = [
  {
    name: "Photo",
    value: 1,
    max: 3,
    allow: ["image/jpeg", "image/png", "image/jpg"],
  },
  { name: "Video", value: 2, max: 1, allow: ["video/mp4"] },
];

export const SENTRY_ALLOWED_DOMAINS = ["picsncart.com"];
export const MICROSOFT_CLARITY_ALLOWED_DOMAINS = ["picsncart.com"];

export const GENDER_LIST = ["Male", "Female", "Other"];

export const RULES = {
  required: "This field is required",
  email: "Invalid email",
  password: "Password must be at least 6 characters long",
  phone: "Invalid phone number",
  number: "Invalid number",
  min: (min) => `Minimum ${min} characters required`,
  max: (max) => `Maximum ${max} characters allowed`,
  minLength: (min) => `Minimum ${min} characters required`,
  maxLength: (max) => `Maximum ${max} characters allowed`,
  pattern: "Invalid format",
  url: "Invalid URL",
  match: "Passwords do not match",
};
