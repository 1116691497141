<template>
  <div>
    <v-skeleton-loader type="card" v-if="loading"></v-skeleton-loader>
    <v-card max-width="344" v-else>
      <v-img
        :src="contest.bannerImg"
        height="200px"
        v-if="contest.bannerImg"
      ></v-img>
      <v-img
        src="https://firebasestorage.googleapis.com/v0/b/picsncart-5772f.appspot.com/o/contests%2F1687683554687625825fc8b169c0018f3682c.webp?alt=media&token=9ca6b266-10b8-4da7-825d-aa40920d27ff"
        height="200px"
        v-else
      ></v-img>

      <v-card-title> {{ contest.title }}</v-card-title>
      <v-card-text v-if="contest.judge && contest.judge.name" class="pb-0">
        <h3>Judge</h3>
        <div class="mt-2 d-flex">
          <img
            :src="contest.judge.image"
            :alt="contest.judge.name"
            class="judge-image"
            v-if="contest.judge.image"
          />
          <v-avatar color="info" size="40" class="mb-3" v-else>
            <v-icon color="white">mdi-account-circle</v-icon>
          </v-avatar>
          <div class="ml-2">
            <h4>{{ contest.judge.name }}{{ " " }}</h4>
            <p>{{ contest.judge.description }}</p>
          </div>
        </div>
      </v-card-text>
      <v-card-text>
        <div style="width: 100%">
          <div class="contest-date">
            Last date to register: {{ formatDate(contest.lastDateToRegister) }}
          </div>
          <!-- <v-chip-group active-class="primary white--text" column> -->
          <div class="my-2">
            <v-chip color="secondary"
              >Entry Fee: {{ getEntryFee(contest.entryFee) }}</v-chip
            >
          </div>
          <v-chip color="secondary" class="mr-2"
            >1st Place: Rs.{{ contest.prizeMoney }}</v-chip
          >
          <v-chip color="secondary" v-if="contest.secondPrizeMoney"
            >2nd Place: Rs.{{ contest.secondPrizeMoney }}</v-chip
          >
          <!-- </v-chip-group> -->
        </div>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-btn
          color="primary"
          @click="$emit('view-contest', contest.id)"
          class="d-block ml-auto mt-2"
          >View</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
  
  <script>
export default {
  props: ["contest", "user", "loading"],
  data() {
    return {};
  },
  methods: {
    formatDate(date) {
      const dateParts = date.split("-");
      const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
      return formattedDate;
    },
    getEntryFee(fee) {
      if (!fee) return "Free";
      else return `Rs ${fee}`;
    },
  },
};
</script>
  
  <style scoped>
.v-card {
  position: relative;
  height: 100%;
  /* max-height: 600px;
  min-height: 600px; */
}

.v-card__title {
  word-break: break-word;
}

.v-card__actions {
  /* position: absolute; */
  bottom: 0.5rem;
  width: 100%;
}

.contest-actions {
  /* position: absolute; */
  right: 0;
  bottom: 0;
}

.judge-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.v-chip {
  cursor: auto;
  font-size: 12px;
}

.contest-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-size: 12px;
}

.contest-date {
  font-size: 12px;
}

.product-prize-description {
  font-size: 12px;
}

@media screen and (max-width: 600px) {
  .v-card {
    margin: 0 auto;
  }
}
</style>