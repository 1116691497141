<template>
  <v-app>
    <nav-bar :user="currentUser"></nav-bar>
    <v-main>
      <router-view :user="currentUser" />
    </v-main>
    <footer-comp class="footer" v-if="showFooter()"></footer-comp>
  </v-app>
</template>

<script>
import NavBar from "./components/layout/NavBar.vue";
import FooterComp from "./components/layout/Footer.vue";

import { apiClient, handleError } from "./common/api/api.middleware";
import { useAuthStore } from "./store/auth.store";
import { apis } from "./common/api/api.constants";
import "./assets/css/style.css";
import * as Sentry from "@sentry/vue";

export default {
  components: { NavBar, FooterComp },
  name: "App",
  created() {
    this.checkIfLoggedIn();
  },
  data: () => ({
    //
    currentUser: null,
  }),
  methods: {
    checkIfLoggedIn() {
      const pcnAccessToken = localStorage.getItem("pcn_access_token");
      if (pcnAccessToken) {
        this.getUserDetailsByAccessToken(pcnAccessToken);
      }
    },
    async getUserDetailsByAccessToken(token) {
      try {
        const response = await apiClient.get(
          `${apis.userDetails}?token=${token}`
        );
        const authStore = useAuthStore();
        authStore.user = response.data.data;
        this.currentUser = response.data.data;

        Sentry.setUser({
          user_id: this.currentUser.user_id,
          email: this.currentUser.email_address,
          name: this.currentUser.name,
        });
      } catch (error) {
        handleError(error);
      }
    },
    showFooter() {
      const allowedRoutes = [
        "/",
        "/login",
        "/signup",
        "/forgot-password",
        "/contests",
        "/photographers",
        "/shop",
        "/settings",
        "/privacy-policy",
        "/terms-and-conditions",
        "/tutorial",
      ];

      if (!this.currentUser) {
        return allowedRoutes.includes(this.$route.path);
      } else {
        return (
          allowedRoutes.includes(this.$route.path) &&
          this.currentUser.roles[0].role_name !== "admin"
        );
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
/* @import "./assets/css/style.css"; */

:root {
  --priamry-color: "#009eff";
  --secondary-color: "#ff5a00";
  --text-primary: "#151740";
}

body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

>>> .v-btn {
  text-transform: capitalize;
}

.footer {
  margin-top: 5rem;
}
</style>
