<template>
  <div class="loader" :class="`loader ${type === 'overlay' ? 'overlay' : ''}`">
    <div class="loader-items">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
      <div class="loader-text"> {{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type", "text"],
};
</script>

<style scoped>
.loader {
  display: grid;
  place-items: center;
  height: 100vh;
}

.loader-text {
  margin-top: 20px;
  font-size: 20px;
  color: #fff;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.loader-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>