<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title class="heading">
        Edit Profile
        <v-spacer></v-spacer>
        <v-btn icon @click="show = false">
          <v-icon color="red">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-form ref="form">
          <v-text-field
            label="Name"
            type="text"
            outlined
            dense
            v-model="data.name"
          ></v-text-field>
          <v-text-field
            label="Business name"
            type="text"
            outlined
            dense
            v-model="data.business_name"
            v-if="userModule && userModule.is_photographer"
          ></v-text-field>
          <v-text-field
            label="Email address"
            type="email"
            outlined
            dense
            disabled
            v-model="data.email_address"
          ></v-text-field>
          <v-text-field
            label="Phone number"
            type="text"
            outlined
            dense
            v-model="data.phone_number"
            disabled
          ></v-text-field>
          <v-file-input
            label="Profile photo"
            outlined
            v-model="data.logo_url"
            dense
            accept="image/*"
          ></v-file-input>
          <v-file-input
            label="Cover photo"
            outlined
            v-model="data.cover_url"
            dense
            accept="image/*"
          ></v-file-input>
          <v-row>
            <v-col cols="12" md="12">
              <label>Date of Birth</label>
              <v-row>
                <v-col cols="4">
                  <v-select
                    label="Date"
                    outlined
                    dense
                    v-model="dateOfBirth.date"
                    :items="dateOfBirthItems.date"
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                    label="Month"
                    outlined
                    dense
                    v-model="dateOfBirth.month"
                    :items="dateOfBirthItems.month"
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                    label="Year"
                    outlined
                    dense
                    v-model="dateOfBirth.year"
                    :items="dateOfBirthItems.year"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-select
            label="Gender"
            outlined
            dense
            v-model="data.gender"
            :items="genders"
          ></v-select>
          <v-text-field
            label="Address"
            type="text"
            outlined
            dense
            v-model="data.address"
          ></v-text-field>
          <v-text-field
            label="City"
            type="text"
            outlined
            dense
            v-model="data.city"
          ></v-text-field>
          <v-text-field
            label="Pincode"
            type="number"
            outlined
            dense
            v-model="data.pincode"
          ></v-text-field>
          <v-combobox
            v-model="data.photography_types"
            :items="photographyTypes"
            label="Specialization"
            multiple
            outlined
            chips
            v-if="userModule && userModule.is_photographer"
          ></v-combobox>
          <v-textarea
            label="Description"
            outlined
            v-model="data.description"
            v-if="userModule && userModule.is_photographer"
          ></v-textarea>
          <v-text-field
            type="number"
            hide-spin-buttons
            label="Photography starts from (in Rs)"
            outlined
            dense
            v-model="data.starting_price"
            v-if="userModule && userModule.is_photographer"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          class="d-block mx-auto"
          @click="handleUpdateProfile"
          :loading="loading"
          :disabled="loading"
          >Update</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "pinia";
import { useAuthStore } from "../../../store/auth.store";
import {
  GENDER_LIST,
  NOTIFICATION_TYPE,
  RULES,
  photographerFilters,
} from "../../../common/constants";
import { useUserModuleStore } from "../../../store/userModule.store";
import { showToastNotification } from "../../../plugins/vue-toast-notifications";
export default {
  props: ["visible", "data", "loading"],
  data() {
    return {
      photographyTypes: photographerFilters.categories,
      rules: {
        required: RULES.required,
        description: (value) => {
          return (
            (value && value.length >= 200) ||
            "Description can only have 200 characters"
          );
        },
      },
      genders: GENDER_LIST,
      dateOfBirthItems: {
        date: [],
        month: [],
        year: [],
      },
      dateOfBirth: {
        date: null,
        month: null,
        year: null,
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) this.$emit("close");
      },
    },
    ...mapState(useAuthStore, ["user"]),
    ...mapState(useUserModuleStore, ["userModule"]),
  },
  created() {
    this.initializeDateOfBirthItems();
    this.dateOfBirth = {
      date: this.data?.date_of_birth?.split("-")[0],
      month: this.data?.date_of_birth?.split("-")[1],
      year: this.data?.date_of_birth?.split("-")[2],
    };
  },
  // watch: {
  //   data(newValue) {
  //     this.dateOfBirth = {
  //       date: newValue?.date_of_birth?.split("-")[0],
  //       month: newValue?.date_of_birth?.split("-")[1],
  //       year: newValue?.date_of_birth?.split("-")[2],
  //     };
  //   },
  // },
  methods: {
    handlePriceInput(event) {
      // Regular expression to allow digits, comma, and dot characters only
      const regex = /^[,.0-9]*$/;

      // Check if the input value matches the regular expression
      if (!regex.test(event.target.value)) {
        // If the input value does not match the regex, remove the last character
        this.data.startingPrice = event.target.value.slice(0, -1);
      }
    },
    initializeDateOfBirthItems() {
      const date = [];
      for (let i = 1; i <= 31; i++) {
        date.push(i.length === 1 ? `0${i}` : i);
      }
      this.dateOfBirthItems.date = date;

      const month = [];
      for (let i = 1; i <= 12; i++) {
        month.push(i.length === 1 ? `0${i}` : i);
      }
      this.dateOfBirthItems.month = month;

      const year = [];
      for (let i = 1950; i <= new Date().getFullYear(); i++) {
        year.push(i);
      }
      this.dateOfBirthItems.year = year;
    },
    handleUpdateProfile() {
      const validateDateOfBirth = this.validateDateOfBirth();
      let dateOfBirth = null;
      if (!validateDateOfBirth) {
        showToastNotification(
          "Please select all date of birth fields",
          NOTIFICATION_TYPE.WARNING
        );
        return;
      }

      if (this.dateOfBirth.date && this.dateOfBirth.month && this.dateOfBirth.year) {
        dateOfBirth = `${this.dateOfBirth.date}-${this.dateOfBirth.month}-${this.dateOfBirth.year}`;
      }

      this.$emit("update", { ...this.data, date_of_birth: dateOfBirth });
    },
    validateDateOfBirth() {
      let isValid = true;
      const { date, month, year } = this.dateOfBirth;

      // Check if all fields are null
      const allFieldsNull = !date && !month && !year;

      // Check if any field is selected and others are null
      const anyFieldSelected = date || month || year;
      const anyFieldNull = !date || !month || !year;

      if (allFieldsNull) {
        // All fields are null, continue updating the profile
        console.log(
          "No date of birth fields selected, continuing to update profile..."
        );
        isValid = true;
      } else if (anyFieldSelected && anyFieldNull) {
        // Some fields are selected, but not all
        console.error(
          "Error: If any date of birth field is selected, all fields must be filled."
        );
        isValid = false;
      } else {
        // All fields are selected, proceed with profile update
        console.log(
          "All date of birth fields are selected, continuing to update profile..."
        );
        isValid = true;
      }

      return isValid;
    },
  },
};
</script>

<style scoped>
</style>