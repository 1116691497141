<template>
  <section>
    <div class="back-btn">
      <v-btn text color="primary" link to="/login"
        ><v-icon>mdi-arrow-left</v-icon> Back to login</v-btn
      >
    </div>
    <v-form class="form" ref="form">
      <h1 class="text-center mb-4">Forgot Password</h1>
      <v-text-field
        label="Enter your email address"
        outlined
        :rules="[rules.required]"
        v-model="email_address"
      ></v-text-field>
      <v-btn
        color="primary"
        class="mx-auto d-block"
        @click="resetPassword()"
        :disabled="loading"
        :loading="loading"
        >Reset Password</v-btn
      >
    </v-form>
  </section>
</template>

<script>
import { apis } from '../../common/api/api.constants';
import { apiClient, handleError } from "../../common/api/api.middleware";
import { NOTIFICATION_TYPE } from "../../common/constants";
import { showToastNotification } from "../../plugins/vue-toast-notifications";

export default {
  data() {
    return {
      email_address: null,
      rules: {
        required: (value) => !!value || "This field is required",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      loading: false,
    };
  },
  methods: {
    async resetPassword() {
      if (!this.$refs.form.validate()) return;
      try {
        this.loading = true;
        await apiClient.post(
          apis.userAccountForgotPassword,
          {
            email_address: this.email_address,
          }
        );
        this.loading = false;
        this.email_address = "";
        showToastNotification(
          "Reset password link is sent to your email address.",
          NOTIFICATION_TYPE.SUCCESS
        );
      } catch (error) {
        this.loading = false;
        handleError(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  width: 500px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2rem;
  margin: 0 auto;
}

.back-btn {
  width: 500px;
  margin: 2rem auto;
}

.v-btn {
  width: 200px;
}

@media screen and (max-width: 600px) {
  form {
    width: 90%;
    padding: 1rem;
  }
  .back-btn {
    width: 100%;
    margin: 1rem auto;
  }
}
</style>