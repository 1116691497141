<template>
  <div class="pnc-modules-container">
    <h3>Enable/Disable Modules</h3>
    <loader v-if="isLoading"></loader>
    <div class="module-item">
      <v-checkbox
        v-model="is_photographer"
        label="I'm a photographer"
        @click="updateUserModules('is_photographer')"
        v-if="user && user.roles[0].role_name !== 'admin'"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
import { apis } from "../../common/api/api.constants";
import { apiClient } from "../../common/api/api.middleware";
import { NOTIFICATION_TYPE } from "../../common/constants";
import Loader from "../../components/shared/Loader.vue";
import { showToastNotification } from "../../plugins/vue-toast-notifications";

export default {
  components: { Loader },
  props: ["user"],
  data() {
    return {
      is_photographer: false,
      isLoading: false,
    };
  },
  created() {
    this.getUserModules();
  },
  methods: {
    async getUserModules() {
      try {
        this.isLoading = true;
        const response = await apiClient.get(
          `${apis.userModule}/${this.user?.user_id}`
        );
        const data = response.data.data[0];
        this.is_photographer = data?.is_photographer || false;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        showToastNotification(
          "Failed to fetch user modules",
          NOTIFICATION_TYPE.ERROR
        );
      }
    },
    async updateUserModules(module) {
      try {
        this.isLoading = true;
        await apiClient.put(`${apis.userModule}/${this.user.user_id}`, {
          [module]: this[module],
          user_id: this.user.user_id,
        });
        this.isLoading = false;
        showToastNotification(
          "User module updated successfully",
          NOTIFICATION_TYPE.SUCCESS
        );
      } catch (error) {
        this.isLoading = false;
        showToastNotification(
          "Failed to update user modules",
          NOTIFICATION_TYPE.ERROR
        );
      }
    },
  },
};
</script>

<style scoped>
</style>