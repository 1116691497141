<template>
  <v-dialog v-model="show" width="600" persistent>
    <v-card>
      <v-card-title>
        Register for {{ contest.title }}
        <v-spacer></v-spacer>
        <v-btn color="red" icon @click="show = false">
          <v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-subtitle class="mt-3"
        >Note: Maximum {{ contestTypes[contest.type - 1].max }} can be
        uploaded</v-card-subtitle
      >
      <v-card-text class="mt-5">
        <div
          @drop.prevent="onDrop($event)"
          @dragover.prevent="dragover = true"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="dragover = false"
          @click="$refs.file.click()"
          class="drop-zone"
        >
          <div
            class="d-flex justify-content-center mt-5 mb-4 align-center text-center"
            dense
          >
            <v-icon icon="cloud-upload" class="h3 mr-2">
              mdi-cloud-upload
            </v-icon>
            <p>Drop your file here, or click to select.</p>
          </div>
        </div>
        <div
          class="my-3 d-flex justify-content-between"
          v-if="images.length > 0"
        >
          <p>
            {{ images.length }}
            {{ images.length > 1 ? "images" : "image" }} selected
          </p>
        </div>
        <div class="images mt-3 pt-3" v-if="images.length > 0">
          <div v-for="(image, index) in createdImages" :key="index">
            <!-- show image if contest type is 1 -->
            <img
              :src="image"
              class="img-thumbnail mb-3 mr-3"
              v-if="contestTypes[contest.type - 1].value === 1"
            />
            <!-- show video if contest type is 2 -->
            <video
              :src="image"
              class="img-thumbnail mb-3 mr-3"
              v-if="contestTypes[contest.type - 1].value === 2"
              controls
            />
            <v-icon class="remove-image" @click="removeImage(index)"
              >mdi-close</v-icon
            >
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          class="d-block mx-auto"
          @click="register()"
          :loading="loading"
          :disabled="loading"
          >{{ btnText }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <input
      type="file"
      ref="file"
      @change="handleChange"
      style="display: none"
      :accept="contestTypes[contest.type - 1].allow"
      multiple
    />
    <confirm-dialog
      :text="confirmDialogText"
      :visible="confirmationDialog"
      @confirm="confirmRegistration"
      @close="confirmationDialog = false"
    ></confirm-dialog>
    <loader
      v-if="isPaymentLoading"
      type="overlay"
      text="Verifying payment..."
    ></loader>
  </v-dialog>
</template>

<script>
import { handleError } from "../../common/api/api.middleware";
import { CONTEST_TYPES, NOTIFICATION_TYPE } from "../../common/constants";
import {
  addDoc,
  competitionsRef,
  contestPaymentsRef,
  getDocs,
  query,
  where,
} from "../../config/firebase";
import { uploadFile } from "../../helpers/file-upload";
import { showToastNotification } from "../../plugins/vue-toast-notifications";
import ConfirmDialog from "../../components/shared/ConfirmDialog.vue";
import {
  displayRazorpay,
  verifyRazorpayPayment,
} from "../../helpers/razorpay/razorpay";
import Loader from "../../components/shared/Loader.vue";

export default {
  components: { ConfirmDialog, Loader },
  props: ["contest", "visible", "user"],
  data() {
    return {
      dragover: false,
      images: [],
      createdImages: [],
      loading: false,
      confirmationDialog: false,
      confirmDialogText: "You cannot edit the work once paid and registered",
      isPaymentLoading: false,
      btnText: "Pay and Register",
      allowedImageTypes: ["image/jpeg", "image/png", "image/jpg"],
      contestTypes: CONTEST_TYPES,
    };
  },
  created() {
    this.checkIfContestIsFree();
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) this.$emit("close");
      },
    },
  },
  methods: {
    checkIfContestIsFree() {
      if (parseInt(this.contest.entryFee) === 0) {
        this.btnText = "Register";
      }
    },
    onDrop(event) {
      event.preventDefault();
      event.dataTransfer.files.forEach((file) => {
        if (this.checkFileType(file)) {
          this.images.push(file);
        }
      });
      this.createImages();
    },
    handleChange() {
      Array.from(this.$refs.file.files).forEach((file) => {
        if (this.checkFileType(file)) {
          if (this.checkIfImageAlreadyExists(file)) {
            showToastNotification(
              "Image already exists",
              NOTIFICATION_TYPE.WARNING
            );
            return;
          }
          this.images.push(file);
        } else {
          showToastNotification(
            "Unsupported file type",
            NOTIFICATION_TYPE.WARNING
          );
        }
      });
      if (this.images.length > this.contestTypes[this.contest.type - 1].max) {
        showToastNotification(
          `Only ${this.contestTypes[this.contest.type - 1].max} entry allowed`,
          NOTIFICATION_TYPE.WARNING
        );
        this.images.splice(this.contestTypes[this.contest.type - 1].max);
      }
      this.createImages();
    },
    createImages() {
      this.createdImages = [];
      for (let i = 0; i < this.images.length; i++) {
        this.createdImages.push(URL.createObjectURL(this.images[i]));
      }
    },
    removeImage(index) {
      this.images.splice(index, 1);
      this.createdImages.splice(index, 1);
    },
    checkFileType(file) {
      return this.contestTypes[this.contest.type - 1].allow.includes(file.type);
    },
    checkIfImageAlreadyExists(file) {
      return this.images.some((image) => image.name === file.name);
    },
    async register() {
      if (this.images.length === 0) {
        showToastNotification(
          "Select your work to participate in the contest",
          NOTIFICATION_TYPE.WARNING
        );
        return;
      }
      if (parseInt(this.contest.entryFee) === 0) {
        this.confirmRegistration();
        return;
      }
      this.handlePayment();
    },
    confirmRegistration() {
      this.confirmationDialog = false;
      this.uploadImages();
    },
    async uploadImages() {
      this.loading = true;
      try {
        let count = 0;
        let workImages = [];
        for (let image of this.images) {
          const imgURL = await uploadFile(image, "competition");
          workImages.push(imgURL);
          count++;
          if (count === this.images.length) {
            const work = {
              user_id: this.user.user_id,
              contest_id: this.contest.id,
              Name: this.user.name,
              email_address: this.user.email_address,
              src: workImages,
              createdAt: new Date(),
              payment_status: "paid",
            };
            await addDoc(competitionsRef, work);

            this.loading = false;
            this.show = false;
            this.images = [];
            this.createdImages = [];
            count = 0;
            showToastNotification(
              "You have successfully registered for the contest",
              NOTIFICATION_TYPE.SUCCESS
            );
            window.location.reload();
          }
        }
      } catch (error) {
        this.loading = false;
        handleError(error);
      }
    },
    checkPaymentStatus() {
      return new Promise((resolve, reject) => {
        let isPaymentDone = false;
        const q = query(
          contestPaymentsRef,
          where("user_id", "==", this.user.user_id),
          where("contest_id", "==", this.contest.id)
        );

        getDocs(q)
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              isPaymentDone = true;
            }
            resolve(isPaymentDone);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async savePaymentDetails(paymentDetails) {
      // save payment details to database
      await addDoc(contestPaymentsRef, paymentDetails);
    },
    async handlePayment() {
      const isPaymentDone = await this.checkPaymentStatus();
      if (isPaymentDone) {
        showToastNotification(
          "You have already paid for this contest",
          NOTIFICATION_TYPE.INFO
        );
        this.confirmationDialog = true;
        return;
      }

      const { entryFee } = this.contest;
      if (!entryFee || parseInt(entryFee) === 0) {
        this.confirmationDialog = true;
        return;
      }
      try {
        let data = {
          amount: entryFee,
          receipt: `contest-${this.contest.id}-${this.user.user_id}`,
          email: this.user.email_address,
          contact: this.user.phone_number,
          name: this.user.name,
        };

        const response = await displayRazorpay(data);
        let verificationPayload = {
          payment_id: response.data.payment.razorpay_payment_id,
          order_id: response.data.order.id,
          signature: response.data.payment.razorpay_signature,
        };
        this.isPaymentLoading = true;
        const verifyPayment = await verifyRazorpayPayment(verificationPayload);

        if (verifyPayment.data.isSignatureValid) {
          this.isPaymentLoading = false;
          this.confirmationDialog = true;

          showToastNotification(
            "Payment verified. You can now submit your work",
            NOTIFICATION_TYPE.SUCCESS
          );

          this.savePaymentDetails({
            user_id: this.user.user_id,
            contest_id: this.contest.id,
            razorpay_payment_id: response.data.payment.razorpay_payment_id,
            razorpay_signature: response.data.payment.razorpay_signature,
            razorpay_order_id: response.data.payment.razorpay_order_id,
            order_id: response.data.order.id,
            amount: entryFee,
            payment_date: new Date(),
          });
        } else {
          this.isPaymentLoading = false;
          showToastNotification(
            "Payment verification failed. Please try again",
            NOTIFICATION_TYPE.ERROR
          );
        }
      } catch (error) {
        handleError(error);
      }
    },
  },
};
</script>

<style scoped>
.drop-zone {
  cursor: pointer;
  border: 1px dashed #ccc;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  background-color: #fafafa;
  color: #ccc;
  font-size: 1.2rem;
  font-weight: bold;
  transition: all 0.3s ease;
}

.images {
  overflow-y: scroll;
  min-height: 400px;
  height: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.images > div {
  position: relative;
}

.remove-image {
  position: absolute;
  top: -5px;
  right: 15px;
  cursor: pointer;
  color: #fff;
  background-color: #f00;
  border-radius: 50%;
}

img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

video {
  width: auto;
  height: 400px;
  object-fit: cover;
  cursor: pointer;
}

.upload-btn {
  background-color: transparent;
  color: var(--primary-color);
}

@media screen and (max-width: 600px) {
  p {
    font-size: 14px;
  }

  .drop-zone > div:first-child {
    display: block !important;
  }

  .mdi-cloud-upload {
    font-size: 2rem;
  }

  .img-thumbnail {
    width: 100px;
    height: 100px;
  }

  .images {
    min-height: 250px;
    height: 250px;
  }
}
</style>