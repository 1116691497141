<template>
  <v-footer dark padless>
    <v-card class="px-10 pt-10" width="100%">
      <v-card-text
        class="white--text d-flex justify-space-between footer-items"
      >
        <v-row>
          <v-col cols="12" md="3">
            <h1>Pics N cart</h1>
            <v-img
              src="../../assets//images/picsncart-logo-no-bg.png"
              alt="Pics N Cart Logo"
              width="180"
              class="mt-2"
            />
            <!-- <p class="mt-3">Snap. Win. Shop</p> -->
          </v-col>
          <v-col cols="12" md="2"> </v-col>
          <v-col cols="12" md="3">
            <h2>Useful Links</h2>
            <ul class="mt-5">
              <!-- <li>
                <router-link to="/faqs">FAQs</router-link>
              </li> -->
              <li>
                <router-link to="/privacy-policy">Privacy policy</router-link>
              </li>
              <li>
                <router-link to="/terms-and-conditions"
                  >Terms & Conditions</router-link
                >
              </li>
            </ul>
          </v-col>
          <v-col cols="12" md="1"> </v-col>
          <v-col cols="12" md="3">
            <h2>Stay Connected</h2>
            <div class="mt-5">
              <v-icon>mdi-email</v-icon>
              help@picsncart.com
            </div>
            <!-- <div class="mt-2">
              <v-icon>mdi-phone</v-icon>
              +91 8095413423
            </div> -->
            <!-- <div class="mt-2">
              <v-btn icon>
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-twitter</v-icon>
              </v-btn>
            </div> -->
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text text-center">
        &copy;{{ new Date().getFullYear() }} — <strong>Pics N Cart</strong> All
        rights reserved.
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
};
</script>

<style scoped>
.footer-items > div {
  width: 30%;
}

ul {
  padding-left: 0;
}

ul > li {
  margin-top: 1rem;
}

ul > li > a {
  color: #fff;
  text-decoration: none;
}

li {
  list-style-type: none;
}

@media screen and (max-width: 600px) {
  .footer-items > div {
    width: 100%;
  }

  .col-md-3 {
    text-align: center;
  }

  .v-image {
    margin: 0 auto;
  }

  h1 {
    display: none;
  }
}
</style>