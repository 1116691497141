<template>
  <loader v-if="isLoding"></loader>
  <div class="photography-contest-container" v-else>
    <img :src="contestCover" alt="Contest Cover" class="contest-cover-img" />
    <h2 class="contest--title">{{ contest.title }}</h2>
    <v-card class="contest-content-container">
      <v-card-text
        :class="`contest-card ${contest.showWinners ? 'pb-0' : 'pb-3'}`"
      >
        <div class="contest-brand contest-brand-mobile">
          <img
            :src="contest.bannerImg"
            alt="Contest Banner"
            class="contest-banner-img"
            v-if="contest.bannerImg"
          />
          <img
            :src="defaultBanner"
            alt="Contest Banner"
            class="contest-banner-img"
            v-else
          />
          <h3 class="text-center mt-2 mb-5">{{ contest.title }}</h3>
          <p>
            Start Date:<strong>{{ formatDate(contest.startDate) }}</strong>
          </p>
          <p>
            Last Date to register:
            <strong>{{ formatDate(contest.lastDateToRegister) }}</strong>
          </p>
          <p>
            Result Date: <strong>{{ formatDate(contest.resultDate) }}</strong>
          </p>
          <p>
            Entry Fee:
            <strong>{{
              contest.entryFee ? "Rs." + contest.entryFee : "Free"
            }}</strong>
          </p>
          <p>
            🏆 1st Place: <strong>Rs.{{ contest.prizeMoney }}</strong>
          </p>
          <p>
            🥈 2nd Place<strong>Rs.{{ contest.secondPrizeMoney }}</strong>
          </p>
        </div>
        <div class="contest-details">
          <h3>Contest Details</h3>
          <v-divider class="my-2"></v-divider>
          <p
            class="mt-2 contest-rules mb-0"
            v-if="contest.rules"
            v-html="contestRules"
          ></p>
          <v-btn
            text
            color="primary"
            class="px-0"
            v-if="contestRules"
            @click="getContestRules(contest.rules)"
          >
            {{ showRules ? "Read more" : "Read less" }}
          </v-btn>
          <p class="mt-2 contest-rules" v-else>No rules found</p>
          <h3 class="mt-4">Judge</h3>
          <div class="mt-2 d-flex" v-if="contest.judge && contest.judge.name">
            <img
              :src="contest.judge.image"
              :alt="contest.judge.name"
              class="judge-image"
              v-if="contest.judge.image"
            />
            <v-avatar color="info" size="40" class="mb-3" v-else>
              <v-icon color="white">mdi-account-circle</v-icon>
            </v-avatar>
            <div class="ml-2">
              <h4>{{ contest.judge.name }}</h4>
              <p>{{ contest.judge.description }}</p>
            </div>
          </div>
          <div v-else>
            <p>No judge found</p>
          </div>
          <v-btn
            color="success"
            block
            class="mx-auto mt-4 participate-btn-mobile"
            @click="checkIfLoggedIn()"
            :disabled="checkIfButtonIsDisabled()"
            >{{ getBtnText() }}</v-btn
          >
        </div>
        <div class="contest-brand contest-brand-desktop">
          <img
            :src="contest.bannerImg"
            alt="Contest Banner"
            class="contest-banner-img"
            v-if="contest.bannerImg"
          />
          <img
            :src="defaultBanner"
            alt="Contest Banner"
            class="contest-banner-img"
            v-else
          />
          <h3 class="text-center mt-2 mb-5">{{ contest.title }}</h3>
          <p>
            Start Date:
            <strong>
              {{ formatDate(contest.startDate) }}
            </strong>
          </p>
          <p>
            Last Date to register:
            <strong>
              {{ formatDate(contest.lastDateToRegister) }}
            </strong>
          </p>
          <p>
            Result Date:
            <strong>
              {{ formatDate(contest.resultDate) }}
            </strong>
          </p>
          <p>
            Entry Fee:
            <strong>
              {{ contest.entryFee ? "Rs." + contest.entryFee : "Free" }}
            </strong>
          </p>
          <p>
            🏆 1st Place:
            <strong> Rs.{{ contest.prizeMoney }} </strong>
          </p>
          <p>
            🥈 2nd Place
            <strong> Rs.{{ contest.secondPrizeMoney }} </strong>
          </p>
          <v-btn
            color="success"
            block
            class="d-block mx-auto mt-4"
            @click="checkIfLoggedIn()"
            :disabled="checkIfButtonIsDisabled()"
            >{{ getBtnText() }}</v-btn
          >
        </div>
      </v-card-text>
      <v-card-text class="pt-0" v-if="contest.showWinners">
        <div class="winners-detail-container">
          <h3>Winners</h3>
          <v-divider class="my-2"></v-divider>
          <v-row class="mt-1">
            <v-col cols="12" md="12">
              <v-row class="align-cente winner-row">
                <v-col cols="12" md="6">
                  <img
                    v-if="
                      contest &&
                      contest.winners &&
                      contest.winners[0] &&
                      contest.winners[0].click_image
                    "
                    :src="contest.winners[0].click_image"
                    alt="1st Place"
                    class="first-place-img--original"
                  />
                  <img
                    v-else
                    src="../../assets/images/first-place.jpg"
                    alt="1st Place"
                    class="first-place-img"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <div class="winners-detail">
                    <img
                      src="../../assets/images/first-place.jpg"
                      alt="1st Place"
                      class="winning-badge"
                    />
                    <img
                      v-if="
                        contest &&
                        contest.winners &&
                        contest.winners[0] &&
                        contest.winners[0].image
                      "
                      :src="contest.winners[0].image"
                      alt="Winner photo"
                      class="winner-photo"
                    />
                    <v-avatar color="info" size="100" class="mb-3" v-else>
                      <v-icon color="white" size="100"
                        >mdi-account-circle</v-icon
                      >
                    </v-avatar>
                    <a
                      v-if="
                        contest &&
                        contest.winners &&
                        contest.winners[0] &&
                        contest.winners[0].pnc_profile
                      "
                      :href="contest.winners[0].pnc_profile"
                      target="_blank"
                    >
                      <h3 class="winner-name">
                        {{
                          contest &&
                          contest.winners &&
                          contest.winners[0] &&
                          contest.winners[0].name
                            ? contest.winners[0].name
                            : "Not declared"
                        }}
                      </h3></a
                    >
                    <h3 class="winner-name" v-else>
                      {{
                        contest &&
                        contest.winners &&
                        contest.winners[0] &&
                        contest.winners[0].name
                          ? contest.winners[0].name
                          : "Not declared"
                      }}
                    </h3>
                    <!-- <p><b>First place</b></p> -->
                    <p class="winner-address">
                      {{
                        contest &&
                        contest.winners &&
                        contest.winners[0] &&
                        contest.winners[0].address
                          ? contest.winners[0].address
                          : ""
                      }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-divider class="mx-4" vertical></v-divider>
            <v-col cols="12" md="12">
              <v-row class="align-center winner-row">
                <v-col cols="12" md="6">
                  <img
                    v-if="
                      contest &&
                      contest.winners &&
                      contest.winners[1] &&
                      contest.winners[1].click_image
                    "
                    :src="contest.winners[1].click_image"
                    alt="2nd Place"
                    class="first-place-img--original"
                  />
                  <img
                    v-else
                    src="../../assets/images/second-place.jpg"
                    alt="2nd Place"
                    class="first-place-img"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <div class="winners-detail">
                    <img
                      src="../../assets/images/second-place.jpg"
                      alt="1st Place"
                      class="winning-badge"
                    />
                    <img
                      v-if="
                        contest &&
                        contest.winners &&
                        contest.winners[1] &&
                        contest.winners[1].image
                      "
                      :src="contest.winners[1].image"
                      alt="Winner photo"
                      class="winner-photo"
                    />
                    <v-avatar color="info" size="100" class="mb-3" v-else>
                      <v-icon color="white" size="100"
                        >mdi-account-circle</v-icon
                      >
                    </v-avatar>
                    <a
                      v-if="
                        contest &&
                        contest.winners &&
                        contest.winners[1] &&
                        contest.winners[1].pnc_profile
                      "
                      :href="contest.winners[1].pnc_profile"
                      target="_blank"
                    >
                      <h3 class="winner-name">
                        {{
                          contest &&
                          contest.winners &&
                          contest.winners[1] &&
                          contest.winners[1].name
                            ? contest.winners[1].name
                            : "Not declared"
                        }}
                      </h3>
                    </a>
                    <h3 class="winner-name" v-else>
                      {{
                        contest &&
                        contest.winners &&
                        contest.winners[1] &&
                        contest.winners[1].name
                          ? contest.winners[1].name
                          : "Not declared"
                      }}
                    </h3>
                    <!-- <p><b>Second place</b></p> -->
                    <p class="winner-address">
                      {{
                        contest &&
                        contest.winners &&
                        contest.winners[1] &&
                        contest.winners[1].address
                          ? contest.winners[1].address
                          : ""
                      }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <register-modal
      :contest="contest"
      :visible="showRegisterModal"
      @close="showRegisterModal = false"
      :user="user"
    ></register-modal>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { handleError } from "../../common/api/api.middleware";
import { NOTIFICATION_TYPE } from "../../common/constants";
import {
  competitionsRef,
  contestsRef,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "../../config/firebase";
import { showToastNotification } from "../../plugins/vue-toast-notifications";
import { formatDate } from "../../utils/common.utils";
import { useAuthStore } from "../../store/auth.store";
import RegisterModal from "./RegisterModal.vue";
import Loader from "../../components/shared/Loader.vue";
export default {
  components: { RegisterModal, Loader },
  computed: {
    ...mapState(useAuthStore, ["user"]),
  },
  data() {
    return {
      contestCover:
        "https://firebasestorage.googleapis.com/v0/b/picsncart-5772f.appspot.com/o/contests%2Fcontest-cover.jpg?alt=media&token=81d6374b-b856-4241-bf14-70e0e6b322c3",
      contest: {},
      defaultBanner:
        "https://firebasestorage.googleapis.com/v0/b/picsncart-5772f.appspot.com/o/contests%2F1687683554687625825fc8b169c0018f3682c.webp?alt=media&token=9ca6b266-10b8-4da7-825d-aa40920d27ff",
      showRegisterModal: false,
      isLoding: true,
      btnText: "Participate",
      showRules: false,
      contestRules: "",
    };
  },
  created() {
    this.getContest();
  },
  methods: {
    async getContest() {
      try {
        this.isLoding = true;
        const contestId = this.$route.query.id;
        const docRef = doc(contestsRef, contestId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          this.contest = {
            ...docSnap.data(),
            id: docSnap.id,
          };
          this.getContestRules(this.contest.rules);
        } else {
          // doc.data() will be undefined in this case
          showToastNotification("Contest not found", NOTIFICATION_TYPE.ERROR);
        }
        this.isLoding = false;
      } catch (error) {
        this.isLoding = false;
        handleError(error);
      }
    },
    formatDate(date) {
      return formatDate(date);
    },
    checkIfLoggedIn() {
      if (!this.user) {
        showToastNotification(
          "Please login to participate",
          NOTIFICATION_TYPE.WARNING
        );

        setTimeout(() => {
          this.$router.push(`/login?redirect=/contest?id=${this.contest.id}`);
        }, 2000);
      } else {
        // check if user is already registered
        const q = query(
          competitionsRef,
          where("contest_id", "==", this.contest.id),
          where("user_id", "==", this.user.user_id)
        );

        getDocs(q)
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              showToastNotification(
                "You are already registered for this contest",
                NOTIFICATION_TYPE.WARNING
              );
            } else {
              this.showRegisterModal = true;
            }
          })
          .catch((error) => {
            handleError(error);
          });
      }
    },
    checkIfAlreadyRegistered() {
      if (this.user) {
        const q = query(
          competitionsRef,
          where("contest_id", "==", this.contest.id),
          where("user_id", "==", this.user.user_id)
        );

        getDocs(q)
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              this.btnText = "Registered";
            }
          })
          .catch((error) => {
            handleError(error);
          });
      }
    },
    getContestRules(rules) {
      this.showRules = !this.showRules;
      if (this.showRules) {
        this.contestRules = rules.slice(0, 900);
      } else {
        this.contestRules = rules;
      }
    },
    checkIfButtonIsDisabled() {
      const lastDateToRegister = new Date(this.contest.lastDateToRegister);
      const currentDate = new Date();
      return currentDate > lastDateToRegister || this.btnText === "Registered";
    },
    getBtnText() {
      const alreadyRegistered = this.checkIfAlreadyRegistered();
      if (alreadyRegistered) {
        return "Registered";
      }

      const lastDateToRegister = new Date(this.contest.lastDateToRegister);
      const currentDate = new Date();
      if (currentDate > lastDateToRegister) {
        return "Registration Closed";
      }

      return "Participate";
    },
  },
};
</script>

<style scoped>
.photography-contest-container {
  position: relative;
  background-color: #e5e5e5;
  height: 100%;
}
.contest-cover-img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.contest-content-container {
  width: 70%;
  margin: -5rem auto 4rem auto;
}

.contest--title {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  position: absolute;
  top: 20%;
  width: 100%;
  color: #fff;
}

.contest-card {
  display: flex;
}

.contest-details {
  width: 60%;
  padding: 1rem;
}

.contest-brand {
  width: 40%;
  padding: 1rem;
}

.contest-banner-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.judge-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.contest-brand-mobile {
  display: none;
}

.contest-brand-desktop {
  display: block;
}

.participate-btn-mobile {
  display: none;
}

.winners-detail-container {
  padding: 1rem;
}

.first-place-img {
  width: 120px;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
}

.first-place-img--original {
  width: 80%;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.winners-detail {
  display: grid;
  place-items: center;
  position: relative;
}

.winner-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.winning-badge {
  width: 50px;
  position: absolute;
  top: 0;
  left: -2rem;
}

@media screen and (max-width: 600px) {
  .contest-content-container {
    width: 90%;
  }

  .contest-cover-img {
    height: 300px;
  }

  .contest--title {
    top: 3rem;
  }

  .contest-content-container {
    margin-top: -6rem;
  }

  .contest-card {
    flex-direction: column;
    padding: 0;
  }

  .contest-card > div {
    width: 100%;
    flex: 1;
  }

  .contest-brand-mobile {
    display: block;
  }

  .contest-brand-desktop {
    display: none;
  }

  .participate-btn-mobile {
    display: block;
  }

  .contest-rules {
    text-align: justify;
  }

  .winner-row {
    flex-direction: column-reverse;
    text-align: center;
  }

  .first-place-img--original {
    width: 100%;
  }

  .winning-badge {
    left: 2rem;
  }
}
</style>