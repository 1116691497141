<template>
  <div class="c38 doc-content">
    <h2 class="text-center mb-5">PICS N CART </h2>
    <p class="c37">
      <span class="c14 c6"
        >The visitors do not have to worry about the whereabouts of the private
        information you share with us. We are committed to secure your personal
        information you share with us on our website. The reason to create this
        notice is to inform you that what we do, who we share it with and how we
        collect your information.</span
      >
    </p>
    <ul class="c24 lst-kix_list_1-0 start">
      <li class="c9 c22 li-bullet-0"><span class="c6 c32">Who we are</span></li>
    </ul>
    <p class="c9">
      <span class="c4">PICS N CART</span
      ><span class="c14"
        >, a partnership firm based in Udupi, India and when we refer
        &ldquo;we&rdquo; &ldquo;our&rdquo; &ldquo;us&rdquo; in this notice
        refers to </span
      ><span class="c4">PICS N CART</span><span class="c14">&nbsp;only. </span
      ><span class="c4">PICS N CART</span
      ><span class="c14"
        >&nbsp;is a trustworthy E-Commerce brand and we offer one stop solution
        for Photographer&rsquo;s needs i.e. sale of World Class Accessories,
        Services, Tutorials by experts in the field, etc. </span
      ><span class="c4">PICS N CART</span
      ><span class="c14">&nbsp;owns and operates&nbsp;</span
      ><span class="c4">www.picncart.com</span>
    </p>
    <p class="c9">
      <span class="c4">PICS N CART</span
      ><span class="c14"
        >&nbsp;uses the personal information for two different purposes: to meet
        your requests for certain information and to connect with you in order
        to complete your online profile on </span
      ><span class="c4">www.picncart.com</span
      ><span class="c14 c6"
        >. If you connect with us via Facebook, we&rsquo;ll receive the basic
        information like your name, email address, and location. We won&rsquo;t
        post anything to Facebook without your consent.</span
      >
    </p>
    <ul class="c24 lst-kix_list_1-0">
      <li class="c9 c22 li-bullet-0">
        <span class="c12 c6">How and why we collect the information?</span>
      </li>
    </ul>
    <p class="c35">
      <span class="c14"><br /></span
      ><span class="c21">We collect the information when:</span>
    </p>
    <ul class="c24 lst-kix_list_1-1 start">
      <li class="c20 li-bullet-0">
        <span class="c14 c6"
          >When you fill the information or you participate on our forum section
          (for example, you sign up to receive emails or to create a profile on
          our website or mobile app)</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c14 c6"
          >When you post the content or you make some posts on our social media
          accounts, and</span
        >
      </li>
      <li class="c17 li-bullet-0">
        <span class="c14 c6"
          >Sometimes automatically for example, technical information that
          transfers to us when you visit our website or app on your phone or
          PC.</span
        >
      </li>
    </ul>
    <p class="c35">
      <span class="c14"><br /></span><span class="c4">PICS N CART</span
      ><span class="c21"
        >&nbsp;doesn&rsquo;t, won&rsquo;t, sell information to anyone; we only
        use the information to:</span
      >
    </p>
    <ul class="c24 lst-kix_list_1-1">
      <li class="c20 li-bullet-0">
        <span class="c14 c6"
          >Receive and reply feedback and queries from you.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c14 c6"
          >Receive and process your information in case, of technical assistance
          required.</span
        >
      </li>
      <li class="c5 li-bullet-0">
        <span class="c14 c6"
          >To personalize or improve the way our products and content presented
          to you.</span
        >
      </li>
      <li class="c17 li-bullet-0">
        <span class="c14 c6">Manage our competitions.</span>
      </li>
    </ul>
    <p class="c35">
      <span class="c14"><br /></span
      ><span class="c21">When you post the content on our website</span>
    </p>
    <ul class="c24 lst-kix_list_1-1">
      <li class="c20 li-bullet-0">
        <span class="c14 c6"
          >Permit you to share your experiences with fellow visitors on forums
          and community section, mobile app along with social media sites.</span
        >
      </li>
      <li class="c17 li-bullet-0">
        <span class="c14 c6"
          >Permit you to start communications among our community members and we
          make sure that content/messages posted comply with our
          guidelines.</span
        >
      </li>
    </ul>
    <p class="c26"><span class="c14 c6"></span></p>
    <ul class="c24 lst-kix_list_1-0">
      <li class="c9 c22 li-bullet-0">
        <span class="c6 c12">Use of cookies</span>
      </li>
    </ul>
    <p class="c9">
      <span class="c4">PICS N CART</span
      ><span class="c6 c14"
        >&nbsp;uses the cookies to improve the user experience and interactive
        sessions on our website. When you visit the website, the information
        provided by you might be retrieved in order to enable some features of
        the website without having re-signed in, along these lines, it offers
        you easy access to various features and functions of the website. The
        cookies would help your username to be pre-entered so that you would
        only need to enter the password when you sign in. The cookie would be
        stored on your PC and we won&rsquo;t be able to access this
        information.</span
      >
    </p>
    <p class="c9">
      <span class="c14"
        >The acceptance of cookies depends on the browser settings on the web
        browser accessed by you. As a member or a visitor, you&rsquo;ll be
        assigned a permanent cookie file on your PC&rsquo;s hard drive, when you
        visit </span
      ><span class="c4">PICS N CART</span
      ><span class="c14 c6"
        >. The permanent cookies file remains on your PC&rsquo;s hard drive
        until you delete these files manually.</span
      >
    </p>
    <p class="c9">
      <span class="c14 c6"
        >Local shared objects are same like browser cookies and can store data
        more complex than simple text.</span
      >
    </p>
    <p class="c9">
      <span class="c14 c6"
        >This Website may similarly utilize Web reference points (otherwise
        called clear gifs, pixel labels or Web bugs), which are minor designs
        with a one of a kind identifier, comparative in function to cookies,
        that are set in the code of a Web page. We utilize Web beacons points to
        screen the traffic patterns of visitors starting with one page inside
        our Website then onto the next, to convey or speak with treats, to
        comprehend whether you have gone to our Website from an online promotion
        showed on an outsider site, and to enhance website performance. We
        additionally may enable our specialist to utilize web reference points
        to enable us to know which emails have been opened by receivers and to
        track the visitor traffic and traffic on our Website. This helps us
        measure the effectiveness of our content and different
        contributions.</span
      >
    </p>
    <p class="c9">
      <span class="c14 c6"
        >You may block, erase or disable cookies, Web beacons, and other Web
        technology by correcting your program or gadget settings. However, if
        you disable this technology, you will most likely be unable to access
        certain highlights or services on our Website. Disabling this technology
        may also imply that you are required to re-enter account information,
        (for example, Member ID and password) on a few occasions during any
        browsing session.</span
      >
    </p>
    <p class="c9">
      <span class="c14 c6"
        >All the personal information data maintained and secured in accordance
        with GDPR and cookies policy.</span
      >
    </p>
    <h1 class="c28"><span class="c34">Disclaimer</span></h1>
    <p class="c37">
      <span class="c14 c6"
        >Each and every content/video/image shown on the website is purely for
        information/marketing/education purpose. Our platform does not intent to
        violate any copyright laws or monetize through it and thus the copyright
        belongs to their respective owners. In case you find any problem or your
        content being misused, kindly contact the broadcaster prior to making
        any copyright claim. </span
      ><span class="c14 c6 c7">You can contact us on&nbsp;</span
      ><span class="c4 c7 c41">www.picncart.com</span
      ><span class="c14 c6 c7">&nbsp;and your issue shall be resolved.</span>
    </p>
    <p class="c2">
      <span class="c27">Privacy Policy - </span
      ><span class="c4">PICS N CART</span>
    </p>
    <p class="c2"><span class="c6 c30">Introduction:</span></p>
    <p class="c16">
      <span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;recognizes the importance of privacy of its users and also of
        maintaining confidentiality of the information provided by its users as
        a responsible data controller and data processer.</span
      >
    </p>
    <p class="c16">
      <span class="c3"
        >This Privacy Policy provides for the practices for handling and
        securing user&#39;s Personal Information (defined hereunder) by </span
      ><span class="c4">PICS N CART</span
      ><span class="c3 c6">&nbsp;and its subsidiaries and affiliates.</span>
    </p>
    <p class="c8">
      <span class="c3"
        >This Privacy Policy is applicable to any person (&lsquo;User&rsquo;)
        who purchase, intend to purchase, or inquire about any product(s) or
        service(s) made available by </span
      ><span class="c4">PICS N CART</span
      ><span class="c3">&nbsp;through any of </span
      ><span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;customer interface channels including its website, mobile site,
        mobile app &amp; offline channels including call centres and offices
        (collectively referred herein as&nbsp;</span
      ><span class="c13">&quot;Sales Channels&quot;</span
      ><span class="c3 c6">).</span>
    </p>
    <p class="c16">
      <span class="c3"
        >For the purpose of this Privacy Policy, wherever the context so
        requires &quot;you&quot; or &quot;your&quot; shall mean User and the
        term &quot;we&quot;, &quot;us&quot;, &quot;our&quot; shall mean </span
      ><span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >. For the purpose of this Privacy Policy, Website means the website(s),
        mobile site(s) and mobile app(s).</span
      >
    </p>
    <p class="c16">
      <span class="c3 c6"
        >By using or accessing the Website or other Sales Channels, the User
        hereby agrees with the terms of this Privacy Policy and the contents
        herein. If you disagree with this Privacy Policy please do not use or
        access our Website or other Sales Channels.</span
      >
    </p>
    <p class="c16">
      <span class="c3"
        >This Privacy Policy does not apply to any website(s), mobile sites and
        mobile apps of third parties, even if their websites/products are linked
        to our Website. User should take note that information and privacy
        practices of </span
      ><span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;business partners, advertisers, sponsors or other sites to which </span
      ><span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;provides hyperlink(s), may be materially different from this
        Privacy Policy. Accordingly, it is recommended that you review the
        privacy statements and policies of any such third parties with whom they
        interact.</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >This Privacy Policy is an integral part of your User Agreement with </span
      ><span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;and all capitalized terms used, but not otherwise defined herein,
        shall have the respective meanings as ascribed to them in the User
        Agreement.</span
      >
    </p>
    <p class="c19">
      <span class="c1">USERS OUTSIDE THE GEOGRAPHICAL LIMITS OF INDIA</span>
    </p>
    <p class="c16">
      <span class="c3">Please note that the data shared with </span
      ><span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;shall be primarily processed in India and such other
        jurisdictions where a third party engaged by </span
      ><span class="c4">PICS N CART</span
      ><span class="c3">&nbsp;may process the data on </span
      ><span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;behalf. By agreeing to this policy, you are providing </span
      ><span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;with your explicit consent to process your personal information
        for the purpose(s) defined in this policy. The data protection
        regulations in India or such other jurisdictions mentioned above may
        differ from those of your country of residence.</span
      >
    </p>
    <p class="c16">
      <span class="c3"
        >If you have any concerns in the processing your data and wish to
        withdraw your consent, you may do so by writing to the following email
        id: </span
      ><span class="c7 c29">privacy</span
      ><span class="c4 c7">&nbsp;PICS N CART</span
      ><span class="c3 c6"
        >. However, if such processing of data is essential for us to be able to
        provide service to you, then we may not be able to serve or confirm your
        bookings after your withdrawal of consent.
      </span>
    </p>
    <p class="c19">
      <span class="c1">TYPE OF INFORMATION WE COLLECT AND ITS LEGAL BASIS</span>
    </p>
    <p class="c16">
      <span class="c3 c6"
        >The information as detailed below is collected for us to be able to
        provide the services chosen by you and also to fulfil our legal
        obligations as well as our obligations towards third parties as per our
        User Agreement.</span
      >
    </p>
    <p class="c8">
      <span class="c13">&quot;Personal Information&quot;</span
      ><span class="c3 c6"
        >&nbsp;of User shall include the information shared by the User and
        collected by us for the following purposes:</span
      >
    </p>
    <p class="c8">
      <span class="c13">Registration on the Website:&nbsp;</span
      ><span class="c3 c6"
        >Information which you provide while subscribing to or registering on
        the Website, including but not limited to information about your
        personal identity such as name, gender, age, profile picture etc., your
        contact details such as your email address, postal addresses, telephone
        (mobile or otherwise) and/or fax numbers. The information may also
        include information such as your banking details (including credit/debit
        card) and billing information payment history etc. (as shared by
        you).</span
      >
    </p>
    <p class="c8">
      <span class="c13">Other information:</span
      ><span class="c3 c6"
        >&nbsp;We many also collect some other information and documents
        including but not limited to:</span
      >
    </p>
    <ul class="c24 lst-kix_list_3-0 start">
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6"
          >Transactional history (other than banking details) about your
          e-commerce activities, buying behaviour.</span
        >
      </li>
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6"
          >Your usernames, passwords, email addresses and other security-related
          information used by you in relation to our Services.</span
        >
      </li>
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6"
          >Data either created by you or by a third party and which you wish to
          store on our servers such as image files, documents etc.</span
        >
      </li>
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6"
          >Data available in public domain or received from any third party
          including social media channels, search engines including but not
          limited to personal or non-personal information from your linked
          social media channels (like name, email address, friend list, profile
          pictures or any other information that is permitted to be received as
          per your account settings) as a part of your account
          information.</span
        >
      </li>
      <li class="c25 c22 li-bullet-0">
        <span class="c3"
          >For international purchase, Users, in compliance with the Liberalized
          Remittance Scheme (LRS) of RBI or any other law may be required to
          provide details such as their PAN information or passport details
          number or any such information required by Service Provider. Such
          information shall be strictly used as per the aforesaid requirements
          only. In case a User does not wish to provide this information, </span
        ><span class="c4">PICS N CART</span
        ><span class="c3"
          >&nbsp;may not be able to process with the order. </span
        ><span class="c4">PICS N CART</span
        ><span class="c3 c6"
          >&nbsp;will never share User&rsquo;s PAN details without their prior
          consent unless otherwise such action is required by any law
          enforcement authority for investigation, by court order or in
          reference to any legal process.</span
        >
      </li>
    </ul>
    <p class="c36 c33"><span class="c3 c6"></span></p>
    <p class="c36">
      <span class="c3"
        >Such information shall be strictly used for the aforesaid specified
        &amp; lawful purpose only. User further understands that </span
      ><span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;may share this information with the end service provider or any
        other third party for provision and facilitation of the desired
        purchase. </span
      ><span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;will always redact all/any sensitive &amp; confidential
        information contained in passbook, bank statement or any other identity
        card submitted for the purpose of availing a service, promotional offer
        or booking a product on the Website. In case a User does not wish to
        provide this information or opts for deletion of the information already
        provided, </span
      ><span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;may not be able to process the desired booking request. </span
      ><span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;will never share any of the above information collected including
        PAN card details, Aadhar Card details without their prior consent unless
        otherwise such action is required by any law enforcement authority for
        investigation, by court order or in reference to any legal
        process.</span
      >
    </p>
    <p class="c33 c36"><span class="c3 c6"></span></p>
    <p class="c19">
      <span class="c1">HOW WE USE YOUR PERSONAL INFORMATION:</span>
    </p>
    <p class="c16">
      <span class="c3 c6"
        >The Personal Information collected maybe used in the following
        manner:</span
      >
    </p>
    <p class="c2">
      <span class="c13 c6">While making a purchase / booking</span>
    </p>
    <p class="c16">
      <span class="c3 c6"
        >While making a purchase / booking, we may use Personal Information
        including, payment details which include cardholder name, credit/debit
        card number (in encrypted form) with expiration date, banking details,
        wallet details etc. as shared and allowed to be stored by you. We may
        also use the information of purchaser list as available in or linked
        with your account. This information is presented to the User at the time
        of making a booking to enable you to complete your purchase / bookings
        expeditiously.</span
      >
    </p>
    <p class="c2">
      <span class="c13 c6"
        >We may also use your Personal Information for several reasons including
        but not limited to:</span
      >
    </p>
    <ul class="c24 lst-kix_list_4-0 start">
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6"
          >confirm your booking/purchase with respective service
          providers;</span
        >
      </li>
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6">keep you informed of the transaction status;</span>
      </li>
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6"
          >send purchase / booking confirmations either via S&lt;S or WhatsApp
          or any other messaging service;</span
        >
      </li>
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6"
          >send any updates or changes to your purchase / booking(s);</span
        >
      </li>
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6"
          >allow our customer service to contact you, if necessary;</span
        >
      </li>
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6"
          >customize the content of our website, mobile site and mobile
          app.</span
        >
      </li>
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6"
          >request for reviews of products or services or any other
          improvements.</span
        >
      </li>
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6">send verification message(s) or email(s);</span>
      </li>
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6"
          >validate/authenticate your account and to prevent any misuse or
          abuse.</span
        >
      </li>
      <li class="c22 c25 li-bullet-0">
        <span class="c3 c6"
          >contact you on your birthday/anniversary to offer a special gift or
          offer.</span
        >
      </li>
    </ul>
    <p class="c2 c33"><span class="c13 c6"></span></p>
    <p class="c2">
      <span class="c13 c6">Marketing Promotions, Research and Programs:</span>
    </p>
    <p class="c16">
      <span class="c3"
        >Marketing promotions, research and programs help us to identify your
        preferences, develop programs and improve user experience. </span
      ><span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;frequently sponsors promotions to give its Users the opportunity
        to win great deals and purchase / booking related prizes. Personal
        Information collected by us for such activities may include contact
        information and survey questions. We use such Personal Information to
        notify contest winners to develop promotions and product improvements.
        As a registered User, you will also occasionally receive updates from us
        about fare sales in your area, special offers, new </span
      ><span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;services, other noteworthy items (like savings and benefits on
        products, accessories) and marketing programs.</span
      >
    </p>
    <p class="c16">
      <span class="c3 c6"
        >In addition, you may look forward to receiving periodic marketing
        emails, newsletters and exclusive promotions offering special
        deals.</span
      >
    </p>
    <p class="c16">
      <span class="c3 c6"
        >From time to time we may add or enhance services available on the
        Website. To the extent these services are provided, and used by you, we
        will use the Personal Information you provide to facilitate the
        service(s) requested. For example, if you email us with a question, we
        will use your email address, name, nature of the question, etc. to
        respond to your question. We may also store such Personal Information to
        assist us in making the Website the better and easier to use for our
        Users.</span
      >
    </p>
    <p class="c0">
      <span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;may from time to time launch reward programs by way of which
        users may stand to win special rewards or other rewards. We may use your
        Personal Information to enrol you in the rewards program and status of
        the same will be visible each time you log in to the Website. Depending
        on the reward program, each time you win a reward, </span
      ><span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;may share your Personal Information with a third party that will
        be responsible for fulfilling the reward to you. You may however choose
        to opt out of such reward programs by writing to us. For various
        purposes such as fraud detection, offering bookings on credit etc., we
        at times may verify information of customers on selective basis,
        including their credit information. Additionally, </span
      ><span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;may share your Personal Information, anonymized and/ or
        aggregated data to a third party that </span
      ><span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;may engage to perform certain tasks on its behalf, including but
        not limited to payment processing, data hosting, data processing and
        assessing credit worthiness for offering bookings on credit.</span
      >
    </p>
    <p class="c19">
      <span class="c1">HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION?</span>
    </p>
    <p class="c16">
      <span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;will retain your Personal Information on its servers for as long
        as is reasonably necessary for the purposes listed in this policy. In
        some circumstances we may retain your Personal Information for longer
        periods of time, for instance where we are required to do so in
        accordance with any legal, regulatory, tax or accounting
        requirements.</span
      >
    </p>
    <p class="c0">
      <span class="c3 c6"
        >Where your personal data is no longer required we will ensure it is
        either securely deleted or stored in a way which means it will no longer
        be used by the business.</span
      >
    </p>
    <p class="c19"><span class="c1">COOKIES AND SESSION DATA</span></p>
    <p class="c2"><span class="c13 c6">Cookies:</span></p>
    <p class="c16">
      <span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;uses cookies to personalize your experience on the Website and
        the advertisements that maybe displayed. </span
      ><span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;use of cookies is similar to that of any other reputable online
        companies.</span
      >
    </p>
    <p class="c16">
      <span class="c3 c6"
        >Cookies are small pieces of information that are stored by your browser
        on your device&#39;s hard drive. Cookies allow us to serve you better
        and more efficiently. Cookies also allow ease of access, by logging you
        in without having to type your login name each time (only your password
        is needed); we may also use such cookies to display any advertisement(s)
        to you while you are on the Website or to send you offers (or similar
        emails &ndash; provided you have not opted out of receiving such emails)
        focusing on destinations which may be of your interest.</span
      >
    </p>
    <p class="c16">
      <span class="c3 c6"
        >A cookie may also be placed by our advertising servers, or third-party
        advertising companies. Such cookies are used for purposes of tracking
        the effectiveness of advertising served by us on any website, and also
        to use aggregated statistics about your visits to the Website in order
        to provide advertisements in the Website or any other website about
        services that may be of potential interest to you. The third-party
        advertising companies or advertisement providers may also employ
        technology that is used to measure the effectiveness of the
        advertisements. All such information is anonymous. This anonymous
        information is collected through the use of a pixel tag, which is an
        industry standard technology and is used by all major websites. They may
        use this anonymous information about your visits to the Website in order
        to provide advertisements about goods and services of potential interest
        to you. No Personal Information is collected during this process. The
        information so collected during this process, is anonymous, and does not
        link online actions to a User</span
      >
    </p>
    <p class="c16">
      <span class="c3"
        >Most web browsers automatically accept cookies. Of course, by changing
        the options on your web browser or using certain software programs, you
        can control how and whether cookies will be accepted by your browser. </span
      ><span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;supports your right to block any unwanted Internet activity,
        especially that of unscrupulous websites. However, blocking</span
      ><span class="c4">&nbsp;PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;cookies may disable certain features on the Website, and may
        hinder an otherwise seamless experience to purchase or use certain
        services available on the Website. Please note that it is possible to
        block cookie activity from certain websites while permitting cookies
        from websites you trust.</span
      >
    </p>
    <p class="c2">
      <span class="c13 c6">Automatic Logging of Session Data:</span>
    </p>
    <p class="c0">
      <span class="c3 c6"
        >Each time you access the Website your session data gets logged. Session
        data may consist of various aspects like the IP address, operating
        system and type of browser software being used and the activities
        conducted by the User while on the Website. We collect session data
        because it helps us analyse User&rsquo;s choices, browsing pattern
        including the frequency of visits and duration for which a User is
        logged on. It also helps us diagnose problems with our servers and lets
        us better administer our systems. The aforesaid information cannot
        identify any User personally. However, it may be possible to determine a
        User&#39;s Internet Service Provider (ISP), and the approximate
        geographic location of User&#39;s point of connectivity through the
        above session data.</span
      >
    </p>
    <p class="c19">
      <span class="c1">WITH WHOM YOUR PERSONAL INFORMATION IS SHARED</span>
    </p>
    <p class="c2">
      <span class="c13 c6">Service Providers and suppliers:</span>
    </p>
    <p class="c16">
      <span class="c3"
        >Your information shall be shared with the end service providers like </span
      ><span class="c3 c7"
        >airlines, hotels, bus service providers, cab rental, railways or any
        other suppliers who are responsible for fulfilling your booking</span
      ><span class="c3">. </span
      ><sup><a href="#cmnt1" id="cmnt_ref1">[a]</a></sup
      ><span class="c3">You may note that while making a booking with </span
      ><span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;you authorize us to share your information with the said service
        providers and suppliers. It is pertinent to note that </span
      ><span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;does not authorize the end service provider to use your
        information for any other purpose(s) except as may be for fulfilling
        their part of service. However, how the said service providers/suppliers
        use the information shared with them is beyond the purview and control
        of </span
      ><span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;as they process Personal Information as independent data
        controllers, and hence we cannot be made accountable for the same. You
        are therefore advised to review the privacy policies of the respective
        service provider or supplier whose services you choose to avail.</span
      >
    </p>
    <p class="c16">
      <span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;does not sell or rent individual customer names or other Personal
        Information of Users to third parties except sharing of such information
        with our business / alliance partners or vendors who are engaged by us
        for providing various referral services and for sharing promotional and
        other benefits to our customers from time to time basis their purchase /
        booking history with us.</span
      >
    </p>
    <p class="c2"><span class="c13 c6">COMPANIES IN THE SAME GROUP:</span></p>
    <p class="c16">
      <span class="c3 c6"
        >In the interests of improving personalization and service efficiency,
        we may, under controlled and secure circumstances, share your Personal
        Information with our affiliate or associate entities. This will enable
        us to provide you with information about various products and services,
        which might interest you; or help us address your questions and requests
        in relation to your bookings.</span
      >
    </p>
    <p class="c16">
      <span class="c3">If the assets of </span
      ><span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;are acquired, our customer information may also be transferred to
        the acquirer depending upon the nature of such acquisition. In addition,
        as part of business expansion/development/restructuring or for any other
        reason whatsoever, if we decide to sell/transfer/assign our business,
        any part thereof, any of our subsidiaries or any business units, then as
        part of such restructuring exercise customer information including the
        Personal Information collected herein shall be transferred
        accordingly.</span
      >
    </p>
    <p class="c2">
      <span class="c13 c6">BUSINESS PARTNERS and THIRD-PARTY VENDORS:</span>
    </p>
    <p class="c16">
      <span class="c3"
        >We may also share certain filtered Personal Information to our
        corporate affiliates or business partners who may contact the customers
        to offer certain products or services, which may include free or paid
        products / services, which will enable the customer to have better
        purchase experience or to avail certain benefits specially made for </span
      ><span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;customers. Examples of such partners are entities offering
        co-branded credit cards, product insurance, insurance cover against loss
        of product, banking cards or similar sensitive information etc. If you
        choose to avail any such services offered by our business partners, the
        services so availed will be governed by the privacy policy of the
        respective service provider.</span
      >
    </p>
    <p class="c16">
      <span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;may share your Personal Information to third party that </span
      ><span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;may engage to perform certain tasks on its behalf, including but
        not limited to payment processing, data hosting, and data processing
        platforms.</span
      >
    </p>
    <p class="c16">
      <span class="c3 c6"
        >We use non-identifiable Personal Information of Users in aggregate or
        anonymized form to build higher quality, more useful online services by
        performing statistical analysis of the collective characteristics and
        behaviour of our customers and visitors, and by measuring demographics
        and interests regarding specific areas of the Website. We may provide
        anonymous statistical information based on this data to suppliers,
        advertisers, affiliates and other current and potential business
        partners. We may also use such aggregate data to inform these third
        parties as to the number of people who have seen and clicked on links to
        their websites. Any Personal Information which we collect and which we
        may use in an aggregated format is our property. We may use it, in our
        sole discretion and without any compensation to you, for any legitimate
        purpose including without limitation the commercial sale thereof to
        third parties.</span
      >
    </p>
    <p class="c16">
      <span class="c3">Occasionally, </span><span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;will hire a third party for market research, surveys etc. and
        will provide information to these third parties specifically for use in
        connection with these projects. The information (including aggregate
        cookie and tracking information) we provide to such third parties,
        alliance partners, or vendors are protected by confidentiality
        agreements and such information is to be used solely for completing the
        specific project, and in compliance with the applicable
        regulations.</span
      >
    </p>
    <p class="c2"><span class="c13 c6">DISCLOSURE OF INFORMATION</span></p>
    <p class="c16">
      <span class="c3">In addition to the circumstances described above,</span
      ><span class="c4">&nbsp;PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;may disclose User&#39;s Personal Information if required to do
        so:</span
      >
    </p>
    <ul class="c24 lst-kix_list_5-0 start">
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6"
          >by law, required by any enforcement authority for investigation, by
          court order or in reference to any legal process;</span
        >
      </li>
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6">to conduct our business;</span>
      </li>
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6"
          >for regulatory, internal compliance and audit exercise(s)</span
        >
      </li>
      <li class="c25 c22 li-bullet-0">
        <span class="c3 c6">to secure our systems; or</span>
      </li>
      <li class="c25 c22 li-bullet-0">
        <span class="c3"
          >to enforce or protect our rights or properties of </span
        ><span class="c4">PICS N CART</span
        ><span class="c3 c6"
          >&nbsp;or any or all of its affiliates, associates, employees,
          directors or officers or when we have reason to believe that
          disclosing Personal Information of User(s) is necessary to identify,
          contact or bring legal action against someone who may be causing
          interference with our rights or properties, whether intentionally or
          otherwise, or when anyone else could be harmed by such
          activities.</span
        >
      </li>
    </ul>
    <p class="c0">
      <span class="c3 c6"
        >Such disclosure and storage may take place without your knowledge. In
        that case, we shall not be liable to you or any third party for any
        damages howsoever arising from such disclosure and storage.</span
      >
    </p>
    <p class="c19 c33"><span class="c1 c6"></span></p>
    <p class="c19">
      <span class="c1"
        >HOW CAN YOU OPT-OUT OF RECEIVING OUR PROMOTIONAL E-MAILS?</span
      >
    </p>
    <p class="c0">
      <span class="c3"
        >You will occasionally receive e-mail updates from us about fare sales
        in your area, special offers, new </span
      ><span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;services, and other noteworthy items. We hope you will find these
        updates interesting and informative. If you wish not to receive them,
        please click on the &quot;unsubscribe&quot; link or follow the
        instructions in each e-mail message.</span
      >
    </p>
    <p class="c19">
      <span class="c1"
        >PERMISSIONS REQUIRED FOR USING OUR MOBILE APPLICATIONS</span
      >
    </p>
    <p class="c16">
      <span class="c3">When the </span><span class="c4">PICS N CART</span
      ><span class="c3"
        >&nbsp;app is installed on your phone or tablet, a list of permissions
        appear and are needed for the app to function effectively. There is no
        option to customize the list. The permissions that </span
      ><span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;requires and the data that shall be accessed and its use is as
        below:</span
      >
    </p>
    <p class="c2"><span class="c30 c6">Android permissions:</span></p>
    <p class="c8">
      <span class="c13">Device &amp; App history:</span
      ><span class="c3 c6"
        >&nbsp;We need your device permission to get information about your
        device, like OS (operating system) name, OS version, mobile network,
        hardware model, unique device identifier, preferred language, etc. Basis
        these inputs, we intend to optimize your booking experience, use OS
        specific capabilities to drive great in-funnel experiences using
        components of device&rsquo;s OS, etc.</span
      >
    </p>
    <p class="c8">
      <span class="c13">Camera:</span
      ><span class="c3 c6"
        >&nbsp;This permission enables you to use your camera from within the
        application to upload a new profile picture, upload an image for sharing
        hotel reviews and also allows you to take videos to upload as video
        reviews on our application. This permission will also enable you to scan
        QR codes for easy UPI payments.</span
      >
    </p>
    <p class="c8">
      <span class="c13">Identity:</span
      ><span class="c3 c6"
        >&nbsp;This permission enables us to know about details of your
        account(s) on your mobile device. We use this info to auto-fill your
        email ID&rsquo;s and provide a typing free in-funnel experience. It
        helps us map email ID&rsquo;s to a particular user to give you the
        benefit of exclusive travel offers, wallet cash-backs, etc. It also
        allows facilitating your Facebook and Google+ login.</span
      >
    </p>
    <p class="c8">
      <span class="c13">Location:</span
      ><span class="c3 c6"
        >&nbsp;This permission enables us to give you the benefit of location
        specific deals and provide you a personalized in-funnel
        experience.</span
      >
    </p>
    <p class="c8">
      <span class="c13">SMS:</span
      ><span class="c3 c6"
        >&nbsp;If you allow us to access your SMS, we read your SMS to autofill
        or prepopulate &lsquo;OTP&rsquo; while making a transaction and to
        validate your mobile number. This provides you a seamless purchase
        experience while making a booking and you don&rsquo;t need to move out
        of the app to read the SMS and then enter it in the app.</span
      >
    </p>
    <p class="c8">
      <span class="c13 c7">Phone:</span
      ><span class="c3 c7"
        >&nbsp;The app requires access to make phone calls so that you can make
        phone calls to hotels, airlines and our customer contact centres
        directly through the app.</span
      >
    </p>
    <p class="c8">
      <span class="c13">Contacts:</span
      ><span class="c3 c6"
        >&nbsp;If you allow us to access your contacts, it enables us to provide
        a lot of social features to you such as sharing your experience with
        your friends, inviting your friends to try our app, send across referral
        links to your friends, etc. We may also use this information to make
        recommendations. This information will be stored on our servers and
        synced from your phone.</span
      >
    </p>
    <p class="c8">
      <span class="c13">Photo/ Media/ Files:</span
      ><span class="c3 c6"
        >&nbsp;The libraries in the app use these permissions to allow map data
        to be saved to your phone&#39;s external storage, like SD cards. By
        saving map data locally, your phone doesn&#39;t need to re-download the
        same map data every time you use the app</span
      >
    </p>
    <p class="c8">
      <span class="c13">Wi-Fi connection information:</span
      ><span class="c3 c6"
        >&nbsp;When you allow us the permission to detect your Wi-Fi connection,
        we optimize your experience such as more detailing on maps, better image
        loading etc.</span
      >
    </p>
    <p class="c8">
      <span class="c13">Device ID &amp; Call information:</span
      ><span class="c3 c6"
        >&nbsp;This permission is used to detect your Android ID through which
        we can uniquely identify users. It also lets us know your contact
        details using which we pre-populate specific fields to ensure a seamless
        booking experience.</span
      >
    </p>
    <p class="c8">
      <span class="c13">Bluetooth:</span
      ><span class="c3 c6"
        >&nbsp;This permission enables us to provide you services of the third
        parties that make available certain features of their products/services
        on our application.
      </span>
    </p>
    <p class="c8">
      <span class="c13">Video/Audio:</span
      ><span class="c3 c6"
        >&nbsp;This permission enables you to upload videos on our application
        or to submit video reviews and thereby providing you with new ways of
        providing reviews of your experience on our application. The audio
        permission enables us to ensure that the video content that you upload
        has a clear audio in its background.</span
      >
    </p>
    <p class="c8">
      <span class="c13">Phone Number:</span
      ><span class="c3 c6"
        >&nbsp;We need the permission of access to phone number to provide a
        seamless login experience, to optimise your booking experience and to
        enable you to obtain UPI services and to prevent fraud in some cases on
        our application</span
      >
    </p>
    <p class="c8">
      <span class="c13">IMEI/IMSI:</span
      ><span class="c3 c6"
        >&nbsp;This permission enables us to identifying different users
        uniquely and helps us to prevent frauds on our application.</span
      >
    </p>
    <p class="c8">
      <span class="c13">Subscription Information:</span
      ><span class="c3 c6"
        >&nbsp;Your subscription information enables to provide you a seamless
        experience depending upon your network and to optimise the
        application&rsquo;s performance.
      </span>
    </p>
    <p class="c8">
      <span class="c13">SIM Serial Number:</span
      ><span class="c3 c6"
        >&nbsp;This permission enables us to read your sim serial number and
        helps us in authenticating your mobile number for UPI registration. It
        helps us to ensure that the mobile number you are using is actually
        present on your device that you are using to register for UPI.</span
      >
    </p>
    <p class="c8 c33"><span class="c3 c6"></span></p>
    <p class="c2"><span class="c30 c6">IOS Permissions:</span></p>
    <p class="c8">
      <span class="c13">Notifications:</span
      ><span class="c3 c6"
        >&nbsp;If you opt in for notifications, it enables us to send across
        exclusive deals, promotional offers, product related updates, etc. on
        your device. If you do not opt for this, updates, booking confirmation,
        refund (in case of cancellation), etc. will be sent through SMS.</span
      >
    </p>
    <p class="c8">
      <span class="c13">Contacts:</span
      ><span class="c3 c6"
        >&nbsp;If you opt in for contacts permission, it enables us to provide a
        lot of social features to you such as sharing experience with your
        friends, inviting your friends to try our app, send across referral
        links to your friends, etc. We will also use this information to make
        recommendations related to products. This information will be stored on
        our servers and synced from your phone.</span
      >
    </p>
    <p class="c0">
      <span class="c13">Location:</span
      ><span class="c3 c6"
        >&nbsp;This permission enables us to give you the benefit of location
        specific deals and provide you a personalized in-funnel experience.
      </span>
    </p>
    <p class="c19">
      <span class="c1">HOW WE PROTECT YOUR PERSONAL INFORMATION?</span>
    </p>
    <p class="c0">
      <span class="c3 c6"
        >All payments on the Website are secured. This means all Personal
        Information you provide is transmitted using TLS (Transport Layer
        Security) encryption. TSL is a proven coding system that lets your
        browser automatically encrypt, or scramble, data before you send it to
        us. Website has stringent security measures in place to protect the
        loss, misuse, and alteration of the information under our control.
        Whenever you change or access your account information, we offer the use
        of a secure server. Once your information is in our possession we adhere
        to strict security guidelines, protecting it against unauthorized
        access.</span
      >
    </p>
    <p class="c19">
      <span class="c1">WITHDRAWAL OF CONSENT AND PERMISSION</span>
    </p>
    <p class="c0">
      <span class="c3"
        >You may withdraw your consent to submit any or all Personal Information
        or decline to provide any permissions on its Website as covered above at
        any time. In case, you choose to do so then your access to the Website
        may be limited, or we might not be able to provide the services to you.
        You may withdraw your consent by sending an email to&nbsp;</span
      ><span class="c7 c23">privacy@</span
      ><span class="c4 c7">&nbsp;PICS N CART</span
      ><span class="c29 c7">&nbsp;URL</span>
    </p>
    <p class="c19">
      <span class="c1">YOUR RIGHTS QUA PERSONAL INFORMATION</span>
    </p>
    <p class="c0">
      <span class="c3"
        >You may access your Personal Information from your user account with </span
      ><span class="c4">PICS N CART</span
      ><span class="c3"
        >. You may also correct your personal information or delete such
        information (except some mandatory fields) from your user account
        directly. If you don&rsquo;t have such a user account, then you write </span
      ><span class="c3 c7">to&nbsp;</span><span class="c23 c7">privacy@</span
      ><span class="c4 c7">&nbsp;PICS N CART</span
      ><span class="c29 c7">&nbsp;URL</span>
    </p>
    <p class="c19">
      <span class="c1">ELIGIBILITY TO TRANSACT WITH </span
      ><span class="c4">PICS N CART</span>
    </p>
    <p class="c0">
      <span class="c3"
        >You must at least 18 years of age to transact directly with </span
      ><span class="c4">PICS N CART</span
      ><span class="c3 c6"
        >&nbsp;and also to consent to the processing of your personal
        data.</span
      >
    </p>
    <p class="c19"><span class="c1">CHANGES TO THE PRIVACY POLICY</span></p>
    <p class="c16">
      <span class="c3 c6"
        >We reserve the rights to revise the Privacy Policy from time to time to
        suit various legal, business and customer requirement. We will duly
        notify the users as may be necessary.</span
      >
    </p>
    <p class="c19"><span class="c1">CHANGES TO THE PRIVACY POLICY</span></p>
    <p class="c16">
      <span class="c3 c6"
        >We reserve the rights to revise the Privacy Policy from time to time to
        suit various legal, business and customer requirement. We will duly
        notify the users as may be necessary.</span
      >
    </p>
    <p class="c0">
      <span class="c13"
        >You may always submit concerns regarding this Privacy Policy via email
        to us at&nbsp;</span
      ><span class="c7 c40">privacy@</span
      ><span class="c4 c7">&nbsp;PICS N CART URL</span
      ><span class="c13 c7">.</span><span class="c13 c6">&nbsp;</span>
    </p>
    <p class="c0">
      <span class="c4">PICS N CART</span
      ><span class="c13"
        >&nbsp;shall endeavour to respond to all reasonable concerns and
        inquires.</span
      >
    </p>
    <p class="c10"><span class="c6 c15"></span></p>
    <p class="c10" id="h.gjdgxs"><span class="c15 c6"></span></p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
ul.lst-kix_list_1-0 {
  list-style-type: none;
}

.lst-kix_list_3-0 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_5-7 {
  list-style-type: none;
}

ul.lst-kix_list_5-8 {
  list-style-type: none;
}

.lst-kix_list_3-1 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_3-2 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_5-5 {
  list-style-type: none;
}

ul.lst-kix_list_5-6 {
  list-style-type: none;
}

ul.lst-kix_list_1-3 {
  list-style-type: none;
}

.lst-kix_list_3-5 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_5-0 {
  list-style-type: none;
}

ul.lst-kix_list_1-4 {
  list-style-type: none;
}

ul.lst-kix_list_1-1 {
  list-style-type: none;
}

.lst-kix_list_3-4 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_1-2 {
  list-style-type: none;
}

ul.lst-kix_list_5-3 {
  list-style-type: none;
}

ul.lst-kix_list_1-7 {
  list-style-type: none;
}

.lst-kix_list_3-3 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_5-4 {
  list-style-type: none;
}

ul.lst-kix_list_1-8 {
  list-style-type: none;
}

ul.lst-kix_list_5-1 {
  list-style-type: none;
}

ul.lst-kix_list_1-5 {
  list-style-type: none;
}

ul.lst-kix_list_5-2 {
  list-style-type: none;
}

ul.lst-kix_list_1-6 {
  list-style-type: none;
}

.lst-kix_list_3-8 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_3-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_3-7 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_5-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-8 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_5-3 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-7 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_5-2 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_5-1 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_4-8 {
  list-style-type: none;
}

.lst-kix_list_5-7 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_4-6 {
  list-style-type: none;
}

.lst-kix_list_5-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_5-8 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_4-7 {
  list-style-type: none;
}

ul.lst-kix_list_4-0 {
  list-style-type: none;
}

ul.lst-kix_list_4-1 {
  list-style-type: none;
}

.lst-kix_list_5-4 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_4-4 {
  list-style-type: none;
}

.lst-kix_list_5-5 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_4-5 {
  list-style-type: none;
}

ul.lst-kix_list_4-2 {
  list-style-type: none;
}

ul.lst-kix_list_4-3 {
  list-style-type: none;
}

.lst-kix_list_6-1 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_6-3 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_6-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_6-4 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_6-2 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_6-8 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_6-5 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_6-7 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_6-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-7 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-4 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-5 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-8 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_3-7 {
  list-style-type: none;
}

ul.lst-kix_list_3-8 {
  list-style-type: none;
}

ul.lst-kix_list_3-1 {
  list-style-type: none;
}

ul.lst-kix_list_3-2 {
  list-style-type: none;
}

ul.lst-kix_list_3-0 {
  list-style-type: none;
}

ul.lst-kix_list_3-5 {
  list-style-type: none;
}

ul.lst-kix_list_3-6 {
  list-style-type: none;
}

ul.lst-kix_list_3-3 {
  list-style-type: none;
}

ul.lst-kix_list_3-4 {
  list-style-type: none;
}

.lst-kix_list_4-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-1 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-4 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-3 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-5 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-2 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-6 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_6-6 {
  list-style-type: none;
}

ul.lst-kix_list_6-7 {
  list-style-type: none;
}

ul.lst-kix_list_6-4 {
  list-style-type: none;
}

ul.lst-kix_list_2-8 {
  list-style-type: none;
}

ul.lst-kix_list_6-5 {
  list-style-type: none;
}

ul.lst-kix_list_6-8 {
  list-style-type: none;
}

ul.lst-kix_list_2-2 {
  list-style-type: none;
}

.lst-kix_list_1-0 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_2-3 {
  list-style-type: none;
}

ul.lst-kix_list_2-0 {
  list-style-type: none;
}

ul.lst-kix_list_2-1 {
  list-style-type: none;
}

ul.lst-kix_list_6-2 {
  list-style-type: none;
}

ul.lst-kix_list_2-6 {
  list-style-type: none;
}

ul.lst-kix_list_6-3 {
  list-style-type: none;
}

.lst-kix_list_1-1 > li:before {
  content: "o  ";
}

.lst-kix_list_1-2 > li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_2-7 {
  list-style-type: none;
}

ul.lst-kix_list_6-0 {
  list-style-type: none;
}

ul.lst-kix_list_2-4 {
  list-style-type: none;
}

ul.lst-kix_list_6-1 {
  list-style-type: none;
}

ul.lst-kix_list_2-5 {
  list-style-type: none;
}

.lst-kix_list_1-3 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_1-4 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_1-7 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_1-5 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_1-6 > li:before {
  content: "\0025aa  ";
}

li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

.lst-kix_list_2-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-1 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_1-8 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-2 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-3 > li:before {
  content: "\0025cf  ";
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c19 {
  padding-top: 0pt;
  border-bottom-color: #d0d6e1;
  border-bottom-width: 0.8pt;
  padding-bottom: 4pt;
  line-height: 2;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c20 {
  background-color: #ffffff;
  margin-left: 72pt;
  padding-top: 14pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c26 {
  background-color: #ffffff;
  margin-left: 36pt;
  padding-top: 14pt;
  padding-bottom: 14pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c17 {
  background-color: #ffffff;
  margin-left: 72pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 14pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c5 {
  background-color: #ffffff;
  margin-left: 72pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c9 {
  background-color: #ffffff;
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 14pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c28 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c4 {
  -webkit-text-decoration-skip: none;
  /* color: #ff0000; */
  font-weight: 400;
  /* text-decoration: underline; */
  text-decoration-skip-ink: none;
  font-size: 11.5pt;
  /* font-style: italic; */
}

.c35 {
  background-color: #ffffff;
  margin-left: 36pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c23 {
  -webkit-text-decoration-skip: none;
  /* color: #ff0000; */
  font-weight: 400;
  /* text-decoration: underline; */
  text-decoration-skip-ink: none;
}

.c25 {
  margin-left: 62.2pt;
  padding-top: 0pt;
  padding-bottom: 3.8pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c37 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 14pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c10 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c40 {
  -webkit-text-decoration-skip: none;
  /* color: #ff0000; */
  font-weight: 700;
  /* text-decoration: underline; */
  text-decoration-skip-ink: none;
}

.c0 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c8 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c16 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c2 {
  padding-top: 0pt;
  padding-bottom: 6pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c36 {
  padding-top: 0pt;
  padding-bottom: 3.8pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c21 {
  font-size: 11.5pt;
  color: #212529;
  font-weight: 700;
}

.c18 {
  color: #2f2f2f;
  font-weight: 400;
  font-size: 7.5pt;
}

.c1 {
  font-size: 14px;
  /* color: #dc4b06; */
  font-weight: 400;
}

.c3 {
  color: #2f2f2f;
  font-weight: 400;
}

.c29 {
  /* color: #ff0000; */
  font-weight: 400;
}

.c12 {
  color: #212529;
  font-weight: 400;
}

.c34 {
  color: #212529;
  font-weight: 400;
  font-size: 26.5pt;
}

.c27 {
  font-size: 13.5pt;
  /* color: #bc0100; */
  font-weight: 400;
}

.c39 {
  color: #000000;
  font-weight: 400;
}

.c14 {
  color: #212529;
  font-weight: 400;
  font-size: 11.5pt;
}

.c30 {
  color: #2f2f2f;
  font-weight: 700;
  font-size: 11.5pt;
}

.c13 {
  color: #2f2f2f;
  font-weight: 700;
}

.c31 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  text-align: left;
}

.c32 {
  color: #212529;
  font-weight: 700;
  font-size: 12pt;
}

.c15 {
  color: #000000;
  font-weight: 400;
}

.c6 {
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}

.c38 {
  background-color: #ffffff;
  padding: 1rem 72pt 72pt 72pt;
}

.c11 {
  border: 1px solid black;
  margin: 5px;
}

.c24 {
  padding: 0;
  margin: 0;
}

.c33 {
}

.c41 {
  vertical-align: baseline;
}

.c7 {
  /* background-color: #ffff00; */
}

.c22 {
  padding-left: 0pt;
}

.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  /* font-style: italic; */
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  color: #000000;
  font-size: 16px;
}

p {
  margin: 0;
  color: #000000;
  font-size: 16px;
}

h1 {
  padding-top: 12pt;
  color: #2f5496;
  font-size: 16pt;
  padding-bottom: 0pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h5 {
  color: #000000;
  font-weight: 700;
  padding-bottom: 2pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h6 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 7.5pt;
  padding-bottom: 8pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: left;
}

@media screen and (max-width: 600px) {
  .c38 {
    padding: 1rem;
  }

  p {
    margin-left: 0 !important;
  }

  ul > li {
    padding-left: 20px;
    margin-left: 20px !important;
  }
}
</style>