<template>
  <v-dialog v-model="show" persistent width="600">
    <v-form ref="form" @submit.prevent="handleSubmit">
      <v-card>
        <v-card-title>
          {{ isEdit ? "Edit" : "Create new" }} contest
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-text-field
            outlined
            dense
            label="Contest title"
            v-model="contest.title"
            :rules="rules.title"
          ></v-text-field>
          <v-textarea
            outlined
            dense
            label="Contest rules"
            v-model="contest.rules"
            :rules="rules.rules"
          ></v-textarea>
          <v-dialog
            ref="dialog1"
            v-model="modal1"
            :return-value.sync="contest.startDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="contest.startDate"
                label="Contest start date"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="contest.startDate"
              scrollable
              :min="minDate"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal1 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog1.save(contest.startDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-dialog
            ref="dialog2"
            v-model="modal2"
            :return-value.sync="contest.lastDateToRegister"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="contest.lastDateToRegister"
                label="Last date to register"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="contest.lastDateToRegister"
              scrollable
              :min="minDate"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog2.save(contest.lastDateToRegister)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-dialog
            ref="dialog3"
            v-model="modal3"
            :return-value.sync="contest.resultDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="contest.resultDate"
                label="Result date"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="contest.resultDate"
              scrollable
              :min="minDate"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal3 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog3.save(contest.resultDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-checkbox
            label="This contest is free"
            v-model="isFreeContest"
          ></v-checkbox>
          <v-text-field
            outlined
            dense
            label="Entry fee"
            v-model="contest.entryFee"
            prepend-inner-icon="mdi-currency-inr"
            type="number"
            hide-spin-buttons
            :rules="rules.entryFee"
            v-if="!isFreeContest"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            label="Prize money"
            v-model="contest.prizeMoney"
            prepend-inner-icon="mdi-currency-inr"
            type="number"
            hide-spin-buttons
            :rules="rules.prizeMoney"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            label="2nd Prize money"
            v-model="contest.secondPrizeMoney"
            prepend-inner-icon="mdi-currency-inr"
            type="number"
            hide-spin-buttons
            :rules="rules.secondPrizeMoney"
          ></v-text-field>
          <v-checkbox
            label="This contest includes product as a prize alse"
            v-model="productCheckbox"
          ></v-checkbox>
          <v-textarea
            v-if="productCheckbox"
            label="Enter product prize description"
            v-model="contest.productPrize"
            outlined
            dense
          ></v-textarea>
          <v-file-input
            outlined
            label="Select contest banner"
            dense
            v-model="contest.bannerImg"
          ></v-file-input>
          <v-checkbox
            label="This contest includes an external judge"
            v-model="judgeCheckbox"
          ></v-checkbox>
          <div class="judge-info-container" v-if="judgeCheckbox">
            <v-text-field
              label="Judge name"
              outlined
              dense
              v-model="contest.judge.name"
            ></v-text-field>
            <v-text-field
              label="Judge designation"
              v-model="contest.judge.description"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              label="Judge mobile number"
              v-model="contest.judge.contact"
              outlined
              dense
              :rules="rules['judge.contact']"
              type="number"
              hide-spin-buttons
            ></v-text-field>
            <v-file-input
              outlined
              label="Judge image"
              dense
              v-model="contest.judge.image"
            ></v-file-input>
          </div>
          <v-select
            outlined
            dense
            label="Select contest type"
            :rules="rules.contestType"
            item-text="name"
            item-value="value"
            v-model="contest.type"
            :items="contestTypes"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            class="d-block mx-auto"
            :loading="loading"
            :disabled="loading"
            type="submit"
            >{{ isEdit ? "Update" : "Create" }} contest</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { CONTEST_TYPES } from "../../../common/constants";

export default {
  props: ["visible", "contest", "loading", "isEdit"],
  data() {
    return {
      modal1: false,
      modal2: false,
      modal3: false,
      productCheckbox: false,
      judgeCheckbox: false,
      rules: {
        title: [(v) => !!v || "Contest title is required"],
        rules: [(v) => !!v || "Contest rules are required"],
        startDate: [(v) => !!v || "Start date is required"],
        lastDateToRegister: [
          (v) => !!v || "Last date to register is required",
          // last date to register should not be before start date
          (v) =>
            new Date(v) >= new Date(this.contest.startDate) ||
            "Last date to register should not be before start date",
        ],
        resultDate: [
          (v) => !!v || "Result date is required",
          // result date should not be before start date and last date to register
          (v) =>
            new Date(v) >= new Date(this.contest.startDate) ||
            "Result date should not be before start date",
          (v) =>
            new Date(v) >= new Date(this.contest.lastDateToRegister) ||
            "Result date should not be before last date to register",
        ],
        prizeMoney: [(v) => !!v || "Prize money is required"],
        secondPrizeMoney: [(v) => !!v || "2nd Prize money is required"],
        entryFee: [
          (v) => (!this.isFreeContest && !!v) || "Entry fee is required",
          // entry fee should be less than prizeMoney
          (v) =>
            parseInt(v) < parseInt(this.contest.prizeMoney) ||
            "Entry fee should be less than prize money",
        ],
        productPrize: [(v) => !!v || "Product prize description is required"],
        "judge.contact": [
          (v) =>
            this.contest.judgeCheckbox ||
            !!v ||
            "Judge mobile number is required",
          (v) => /^[6-9]{1}[0-9]{9}$/.test(v) || "Invalid mobile number",
        ],
        contestType: [(v) => !!v || "Contest type is required"],
      },
      minDate: new Date().toISOString().substr(0, 10),
      isFreeContest: false,
      contestTypes: CONTEST_TYPES,
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  mounted() {
    if (this.contest && this.contest.judge && this.contest.judge.name) {
      this.judgeCheckbox = true;
    } else {
      this.judgeCheckbox = false;
    }
  },
  methods: {
    async handleSubmit() {
      if (!this.$refs.form.validate()) return;
      this.$emit(this.isEdit ? "update" : "create");
    },
  },
};
</script>

<style scoped>
</style>