<template>
  <div class="stats-row">
    <stats-card
      v-for="(stat, index) in stats"
      :key="index"
      :stat="stat"
    ></stats-card>
  </div>
</template>
  
  <script>
import { getDocs } from "../../../config/firebase";
import { apiClient, handleError } from "../../../common/api/api.middleware";
import StatsCard from "./StatsCard.vue";
import { contestsRef } from "../../../config/firebase";
import { apis } from "../../../common/api/api.constants";

export default {
  components: { StatsCard },
  name: "Stats",
  data() {
    return {
      stats: [
        {
          title: "Active Contests",
          icon: "mdi-account-group-outline",
          count: 0,
          link: "/contests",
        },
        {
          title: "Vendors",
          icon: "mdi-account-group",
          count: 0,
          link: "/vendors",
        },
        {
          title: "Photographers",
          icon: "mdi-camera-account",
          count: 0,
          link: "/photographers?view=list",
        },
        {
          title: "Total Orders",
          icon: "mdi-cart",
          count: 0,
        },
      ],
    };
  },
  created() {
    this.getDashboardStats();
  },
  methods: {
    async getDashboardStats() {
      try {
        const response = await apiClient.get(apis.adminDashboardStats);
        this.stats[2].count = response.data.data.photographer_count;

        // get all contests
        const querySnapshot = await getDocs(contestsRef);
        let contests = querySnapshot.docs.map((doc) => doc.data());
        contests = contests.filter((contest) => {
          const startDate = new Date(contest.resultDate);
          const today = new Date();
          return startDate > today;
        });
        this.stats[0].count = contests.length;
      } catch (error) {
        handleError(error);
      }
    },
  },
};
</script>
  
  <style scoped>
.stats-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .stats-row {
    flex-wrap: wrap;
  }

  .stats-row > * {
    flex: 100%;
    margin-bottom: 10px;
  }
}
</style>