<template>
  <section>
    <h2 class="mb-3 text-center">About Us</h2>
    <p>
      PICS N CART is one of the leading marketplace, providing one stop solution
      for Photographer’s needs i.e. sale of World Class camera and accessory
      brands in India, Services, Tutorials by experts in the field, etc. Known
      for its competitive pricing, PICS N CART has taken consignment packaging
      and delivery standards to a whole new level. With customer satisfaction
      index throttling at its highest, PICS N CART continues to look at even the
      smallest of its deficits with complete attention.
    </p>
    <p>
      With more than 2000 products under its belt already, PICS N CART is
      continuously looking at adding more options to its retail segment.
    </p>
    <p>
      Realizing the needs of professional photographers and budding
      photographers, PICS N CART was started by an aspiring team of
      photographers, in the heart of a small town named Udupi, Karnataka. It has
      come a long way to become a leading photography equipment marketplace
      across the length and breadth of the country. This was possible mainly due
      to the focused customer care PICS N CART offered to each of its customers,
      be it big or small. With many plans in the bag, PICS N CART is all geared
      up to establish its retail segment online and reach out to the remotest
      corners of the country.
    </p>

    <p>
      PICS N CART envisages a future where its customers have easy access to
      products from popular brands available all across the globe. An idea to
      provide a One-Stop solution for photography equipment at competitive
      prices has been a long standing moto of PICS N CART.
    </p>
    <h3>Our Services</h3>
    <ul>
      <li>Cameras and accessories</li>
      <li>Photography tutorials</li>
      <li>Photography competition</li>
      <li>Pages for photographer's profile</li>
    </ul>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  p {
    text-align: justify;
  }
}
</style>