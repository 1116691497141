<template>
  <div class="photographers-list">
    <div
      class="photographer-card text-center mr-4 mb-5"
      @click="handleAddProfile()"
    >
      <v-icon color="primary" size="48" class="add-profile-icon"
        >mdi-account-plus</v-icon
      >
      <h4 class="mt-5">Add profile</h4>
    </div>
    <photographer-card
      v-for="(photographer, index) in photographers"
      :photographer="photographer"
      :class="`${(index + 1) % 4 === 0 ? '' : 'mr-4'} mb-5`"
      :key="index"
      class="mr-3"
    ></photographer-card>
  </div>
</template>

<script>
import PhotographerCard from "../PhotographerCard.vue";
export default {
  props: ["photographers", "user"],
  components: { PhotographerCard },
  data() {
    return {
      // width: "23%",
    };
  },
  created() {
    this.getPhotographers();
  },
  methods: {
    async getPhotographers() {},
    handleAddProfile() {
      if (!this.user) {
        this.$router.push("/signup");
      } else {
        const { roles } = this.user;
        if (roles[0].role_name === "photographer") {
          this.$router.push(`/photographer?id=${this.user.user_id}`);
        } else {
          this.$router.push("/home");
        }
      }
    },
  },
};
</script>

<style scoped>
.photographers-list {
  display: flex;
  flex-wrap: wrap;
}

.photographer-card {
  cursor: pointer;
}

.add-profile-icon {
  border: 1px solid #0597f2 !important;
  border-radius: 50%;
  padding: 1rem;
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 600px) {
  .photographers-list {
    margin-top: 1rem !important;
  }

  .photographer-card {
    width: 50% !important;
    height: 150px;
    margin-right: 0 !important;
  }
}
</style>