<template>
  <v-dialog v-model="show" width="400" persistent>
    <v-card>
      <v-card-title>
        Confirmation
        <v-spacer></v-spacer>
        <v-btn color="red" icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>{{
        text ? text : "Are you sure want to perform this action?"
      }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$emit('confirm')">{{
          btnText ? btnText : "Confirm"
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["visible", "text", "btnText"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          return this.$emit("close");
        }
      },
    },
  },
};
</script>

<style>
</style>