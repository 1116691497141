<template>
  <div class="home">
    <div class="banner">
      <banner></banner>
    </div>
    <div class="about-us">
      <about-us></about-us>
    </div>
    <!-- <div class="services">
      <services></services>
    </div> -->
  </div>
</template>

<script>
import AboutUs from "./AboutUs.vue";
import Banner from "./Banner.vue";
// import Services from "./Services.vue";

export default {
  name: "Home",
  components: {
    Banner,
    AboutUs,
    // Services
  },
};
</script>

<style scoped>
.banner,
.about-us {
  padding: 0 3rem;
  margin-top: 2rem;
}

@media screen and (max-width: 600px) {
  .banner,
  .about-us {
    padding: 0 1rem;
    margin-top: 1rem;
  }
}
</style>
