import { apis } from "../../common/api/api.constants";
import { apiClient } from "../../common/api/api.middleware";
import { NOTIFICATION_TYPE, PICSNCART_LOGO_URL } from "../../common/constants";
import { showToastNotification } from "../../plugins/vue-toast-notifications";

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const displayRazorpay = (data) => {
  return new Promise((resolve, reject) => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js")
      .then((res) => {
        if (!res) {
          showToastNotification(
            NOTIFICATION_TYPE.ERROR,
            "Razorpay SDK failed to load. Are you online?"
          );
          reject(new Error("Razorpay SDK failed to load."));
          return;
        }

        const orderData = {
          amount: parseInt(data.amount) * 100,
          receipt: data.receipt,
        };

        apiClient
          .post(apis.razorpayCreateOrder, orderData)
          .then((response) => {
            const order = response.data.data;

            const options = {
              key: process.env.VUE_APP_RAZORPAY_KEY_ID,
              currency: data.currency,
              amount: data.amount.toString(),
              order_id: order.id,
              name: "Pics N Cart",
              description: "Payment for your order",
              image: PICSNCART_LOGO_URL,
              prefill: {
                name: data.name,
                email: data.email,
                contact: data.contact,
              },
              theme: {
                color: "#0597F2",
              },
              handler: function (response) {
                resolve({
                  status: "success",
                  message: "Payment successful",
                  data: {
                    payment: response,
                    order: order,
                  },
                });
              },
              modal: {
                ondismiss: function () {
                  reject(new Error("Payment dismissed."));
                },
              },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const verifyRazorpayPayment = async (data) => {
  const response = await apiClient.post(apis.razorpayVerifyPayment, data);
  return response.data;
};

export { displayRazorpay, verifyRazorpayPayment };
