<template>
  <v-data-table
    :headers="headers"
    :items="photographers"
    :items-per-page="10"
    class="elevation-1"
    :search="search"
    mobile-breakpoint="0"
  >
    <template v-slot:[`item.logo_url`]="{ item }">
      <v-avatar color="primary" size="36">
        <img :src="item.logo_url" :alt="item.name" v-if="item.logo_url" />
        <span class="white--text text-h6" v-else>{{
          item.name.charAt(0)
        }}</span>
      </v-avatar>
    </template>
    <template v-slot:[`item.is_verified`]="{ item }">
      <v-chip :color="getAccountVerificationColor(item)">
        {{ getAccountVerificationStatus(item) }}
      </v-chip>
      <v-chip v-if="item.is_blocked" color="error" class="ml-2" outlined
        >Blocked</v-chip
      >
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="black" icon dark v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="view(item.user_id)">
            <v-list-item-title>View</v-list-item-title>
          </v-list-item>
          <v-list-item @click="convertUser(item)">
            <v-list-item-title>{{
              item.is_photographer
                ? "Convert to user"
                : "Convert to photographer"
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="verifyUser(item)" v-if="!item.is_verified">
            <v-list-item-title>Verify</v-list-item-title>
          </v-list-item>
          <v-list-item @click="blockUnblock(item)" v-if="item.is_blocked">
            <v-list-item-title>Un block</v-list-item-title>
          </v-list-item>
          <v-list-item @click="blockUnblock(item)" v-else>
            <v-list-item-title>Block</v-list-item-title>
          </v-list-item>
          <v-list-item @click="_delete(item)">
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["photographers", "user"],
  data() {
    return {
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "logo_url",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Email", value: "email_address" },
        { text: "Mobile Number", value: "phone_number" },
        { text: "Ac status", value: "is_verified" },
        { text: "Actions", value: "actions" },
      ],
      search: "",
    };
  },
  methods: {
    view(id) {
      this.$router.push(`/photographer?id=${id}`);
    },
    _delete(user) {
      this.$emit("delete", user);
    },
    getAccountVerificationColor(item) {
      const isVerified = item.is_verified;
      return isVerified ? "success" : "warning";
    },
    getAccountVerificationStatus(item) {
      const isVerified = item.is_verified;
      return isVerified ? "Verified" : "Pending";
    },
    blockUnblock(user) {
      this.$emit("blockUnblock", user);
    },
    verifyUser(user) {
      this.$emit("verifyUser", user);
    },
    convertUser(user) {
      this.$emit("convertUser", user);
    },
  },
};
</script>

<style scoped>
</style>