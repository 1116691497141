<template>
  <v-form ref="form">
    <h1 class="text-center mb-4">Verify OTP</h1>
    <v-text-field
      label="Phone number"
      required
      outlined
      prepend-inner-icon="mdi-phone"
      type="text"
      v-model="phone_number"
      prefix="+91"
      disabled
    ></v-text-field>
    <label>Enter the OTP</label>
    <v-otp-input length="6" type="number" v-model="otp"></v-otp-input>
    <v-btn
      color="primary"
      class="d-block mx-auto mt-5 verify-otp-btn"
      @click="verifyOtp()"
      :loading="loading"
      :disabled="loading"
      >Verify OTP</v-btn
    >
    <!-- Retry OTP with timer -->
    <div class="text-right mt-5">
      Didn't receive OTP?
      <v-btn
        text
        @click="$emit('resend-otp')"
        color="primary"
        :disabled="localCounter > 0"
        >Resend OTP</v-btn
      >
      <small>in {{ localCounter }} sec</small>
    </div>

    <!-- <v-dialog v-model="photographerDialog" width="500">
      <v-card>
        <v-card-title
          >Are you a photographer?
          <v-btn
            icon
            @click="handlePhotographerCheckboxDialogClose"
            color="red"
            class="ml-auto"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-checkbox
            label="I am a photographer"
            v-model="isPhotographer"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="handlePhotographerCheckboxSave()"
            class="ml-auto"
            :loading="loading"
            >Save and Login</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-form>
</template>

<script>
import { apis } from "../../common/api/api.constants";
import { apiClient, handleError } from "../../common/api/api.middleware";
import { NOTIFICATION_TYPE } from "../../common/constants";
import { showToastNotification } from "../../plugins/vue-toast-notifications";
// import { setAccountAndRedirect } from "../../utils/common.utils";

export default {
  props: ["phone_number", "counter", "email", "password"],
  data() {
    return {
      otp: "",
      loading: false,
      localCounter: this.counter,
      // photographerDialog: false,
      // isPhotographer: false,
    };
  },
  computed: {
    otpCounter() {
      return this.localCounter;
    },
  },
  watch: {
    counter(newCounter) {
      // Update the local counter when the prop changes
      this.localCounter = newCounter;

      // Start the countdown when the prop changes
      this.startCountdown();
    },
  },
  methods: {
    async verifyOtp() {
      if (!this.otp) {
        return showToastNotification(
          "OTP cannot be empty",
          NOTIFICATION_TYPE.WARNING
        );
      }
      const requestBody = {
        phone_number: `+91${this.phone_number}`,
        code: this.otp,
      };
      try {
        this.loading = true;
        const response = await apiClient.post(
          `${apis.userAccountVerify}?verification_method=otp`,
          requestBody
        );
        if(response) {
          this.loading = false;
          showToastNotification(response.data.message, NOTIFICATION_TYPE.SUCCESS);
          this.$emit('verified');
        }
      } catch (error) {
        handleError(error);
        this.loading = false;
      }
    },
    startCountdown() {
      const countdown = setInterval(() => {
        if (this.localCounter > 1) {
          this.localCounter--;
        } else {
          clearInterval(countdown); // Stop the countdown when it reaches 0
        }
      }, 1000);
    },
    // handlePhotographerCheckboxDialogClose() {
    //   this.handlePhotographerCheckboxSave();
    // },
    // async handlePhotographerCheckboxSave() {
    //   try {
    //     this.loading = true;
    //     const requestBody = {
    //       email_address: this.email,
    //       password: this.password,
    //     };
    //     const response = await apiClient.post(apis.loginUser, requestBody);
    //     showToastNotification(
    //       "Login successful. Redirecting...",
    //       NOTIFICATION_TYPE.SUCCESS,
    //       "top",
    //       500
    //     );
    //     const { user } = response.data.data;

    //     await apiClient.put(
    //       `${apis.userModule}/${user.user_id}`,
    //       {
    //         is_photographer: this.isPhotographer,
    //         user_id: user.user_id,
    //       }
    //     );

    //     this.loading = false;
    //     this.photographerDialog = false;
    //     // pass user data and redirect from url if present
    //     setAccountAndRedirect(response.data.data, this.$route.query.redirect);
    //   } catch (error) {
    //     this.loading = false;
    //     this.photographerDialog = false;
    //     handleError(error);
    //   }
    // },
  },
  mounted() {
    // Start the countdown when the component is initially rendered
    this.startCountdown();
  },
};
</script>

<style scoped>
form {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2rem;
  width: 500px;
  margin: 2rem auto 0 auto;
}
.verify-otp-btn {
  width: 200px;
}
</style>