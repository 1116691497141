<template>
  <section>
    <v-form class="form" ref="form">
      <h1 class="text-center my-5">Reset your Password</h1>
      <v-text-field
        label="Enter your email address"
        outlined
        :rules="[rules.required]"
        v-model="emailAddress"
        disabled
        dense
        type="email"
        v-if="!user"
      ></v-text-field>
      <v-text-field
        label="Enter new password"
        outlined
        :rules="[rules.required, rules.password]"
        v-model="password"
        dense
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      ></v-text-field>
      <v-text-field
        label="Confirm new password"
        outlined
        :rules="[rules.required, rules.confirmPassword]"
        v-model="confirmPassword"
        dense
        :type="showConfirmPassword ? 'text' : 'password'"
        @click:append="showConfirmPassword = !showConfirmPassword"
        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
      ></v-text-field>
      <v-btn
        color="primary"
        class="mx-auto d-block"
        block
        @click="resetPassword()"
        :disabled="loading"
        :loading="loading"
        >Reset Password</v-btn
      >
    </v-form>
  </section>
</template>
  
  <script>
import { mapState } from "pinia";
import { apiClient, handleError } from "../../common/api/api.middleware";
import { NOTIFICATION_TYPE } from "../../common/constants";
import { showToastNotification } from "../../plugins/vue-toast-notifications";
import { useAuthStore } from "../../store/auth.store";
import { apis } from "../../common/api/api.constants";

export default {
  data() {
    return {
      emailAddress: null,
      rules: {
        required: (value) => !!value || "This field is required",
        password: (value) => {
          return (
            // password should be at least 6 characters long and should contain at least one number and one alphabet and can contain special characters
            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&+-/_(){}=^:;'"]{6,}$/.test(value) ||
            "Password should be at least 6 characters long and should contain at least one number and one alphabet"
          );
        },
        confirmPassword: (value) => {
          return value === this.password || "Passwords does not match";
        },
      },
      loading: false,
      password: null,
      confirmPassword: null,
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["user"]),
  },
  created() {
    // this.logout();
    this.emailAddress =
      this.$route.query.email_address || this.user.email_address;
  },
  methods: {
    logout() {
      const authStore = useAuthStore();
      authStore.user = null;
      localStorage.removeItem("pcn_access_token");
    },
    async resetPassword() {
      if (!this.$refs.form.validate()) return;
      if (this.password !== this.confirmPassword) {
        showToastNotification(
          "Passwords does not match",
          NOTIFICATION_TYPE.WARNING
        );
        return;
      }
      try {
        this.loading = true;
        await apiClient.post(apis.userAccountResetPassword, {
          emailAddress: this.emailAddress,
          password: this.password,
        });
        showToastNotification(
          "Password reset successfull. Please login to access your account",
          NOTIFICATION_TYPE.SUCCESS
        );
        this.loading = false;
        this.password = "";
        this.confirmPassword = "";
        setTimeout(() => {
          this.$router.push("/");
        }, 1000);
      } catch (error) {
        this.loading = false;
        handleError(error);
      }
    },
  },
};
</script>
  
<style scoped>
form {
  width: 500px;
  margin: 2rem auto 0 auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2rem;
}

.v-btn {
  width: 200px;
}

@media screen and (max-width: 600px) {
  form {
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
  }
}
</style>