<template>
  <Loader v-if="loading" />
  <div
    class="pcn-photographer-profile-container"
    id="pcn-photographer-profile-container"
    @contextmenu.prevent
    v-else
  >
    <img :src="getCoverPhoto()" alt="Cover Photo" class="cover-photo" />
    <img :src="getProfilePhoto()" alt="Profile photo" class="profile-photo" />
    <div class="details-container">
      <div class="row__1 d-flex justify-space-between align-center">
        <h1 class="">
          {{
            photographerData && photographerData.name
              ? photographerData.name
              : ""
          }}
        </h1>
        <!-- <v-rating
          color="warning"
          v-model="photographerRating"
          dense
          readonly
          half-increments
        ></v-rating> -->
      </div>
      <v-chip
        class="mt-3"
        color="primary"
        label
        outlined
        v-if="
          photographerData &&
          photographerData.business_name &&
          userModule &&
          userModule.is_photographer
        "
      >
        <v-icon class="mr-1">mdi-domain</v-icon>
        {{ photographerData.business_name }}
      </v-chip>
      <div class="row__2 my-3">
        <p
          class="no-data"
          v-if="
            photographerData &&
            photographerData.description &&
            userModule &&
            userModule.is_photographer
          "
        >
          {{ photographerData.description }}
        </p>
      </div>
      <div class="row__3 profile-details">
        <!-- <h3>
          <span>{{
            photographerData && photographerData.photography_types
              ? photographerData.photography_types.toString()
              : "N/A"
          }}</span>
        </h3> -->
        <div
          class="photography-types mt-5"
          v-if="
            photographerData &&
            photographerData.photography_types &&
            userModule &&
            userModule.is_photographer
          "
        >
          <h3 class="mb-2">Expertise</h3>
          <v-chip
            v-for="(type, index) in photographerData.photography_types"
            :key="index"
            class="mr-2 mb-2"
            color="primary"
            label
          >
            {{ type }}
          </v-chip>
        </div>

        <div
          class="photography-starting-price mt-5"
          v-if="
            photographerData &&
            photographerData.starting_price &&
            userModule &&
            userModule.is_photographer
          "
        >
          <h3>Starting price - Rs. {{ photographerData.starting_price }}</h3>
        </div>

        <div
          class="contact-details mt-5"
          v-if="(user && user.user_id) || showContactDetails"
        >
          <h3 class="mb-2">Contact details</h3>
          <p>
            <v-icon class="mr-1">mdi-phone</v-icon>
            {{
              photographerData && photographerData.phone_number
                ? photographerData.phone_number
                : "N/A"
            }}
          </p>
          <p>
            <v-icon class="mr-1">mdi-email</v-icon>
            {{
              photographerData && photographerData.email_address
                ? photographerData.email_address
                : "N/A"
            }}
          </p>
          <p>
            <v-icon class="mr-1">mdi-map-marker</v-icon>
            {{
              photographerData && photographerData.address
                ? photographerData.address
                : "N/A"
            }}
          </p>
        </div>
        <div class="contact-details mt-5" v-else>
          <v-btn
            color="secondary"
            @click="handleViewContactDetails()"
            text
            class="pl-0"
            >View contact details
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
        <div
          class="profile-completion-rate-container mt-5"
          v-if="
            user &&
            user.user_id == $route.query.id &&
            userModule &&
            userModule.is_photographer
          "
        >
          <v-progress-linear
            v-if="userModule && userModule.is_photographer"
            :value="profileCompletionRate"
            color="amber"
            height="25"
            class="mt-2"
          >
            <p class="mb-0">
              <strong> {{ profileCompletionRate }}% </strong>
              Profile completed
            </p>
          </v-progress-linear>
        </div>
      </div>
    </div>
    <v-btn
      outlined
      color="primary"
      class="edit-profile-btn"
      id="edit-photographer-profile-btn"
      @click="showEditProfileModal = true"
      v-if="user && user.user_id == $route.query.id"
    >
      <v-icon>mdi-square-edit-outline</v-icon>
      Edit profile
    </v-btn>
    <!-- <div class="profile-container">
    </div> -->
    <div class="tabs-container mt-8">
      <v-tabs grow dark background-color="primary" v-model="tab" show-arrows>
        <v-tab
          key="my-work"
          id="my-work-tab"
          v-if="userModule && userModule.is_photographer"
          >Gallery</v-tab
        >
        <v-tab
          key="registered-contests"
          v-if="iamTheOwner"
          id="registered-contests-tab"
          >Registered contests</v-tab
        >
        <!-- <v-tab key="coupons" v-if="iamTheOwner" id="coupons-tab">Coupons</v-tab> -->
        <v-tab key="achievements" id="achievements-tab">Achivements</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          key="my-work"
          v-if="userModule && userModule.is_photographer"
        >
          <photographer-work :user="user" />
        </v-tab-item>
        <v-tab-item key="registered-contests" v-if="iamTheOwner">
          <photographer-contests :contests="registeredContests" />
        </v-tab-item>
        <!-- <v-tab-item key="coupons" v-if="iamTheOwner">
          <photographer-contests :contests="coupons" />
        </v-tab-item> -->
        <v-tab-item key="achievements">
          <photographer-contests :contests="coupons" />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <edit-photographer-modal
      :visible="showEditProfileModal"
      @close="showEditProfileModal = false"
      :data="photographerData"
      @update="updatePhotographer"
      :loading="profileUpdateLoading"
    />
    <walkthough
      :steps="tourData.steps"
      :name="tourData.name"
      ref="walkthough"
      @mounted="handleWalkthroughMounted"
    ></walkthough>
    <data-capture-modal
      :visible="showDataCaptureModal"
      @close="showDataCaptureModal = false"
      @success="handleViewContactDetailsSuccess"
    ></data-capture-modal>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { apiClient, handleError } from "../../../common/api/api.middleware";
import { NOTIFICATION_TYPE } from "../../../common/constants";
import { getUiAvatar } from "../../../utils/common.utils";
import { useAuthStore } from "../../../store/auth.store";
import PhotographerWork from "./PhotographerWork.vue";
import PhotographerContests from "./PhotographerContests.vue";
import EditPhotographerModal from "../../../components/modals/photographer/EditPhotographerModal.vue";
import { uploadFile } from "../../../helpers/file-upload";
import { showToastNotification } from "../../../plugins/vue-toast-notifications";
import Loader from "../../../components/shared/Loader.vue";
import { apis } from "../../../common/api/api.constants";
import { useUserModuleStore } from "../../../store/userModule.store";
import {
  competitionsRef,
  query,
  where,
  getDocs,
  contestsRef,
  getDoc,
  doc,
} from "../../../config/firebase";
import Walkthough from "../../../components/shared/Walkthough.vue";
import { TOURS } from "../../../utils/tour.util.js";
import DataCaptureModal from "../../../components/shared/DataCaptureModal.vue";

export default {
  components: {
    PhotographerWork,
    PhotographerContests,
    EditPhotographerModal,
    Loader,
    Walkthough,
    DataCaptureModal,
  },
  data() {
    return {
      defaultCoverPhoto:
        "https://firebasestorage.googleapis.com/v0/b/picsncart-5772f.appspot.com/o/photographers%2Fdefault-cover.jpg?alt=media&token=ddeb2e79-550a-428f-a257-980337043a4a",
      photographerData: {},
      tab: 0,
      upcomingContests: [],
      coupons: [],
      showEditProfileModal: false,
      profileUpdateLoading: false,
      photographerRating: 3.5,
      pastContests: [],
      loading: false,
      iamTheOwner: false,
      registeredContests: [],
      tourData: TOURS.PHOTOGRAPHER_PROFILE,
      profileCompletionRate: 0,
      totalPhotographerProfileFields: 14,
      showDataCaptureModal: false,
      showContactDetails: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["user"]),
    ...mapState(useUserModuleStore, ["userModule"]),
  },
  created() {
    this.getPhotographerDetails();
    this.checkIfUserIsTheOwner();
    this.getUserModules();
    if (this.user && this.user.user_id == this.$route.query.id) {
      this.getRegisteredContests();
    }
  },
  watch: {
    userModule() {
      this.handleWalkthroughMounted();
    },
  },
  methods: {
    checkIfUserIsTheOwner() {
      this.iamTheOwner = this.user && this.user.user_id == this.$route.query.id;
    },
    async getPhotographerDetails() {
      const userId = this.$route.query.id;
      try {
        this.loading = true;
        const response = await apiClient.get(`${apis.photographers}/${userId}`);
        this.photographerData = response.data.data;
        this.photographerData.photography_types =
          this.photographerData.photography_types?.split(",");
        this.loading = false;
        this.calculateProfileCompletionRate();
      } catch (error) {
        this.loading = false;
        handleError(error);
      }
    },
    getProfilePhoto() {
      return this.photographerData && this.photographerData.logo_url
        ? this.photographerData.logo_url
        : getUiAvatar(this.photographerData.name);
    },
    getCoverPhoto() {
      return this.photographerData && this.photographerData.cover_url
        ? this.photographerData.cover_url
        : this.defaultCoverPhoto;
    },
    async updatePhotographer(data) {
      const photographer = data;
      try {
        if (photographer.description && photographer.description.length > 200) {
          return showToastNotification(
            "Description can only have 200 characters",
            NOTIFICATION_TYPE.WARNING
          );
        }
        this.profileUpdateLoading = true;
        if (
          photographer.logo_url &&
          typeof photographer.logo_url === "object"
        ) {
          photographer.logo_url = await uploadFile(
            photographer.logo_url,
            "photographers"
          );
        }

        if (
          photographer.cover_url &&
          typeof photographer.cover_url === "object"
        ) {
          photographer.cover_url = await uploadFile(
            photographer.cover_url,
            "photographers"
          );
        }
        await apiClient.put(apis.photographers, photographer);
        this.profileUpdateLoading = false;
        this.showEditProfileModal = false;
        showToastNotification("Profile updated.", NOTIFICATION_TYPE.SUCCESS);
        this.getPhotographerDetails();
      } catch (error) {
        handleError(error);
        this.profileUpdateLoading = false;
      }
    },
    handleViewContactDetails() {
      // showToastNotification(
      //   "Please login to view contact details",
      //   NOTIFICATION_TYPE.INFO
      // );

      // setTimeout(() => {
      //   window.location.href = `/login?redirect=/photographer?id=${this.$route.query.id}`;
      // }, 2000);

      this.showDataCaptureModal = true;
    },
    handleViewContactDetailsSuccess() {
      this.showContactDetails = true;
    },
    getUserModules() {
      const userModuleStore = useUserModuleStore();
      userModuleStore.getUserModuleByUserId(this.$route.query.id);
    },
    getRegisteredContests() {
      let contests = [];
      const q = query(
        competitionsRef,
        where("user_id", "==", this.user.user_id)
      );

      getDocs(q)
        .then(async (querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              contests.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            for (let [index, contest] of contests.entries()) {
              const docSnap = await getDoc(
                doc(contestsRef, contest.contest_id)
              );
              let data = {};
              if (docSnap.exists()) {
                data = docSnap.data();
              } else {
                data = { title: "Contest not found" };
              }
              contests[index] = {
                ...contest,
                title: data.title,
                type: data.type,
              };
            }
            this.registeredContests = contests;
          }
        })
        .catch((error) => {
          handleError(error);
        });
    },
    handleWalkthroughMounted() {
      if (
        this.user &&
        this.userModule &&
        this.userModule.is_photographer &&
        this.profileCompletionRate < 100
      ) {
        this.$refs.walkthough.$children[0].start();
      }
    },
    calculateProfileCompletionRate() {
      let fieldsFilled = 0;
      const fields = [
        "name",
        "business_name",
        "city",
        "description",
        "photography_types",
        "starting_price",
        "phone_number",
        "email_address",
        "address",
        "logo_url",
        "cover_url",
        "pincode",
        "gender",
        "date_of_birth",
      ];

      for (let field of fields) {
        if (this.photographerData[field]) {
          fieldsFilled += 1;
        }
      }

      this.profileCompletionRate = Math.floor(
        (fieldsFilled / this.totalPhotographerProfileFields) * 100
      );
    },
  },
};
</script>

<style scoped>
.pcn-photographer-profile-container {
  padding: 1rem 2rem 5rem 2rem;
  height: 500px;
  position: relative;
}

.profile-container {
  width: 100%;
  height: 100%;
  /* box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2); */
  padding: 1rem;
  /* display: flex; */
  /* position: relative; */
  /* align-items: center; */
  /* border-radius: 5px; */
}

.cover-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.profile-photo {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 2px solid #fff;
  bottom: 0%;
  left: 5%;
  object-fit: cover;
}

.details-container {
  margin-top: 3rem;
  /* width: 84%;
  margin: 8rem auto 0 auto;
  margin-left: 2rem; */
  padding: 3rem 1rem 1rem 1rem;
}

.edit-profile-btn {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
}

.row__1 {
}

.no-data {
  color: #000;
  opacity: 0.5;
}

.v-tab {
  text-transform: capitalize;
}

@media screen and (max-width: 600px) {
  .pcn-photographer-profile-container {
    padding: 1rem;
    height: auto;
  }

  .cover-photo {
    height: 200px;
  }

  .profile-photo {
    width: 110px;
    height: 110px;
    top: 10rem;
    left: 2rem;
  }

  .details-container {
    margin-top: 3rem;
    padding: 1rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  .edit-profile-btn {
    right: 1rem;
    top: 14rem;
    font-size: 12px;
  }

  h3 {
    font-size: 1rem;
  }

  .tabs-container {
    margin-top: 1rem !important;
  }
}
</style>