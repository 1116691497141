import { render, staticRenderFns } from "./Contests.vue?vue&type=template&id=492a1408&scoped=true"
import script from "./Contests.vue?vue&type=script&lang=js"
export * from "./Contests.vue?vue&type=script&lang=js"
import style0 from "./Contests.vue?vue&type=style&index=0&id=492a1408&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "492a1408",
  null
  
)

export default component.exports