export const TOURS = {
  PHOTOGRAPHER_PROFILE: {
    name: "photographerProfile",
    steps: [
      {
        target: "#pcn-photographer-profile-container",
        content:
          "This is your photography profile. You can view and edit your profile here. You can build your portfolio, view your reviews, and manage your bookings here.",
        title: "Photographer Profile",
      },
      {
        target: "#edit-photographer-profile-btn",
        content:
          "You can edit your profile here. Click the “Edit Profile” button to edit your profile.",
        title: "Edit Photographer Profile",
      },
      {
        target: "#my-work-tab",
        content:
          "This is your portfolio. You can view and manage your portfolio/albums here.",
        title: "My Work",
      },
      {
        target: "#registered-contests-tab",
        content:
          "This is where you can view and manage the contests you have registered for.",
        title: "Registered Contests",
      },
      {
        target: "#achievements-tab",
        content: "This is where you can view your achievements and badges.",
        title: "Achievements",
      },
    ],
  },
};
