<template>
  <v-carousel v-model="model" cycle hide-delimiter-background height="82vh">
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      :src="item.src"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>

export default {
  data: () => ({
    model: 0,
    items: [
      {
        src: 'https://firebasestorage.googleapis.com/v0/b/picsncart-5772f.appspot.com/o/images%2Fbanner-1.jpg?alt=media&token=e89d0f3b-b7b2-4000-a66a-f9557b16963a',
      },
      {
        src: 'https://firebasestorage.googleapis.com/v0/b/picsncart-5772f.appspot.com/o/images%2Fbanner-2.jpg?alt=media&token=29de342a-a542-4c2e-8ac9-eb71f60cb318',
      },
      {
        src: 'https://firebasestorage.googleapis.com/v0/b/picsncart-5772f.appspot.com/o/images%2Fbanner-3.jpg?alt=media&token=390fb044-39a3-4aa0-b04a-b06d73af3da5',
      },
      {
        src: 'https://firebasestorage.googleapis.com/v0/b/picsncart-5772f.appspot.com/o/images%2Fbanner-4.jpg?alt=media&token=7aa03f01-78f7-4cf3-a0a1-193aa4844faf',
      },
    ],
  }),
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .v-carousel {
  }
}
</style>