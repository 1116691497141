<template>
  <v-dialog v-model="show" width="400" persistent>
    <v-card>
      <v-card-title>
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn color="red" icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="showOtpView">
        <v-otp-input length="6" type="number" v-model="otp"></v-otp-input>
      </v-card-text>
      <v-card-text v-else>
        <v-form ref="form">
          <v-text-field
            label="Enter your phone number"
            type="text"
            outlined
            dense
            v-model="phoneNumber"
            :rules="[rules.required, rules.phoneNumber]"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="handleBtnClick()"
          :loading="loading"
          :disabled="loading"
          >{{ btnText }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
import { apis } from "../../common/api/api.constants";
import { apiClient, handleError } from "../../common/api/api.middleware";
import { NOTIFICATION_TYPE, RULES } from "../../common/constants";
import { showToastNotification } from "../../plugins/vue-toast-notifications";
export default {
  props: ["visible"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          return this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      title: "Enter your phone number",
      phoneNumber: "",
      otp: "",
      showOtpView: false,
      rules: {
        required: (value) => !!value || RULES.required,
        phoneNumber: (value) => {
          const pattern = /^[6-9]{1}[0-9]{9}$/;
          return pattern.test(value) || "Invalid mobile number";
        },
      },
      loading: false,
      btnText: "Send OTP",
    };
  },
  created() {
    this.showOtpView = false;
    this.phoneNumber = "";
    this.otp = "";
    this.loading = false;
    this.btnText = "Send OTP";
  },
  methods: {
    handleBtnClick() {
      if (this.showOtpView) {
        this.handleVerifyOtp();
      } else {
        this.handleSendOtp();
      }
    },
    async handleSendOtp() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;
        await apiClient.post(apis.sendVisitorOtp, {
          phoneNumber: `+91${this.phoneNumber}`,
        });
        this.loading = false;
        this.showOtpView = true;
        this.btnText = "Verify OTP";
      } catch (error) {
        this.loading = false;
        handleError(error);
      }
    },
    async handleVerifyOtp() {
      if (!this.phoneNumber || !this.otp) {
        showToastNotification(
          "Please enter phone number and OTP",
          NOTIFICATION_TYPE.WARNING
        );
        return;
      }
      try {
        this.loading = true;
        await apiClient.post(apis.verifyVisitorOtp, {
          phoneNumber: `+91${this.phoneNumber}`,
          otp: this.otp,
        });
        this.loading = false;
        this.show = false;
        this.$emit("success");
      } catch (error) {
        this.loading = false;
        handleError(error);
      }
    },
  },
};
</script>
  
<style scoped>
</style>