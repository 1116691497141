<template>
  <v-container class="pnc-photographer-container">
    <div
      class="header-container d-flex justify-space-between align-center mr-5"
    >
      <h2>All Users</h2>
    </div>
    <loader v-if="loading"></loader>
    <empty-state
      v-else-if="photographers.length === 0"
      :imgWidth="500"
    ></empty-state>
    <div class="mt-5" v-else>
      <photographer-list
        v-if="viewType === 'list'"
        :photographers="photographers"
        :user="user"
        class="mx-5"
        @delete="deletePhotographer"
        @blockUnblock="blockUnblockPhotographer"
        @verifyUser="verifyUser"
        @convertUser="convertUser"
      ></photographer-list>
      <div class="photographer-grid-view" v-if="viewType === 'grid'">
        <!-- <div class="photographer-filter-container">
            <photographer-filter></photographer-filter>
          </div> -->
        <div class="photographer-grid-container">
          <photographer-grid
            :photographers="photographers"
            class="mt-10"
          ></photographer-grid>
        </div>
      </div>
      <confirm-dialog
        :visible="showConfirmDialog"
        :text="confirmDialogText"
        :btn-text="confirmDialogBtnText"
        @close="clearUserVars"
        @confirm="handleConfirmDialogConfirm"
      ></confirm-dialog>
    </div>
  </v-container>
</template>
  
  <script>
import EmptyState from "../../components/shared/EmptyState.vue";
import PhotographerList from "../photographer/views/PhotographerList.vue";
import { mapState } from "pinia";
import { useAuthStore } from "../../store/auth.store";
import { apiClient, handleError } from "../../common/api/api.middleware";
import { showToastNotification } from "../../plugins/vue-toast-notifications";
import { NOTIFICATION_TYPE } from "../../common/constants";
import ConfirmDialog from "../../components/shared/ConfirmDialog.vue";
// import PhotographerFilter from "./PhotographerFilter.vue";
import Loader from "../../components/shared/Loader.vue";
import { apis } from "../../common/api/api.constants";
export default {
  components: {
    EmptyState,
    PhotographerList,
    ConfirmDialog,
    // PhotographerFilter,
    Loader,
  },
  data() {
    return {
      photographers: [],
      viewType: "list",
      showConfirmDialog: false,
      confirmDialogText: "",
      confirmDialogBtnText: "",
      activeUserId: null,
      loading: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["user"]),
  },
  created() {
    this.viewType = this.$route.query.view;
    this.getPhotographers();
  },
  methods: {
    async getPhotographers() {
      try {
        this.loading = true;
        const response = await apiClient.get(apis.photographers);
        const photographers = response.data.data;
        this.photographers = photographers.filter(
          (photographer) =>
            !photographer.is_photographer &&
            photographer.email_address !== "admin@picsncart.com"
        );
        if (!this.viewType) {
          this.$router.push({ query: { view: "list" } });
          this.viewType = "list";
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        handleError(error);
      }
    },
    async deletePhotographer(user) {
      this.activeUserId = user.user_id;
      this.confirmDialogText = `Are you sure want to delete ${user.name}?`;
      this.confirmDialogBtnText = "Delete user";
      this.showConfirmDialog = true;
    },
    async handleConfirmDialogConfirm() {
      try {
        await apiClient.delete(`${apis.users}/${this.activeUserId}`);
        showToastNotification(
          "User deleted successfully",
          NOTIFICATION_TYPE.SUCCESS
        );
        this.clearUserVars();
        this.getPhotographers();
      } catch (error) {
        handleError(error);
      }
    },
    clearUserVars() {
      this.showConfirmDialog = false;
      this.confirmDialogBtnText = "";
      this.confirmDialogText = "";
      this.activeUserId = null;
    },
    async blockUnblockPhotographer(user) {
      try {
        const url = user.is_blocked
          ? `/users/${user.user_id}/unblock`
          : `/users/${user.user_id}/block`;
        await apiClient.post(url, {
          is_blocked: user.is_blocked,
        });
        showToastNotification(
          `User ${user.is_blocked ? "unblocked" : "blocked"} successfully`,
          NOTIFICATION_TYPE.SUCCESS
        );
        this.getPhotographers();
      } catch (error) {
        handleError(error);
      }
    },
    async verifyUser(user) {
      const { user_id } = user;
      if (!user_id) return;

      try {
        await apiClient.post(`/users/${user_id}/verify`);
        showToastNotification(
          "User verified successfully",
          NOTIFICATION_TYPE.SUCCESS
        );
        if (
          window.confirm(
            "Do you want to send a verification success message to the user?"
          )
        ) {
          await apiClient.post(
            `/users/${user_id}/send-verification-success-message`
          );
          showToastNotification(
            "Verification success message sent successfully",
            NOTIFICATION_TYPE.SUCCESS
          );
        }
        this.getPhotographers();
      } catch (error) {
        handleError(error);
      }
    },
    async convertUser(user) {
      const { user_id } = user;
      if (!user_id) return;

      try {
        const requestBody = {
          is_photographer: !user.is_photographer,
        };
        await apiClient.post(`/users/${user_id}/convert`, requestBody);
        showToastNotification(
          "User converted successfully",
          NOTIFICATION_TYPE.SUCCESS
        );
        this.getPhotographers();
      } catch (error) {
        handleError(error);
      }
    },
  },
};
</script>
  
  <style scoped>
h2 {
  color: #4f4f4f;
  font-weight: 500;
}

>>> th {
  color: var(--primary-color) !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.photographer-grid-view {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.photographer-filter-container {
  width: 25%;
  margin-right: 2rem;
}

.photographer-grid-container {
  width: 100%;
}
</style>