<template>
  <section>
    <div
      class="contests-container py-5 px-2"
      v-if="contests && contests.length"
    >
      <v-expansion-panels>
        <v-expansion-panel v-for="(contest, i) in contests" :key="i">
          <v-expansion-panel-header>
            {{ contest.title }}
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <div class="" v-if="contestTypes[contest.type - 1].value === 1">
              <cool-light-box
                :items="contest.src"
                :index="index"
                @close="index = null"
                :useZoomBar="true"
                :fullScreen="true"
              >
              </cool-light-box>
              <div class="images-wrapper mt-5">
                <div
                  class="image"
                  v-for="(image, imageIndex) in contest.src"
                  :key="imageIndex"
                  @click="index = imageIndex"
                  :style="{ backgroundImage: 'url(' + image + ')' }"
                ></div>
              </div>
            </div>
            <div class="" v-if="contestTypes[contest.type - 1].value === 2">
              <video controls class="contest-video" :src="contest.src"></video>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <empty-state :imgWidth="500" v-else />
  </section>
</template>

<script>
import EmptyState from "../../../components/shared/EmptyState.vue";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import { CONTEST_TYPES } from "../../../common/constants";

export default {
  components: { EmptyState, CoolLightBox },
  props: ["contests"],
  data() {
    return {
      index: null,
      contestTypes: CONTEST_TYPES,
    };
  },
  methods: {},
};
</script>

<style scoped>
.images-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.images-wrapper .image {
  width: 200px;
  height: 200px;
  background-size: cover;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 20px;
}
video {
  width: 100%;
  height: 400px;
}
</style>