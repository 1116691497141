<template>
  <section>
    <otp-verify
      v-if="showOtpVerify"
      :phone_number="phoneNumber"
      :counter="otpCounter"
      @resend-otp="sendOtp()"
    />
    <v-form ref="form" @submit.prevent="handleLogin" v-else>
      <h1 class="text-center mb-4">Login</h1>
      <v-text-field
        label="Email address"
        required
        outlined
        prepend-inner-icon="mdi-email"
        type="email"
        v-model="credentials.email"
        :rules="emailRules"
      ></v-text-field>
      <v-text-field
        label="Password"
        required
        outlined
        prepend-inner-icon="mdi-lock"
        :type="show ? 'text' : 'password'"
        @click:append="show = !show"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="passwordRules"
        v-model="credentials.password"
      ></v-text-field>
      <v-btn
        color="primary"
        class="d-block mx-auto login-btn"
        :loading="isLoading"
        :disabled="isLoading"
        type="submit"
        >Login</v-btn
      >
      <div class="action-container mt-5">
        <div>
          Don't have an account?
          <v-btn link text color="primary" to="/signup" class="pl-0">Signup here</v-btn>
        </div>
        <div>
          <router-link to="/forgot-password" class="mb-2"
            >Forgot password?</router-link
          >
        </div>
      </div>
    </v-form>
  </section>
</template>

<script>
import loginRulesMixin from "../../mixins/rules/login-rules.mixin";
import { apiClient, handleError } from "../../common/api/api.middleware";
import { apis } from "../../common/api/api.constants";
import { showToastNotification } from "../../plugins/vue-toast-notifications";
import { NOTIFICATION_TYPE } from "../../common/constants";
import { setAccountAndRedirect } from "../../utils/common.utils";
import OtpVerify from "./OtpVerify.vue";

export default {
  components: { OtpVerify },
  mixins: [loginRulesMixin],
  data() {
    return {
      credentials: {
        email: null,
        password: null,
      },
      show: false,
      isLoading: false,
      phoneNumber: null,
      showOtpVerify: false,
      otpCounter: 60,
    };
  },
  methods: {
    async handleLogin() {
      if (!this.$refs.form.validate()) return;
      this.isLoading = true;
      try {
        const requestBody = {
          email_address: this.credentials.email,
          password: this.credentials.password,
        };
        const response = await apiClient.post(apis.loginUser, requestBody);
        showToastNotification(
          "Login successful. Redirecting...",
          NOTIFICATION_TYPE.SUCCESS,
          "top",
          500
        );

        // pass user data and redirect from url if present
        setAccountAndRedirect(response.data.data, this.$route.query.redirect);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        const { response } = error;
        if (
          response.data.message ===
          "Please verify your account by validating the otp sent to your phone number befor login."
        ) {
          showToastNotification(
            response.data.message,
            NOTIFICATION_TYPE.WARNING
          );

          setTimeout(async () => {
            // assign last 10 digits of phone number to phoneNumber
            this.phoneNumber = response.data.data[0].phone_number.slice(-10);
            await this.sendOtp(response.data.data[0].phone_number);
          }, 3000);
        } else {
          handleError(error);
        }
      }
    },
    async sendOtp(phoneNumber) {
      try {
        this.showOtpVerify = true;
        this.isLoading = true;
        const response = await apiClient.post(
          apis.sendAccountVerificationCode,
          {
            phone_number: phoneNumber,
          }
        );
        showToastNotification(response.data.message, NOTIFICATION_TYPE.SUCCESS);
        this.isLoading = false;
        this.otpCounter = 60;
      } catch (error) {
        this.isLoading = false;
        this.otpCounter = 0;
        handleError(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2rem;
  width: 500px;
  margin: 2rem auto 0 auto;
}
.login-btn {
  width: 200px;
}

a {
  /* color: #000 !important; */
}

.login-with-google-container {
  width: 500px;
  margin: 2rem auto 0 auto;
}

.google-signup-btn {
  width: 320px;
  display: flex;
  align-items: center;
  font-size: 18px;
  background-color: #4286f5;
  color: #fff;
  border-radius: 3px;
  padding: 5px;
  margin: 0 auto;
}

.google-signup-btn > img {
  width: 40px;
  margin-right: 10px;
}

@media screen and (max-width: 600px) {
  section {
    margin-top: 1.5rem;
  }

  form {
    width: 90%;
    margin-top: 0;
    padding: 1rem;
  }
  .login-with-google-container {
    width: 100%;
  }
  .google-signup-btn {
    width: 100%;
  }

  .action-container {
    font-size: 14px;
  }
}
</style>