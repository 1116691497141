<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card class="mt-5">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title> Settings </v-toolbar-title>
      </v-toolbar>
      <v-tabs :vertical="!isMobile()" show-arrows>
        <v-tab>
          <v-icon left> mdi-account </v-icon>
          Modules
        </v-tab>
        <v-tab>
          <v-icon left> mdi-lock </v-icon>
          Reset password
        </v-tab>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <modules :user="user"></modules>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <reset-password></reset-password>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import ResetPassword from "../auth/ResetPassword.vue";
import Modules from "./Modules.vue";
export default {
  props: ["user"],
  components: { Modules, ResetPassword },
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Settings",
        disabled: true,
        href: "settings",
      },
    ],
  }),
  methods: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style scoped>
.v-tab {
  justify-content: flex-start;
}

>>> .v-tabs-bar__content {
  border-right: 1px solid #e0e0e0;
}
</style>