<template>
  <section>
    <v-btn
      class="mt-5 upload-work-btn"
      color="primary"
      outlined
      v-if="user && user.user_id == $route.query.id"
      @click="uploadWorkModal = true"
      id="upload-work-btn"
    >
      <v-icon>mdi-cloud-upload</v-icon>Upload work</v-btn
    >
    <cool-light-box
      :items="allWorks"
      :index="index"
      @close="index = null"
      :useZoomBar="true"
      :fullScreen="true"
    >
    </cool-light-box>
    <empty-state v-if="allWorks.length === 0" :imgWidth="500"></empty-state>
    <div class="images-wrapper mt-5" v-else>
      <div
        class="image"
        v-for="(image, imageIndex) in allWorks"
        :key="imageIndex"
        @click="index = imageIndex"
        :style="{ backgroundImage: 'url(' + image.src + ')' }"
      >
        <div
          class="image-action"
          v-if="user.user_id == $route.query.id"
          @click="handleGalleryImageDelete(image)"
        >
          <v-btn color="red" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <photographer-work-modal
      :user="user"
      :visible="uploadWorkModal"
      @close="handleUploadWorkModalClose"
      @refetch="getPhotographerWorks"
    ></photographer-work-modal>
  </section>
</template>

<script>
import { handleError } from "../../../common/api/api.middleware";
import PhotographerWorkModal from "../../../components/modals/photographer/PhotographerWorkModal.vue";
import EmptyState from "../../../components/shared/EmptyState.vue";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import {
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  photographerWorksRef,
  query,
  where,
} from "../../../config/firebase";
import { showToastNotification } from "../../../plugins/vue-toast-notifications";
import { NOTIFICATION_TYPE } from "../../../common/constants";
export default {
  components: { EmptyState, PhotographerWorkModal, CoolLightBox },
  props: ["user"],
  data() {
    return {
      allWorks: [],
      uploadWorkModal: false,
      index: null,
    };
  },
  created() {
    this.getPhotographerWorks();
  },
  methods: {
    handleUploadWorkModalClose() {
      this.uploadWorkModal = false;
    },
    async getPhotographerWorks() {
      try {
        this.allWorks = [];
        const q = query(
          photographerWorksRef,
          where("user_id", "==", parseInt(this.$route.query.id)),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          this.allWorks.push({
            ...doc.data(),
            id: doc.id,
          });
        });
      } catch (error) {
        handleError(error);
      }
    },
    async handleGalleryImageDelete(image) {
      try {
        this.index = null;
        await deleteDoc(doc(photographerWorksRef, image.id));
        await this.getPhotographerWorks();
        showToastNotification(
          "Work deleted successfully",
          NOTIFICATION_TYPE.SUCCESS
        );
      } catch (error) {
        showToastNotification(
          "Failed to delete work. Please try again",
          NOTIFICATION_TYPE.ERROR
        );
      }
    },
  },
};
</script>

<style scoped>
.upload-work-btn {
  margin-left: auto;
  display: block;
}

.images-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.images-wrapper .image {
  width: 24%;
  height: 300px;
  background-size: cover;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 20px;
}

.image {
  position: relative;
}

.image-action {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.image:hover .image-action {
  display: block;
}

@media screen and (max-width: 600px) {
  .images-wrapper {
    width: 100%;
  }
  .images-wrapper .image {
    width: 30%;
    height: 120px;
  }
}
</style>