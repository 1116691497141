import Vue from "vue";
import App from "./App.vue";
import pinia from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./plugins/vue-toast-notifications";
import "./plugins/vue-tour";
import "./helpers/clarity";

import * as Sentry from "@sentry/vue";

import "./config/firebase";
import { SENTRY_ALLOWED_DOMAINS } from "./common/constants";

Vue.config.productionTip = false;

if (SENTRY_ALLOWED_DOMAINS.includes(window.location.hostname)) {
  Sentry.init({
    Vue,
    dsn: "https://c8961e90cd723b9c2bdcc1184dde9232@o4507420772925440.ingest.us.sentry.io/4507420777971712",
    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    environment: "production",
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [],
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

new Vue({
  pinia,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
