<template>
  <section>
    <img
      src="../../assets/images/empty-state.svg"
      alt="Empty state"
      :style="{ width: imgWidth ? `${imgWidth}px` : '100%' }"
    />
    <h4 class="text-center mt-2">{{ text ? text : "No data available" }}</h4>
  </section>
</template>

<script>
export default {
  props: ["imgWidth", "text"],
};
</script>

<style scoped>
img {
  /* width: 100%; */
  margin: 0 auto;
  display: block;
}

@media screen and (max-width: 600px) {
  img {
    width: 100% !important;
  }
}
</style>