<template>
  <div>
    <v-app-bar
      color="white accent-4"
      :elevation="user && user.roles[0].role_name !== 'admin' ? 1 : 0"
      :class="`${isSticky ? 'sticky' : ''}`"
      class="navbar-large-screen desktop-navbar"
    >
      <v-toolbar-title
        @click="goto('/')"
        v-if="!user || user.roles[0].role_name !== 'admin'"
      >
        <img
          src="../../assets/images/picsncart-logo.png"
          width="120px"
          class="logo"
        />
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <div class="nav-right d-flex">
        <div
          class="nav-right-items mr-2"
          v-if="!user || user.roles[0].role_name !== 'admin'"
        >
          <router-link text to="/" class="route-link"> Home </router-link>
          <router-link text to="/shop" class="route-link"> Shop </router-link>
          <router-link text to="/contests" class="route-link">
            Contests
          </router-link>
          <router-link text to="/photographers?view=grid" class="route-link">
            Photographers
          </router-link>
          <router-link text to="/tutorial" class="route-link">
            Courses
          </router-link>
        </div>
        <div class="nav-action-items" v-if="!user">
          <v-btn color="primary" class="mr-5 login-btn" link to="/login"
            >Login</v-btn
          >
          <v-btn color="primary" link to="/signup" class="sign-up-btn"
            >Sign Up</v-btn
          >
          <!-- <v-btn icon @click="drawer = !drawer">
            <v-icon>mdi-menu</v-icon>
          </v-btn> -->
        </div>
      </div>

      <div class="nav-action-items" v-if="user && user.user_id">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-badge color="red" content="0" offset-x="20" offset-y="20">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-bell</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>No notification found</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <!-- Use a button or any other clickable element as the activator -->
            <div v-bind="attrs" v-on="on">
              <v-img
                :src="user.logo_url"
                :alt="user.name"
                v-if="user && user.logo_url"
                class="user-display-pic"
              ></v-img>
              <v-avatar v-else>
                <img :src="getAvatarImg()" :alt="user.name" />
              </v-avatar>
            </div>
          </template>

          <!-- Menu content -->
          <v-list>
            <v-list-item
              @click="openProfile()"
              v-if="user && !user.is_photographer"
            >
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item @click="openSettings()">
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="sidebarMenu"
      app
      floating
      :permanent="sidebarMenu"
      v-if="user && user.roles[0].role_name === 'admin'"
      :mini-variant.sync="mini"
    >
      <v-list dense color="white" dark>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <div class="d-flex align-center">
                <v-img
                  alt="Pics N Cart Logo"
                  class="shrink mr-2"
                  contain
                  src="../../assets/images/picsncart-logo.png"
                  transition="scale-transition"
                  width="60"
                />
                <h2 class="black--text">Pics N Cart</h2>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list class="mt-10">
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.href"
        >
          <v-list-item-icon>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="white" dark dense class="mobile-navbar">
      <v-app-bar-nav-icon
        @click="mobileDrawer = true"
        color="primary"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>
        <img
          src="../../assets/images/picsncart-logo.png"
          width="120px"
          class="logo"
          @click="goto('/')"
        />
      </v-toolbar-title>
      <div v-if="user">
        <v-img
          :src="user.logo_url"
          :alt="user.name"
          v-if="user && user.logo_url"
          class="user-display-pic"
        ></v-img>
        <v-avatar v-else>
          <img :src="getAvatarImg()" :alt="user.name" />
        </v-avatar>
      </div>
      <v-btn color="primary" link to="/signup" v-else>Signup</v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="mobileDrawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="primary--text text--accent-4"
        >
          <v-list-item link to="/">
            <v-list-item-icon>
              <v-icon color="primary">mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/shop">
            <v-list-item-icon>
              <v-icon color="primary">mdi-cart</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Shop</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/contests">
            <v-list-item-icon>
              <v-icon color="primary">mdi-camera-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Contests</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/photographers">
            <v-list-item-icon>
              <v-icon color="primary">mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Photographers</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/tutorial">
            <v-list-item-icon>
              <v-icon color="primary">mdi-book-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Courses</v-list-item-title>
          </v-list-item>

          <v-list-item @click="openProfile()" v-if="user">
            <v-list-item-icon>
              <v-icon color="primary">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>

          <v-list-item @click="openSettings()" v-if="user">
            <v-list-item-icon>
              <v-icon color="primary">mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-btn
        color="primary"
        class="mx-1 mb-2 logout-btn-mbl"
        @click="logout()"
        v-if="user"
        >Logout</v-btn
      >
      <v-btn
        v-else
        color="primary"
        class="mx-1 mb-2 logout-btn-mbl"
        link
        to="/login"
        >Login</v-btn
      >
      <template v-slot:append>
        <!-- <img
          src="../../assets/images/picsncart-logo.png"
          width="120px"
          class="logo"
        /> -->
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { getUiAvatar, logoutAndRedirect } from "../../utils/common.utils";
import { ROLES } from "../../common/constants";
export default {
  props: ["user"],
  computed: {
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
  },
  data() {
    return {
      isSticky: false,
      sidebarMenu: true,
      items: [
        { title: "Dashboard", href: "/dashboard", icon: "mdi-home-outline" },
        {
          title: "Contests",
          href: "/contests",
          icon: "mdi-account-group-outline",
        },
        {
          title: "Photographers",
          href: "/photographers?view=list",
          icon: "mdi-camera-account",
        },
        {
          title: "Users",
          href: "/users",
          icon: "mdi-account-group",
        },
        { title: "Settings", href: "/settings", icon: "mdi-cog" },
      ],
      desktopDrawer: false,
      mobileDrawer: false,
      group: null,
    };
  },
  methods: {
    handleScroll() {
      const scrollPosition = window.scrollY;
      scrollPosition > 60 ? (this.isSticky = true) : (this.isSticky = false);
    },
    goto(path) {
      this.$router.push(path);
    },
    logout() {
      logoutAndRedirect();
    },
    openProfile() {
      if (this.user.roles[0].role_name === ROLES[1]) {
        this.$router.push(`/photographer?id=${this.user.user_id}`);
      } else if (this.user.roles[0].role_name === ROLES[0]) {
        this.$router.push(`/dashboard`);
      }
    },
    getAvatarImg() {
      return getUiAvatar(this.user.name);
    },
    openSettings() {
      this.$router.push("/settings");
    },
  },
};
</script>

<style scoped>
>>> .v-toolbar__content {
  height: 100px !important;
  padding: 20px 50px !important;
}

.logo {
  cursor: pointer;
}

header {
  height: 100% !important;
}

.sticky {
  height: 100px !important;
  position: fixed;
  top: 0;
  background: #fff;
  margin-top: 0 !important;
  z-index: 99;
  width: 100%;
  -webkit-animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
  animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
}

.nav-right-items {
  display: flex;
  align-items: center;
}

.route-link {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
}

.route-link:hover {
  color: var(--primary-color);
  text-decoration: none;
}

.mobile-navbar {
  display: none;
}

.desktop-navbar {
  display: block;
}

.user-display-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 1rem;
}

.nav-action-items {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 600px) {
  >>> .v-toolbar__content {
    padding: 20px 20px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .desktop-navbar {
    display: none;
  }

  .mobile-navbar {
    display: block;
  }

  .v-navigation-drawer {
    height: 100vh !important;
  }

  >>> .v-navigation-drawer__append {
    text-align: center !important;
  }

  .logout-btn-mbl {
    width: 90%;
  }
}

@media screen and (max-width: 960px) {
  .desktop-navbar {
    display: none;
  }

  .mobile-navbar {
    display: block;
  }

  >>> .v-toolbar__content {
    padding: 20px 20px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  >>> .v-navigation-drawer__append {
    text-align: center !important;
  }

  .logout-btn-mbl {
    width: 90%;
  }
}
</style>