export const apis = {
  users: "/users",
  loginUser: "/users/login",
  sendAccountVerificationCode: "/users/account/send-verification-code",
  userDetails: "/users/details",
  adminDashboardStats: "/admin/dashboard/stats",
  userAccountForgotPassword: "/users/account/forgot-password",
  photographers: "/photographers",
  userAccountVerify: "/users/account/verify",
  userAccountResetPassword: "/users/account/reset-password",
  razorpayCreateOrder: "/razorpay/create-order",
  razorpayVerifyPayment: "/razorpay/verify-payment",
  userModule: "/user-module",
  sendVisitorOtp: "/common/visitor/send-otp",
  verifyVisitorOtp: "/common/visitor/verify-otp",
};
