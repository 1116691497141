<template>
  <div
    class="photographer-card"
    :style="`width: ${width ? width : ''}`"
    @click="goto(photographer.user_id)"
  >
    <img
      :src="photographer.profile"
      :alt="photographer.name"
      v-if="photographer.profile"
    />
    <img
      :src="getImage(photographer)"
      class="profile-img"
      :alt="photographer.name"
      v-else
    />
    <h4 class="mt-3">{{ photographer.name }}</h4>
    <p>{{ photographer.company }}</p>
  </div>
</template>
  
  <script>
import { getUiAvatar } from "../../utils/common.utils";

export default {
  props: ["photographer", "width"],
  methods: {
    goto(id) {
      this.$router.push(`/photographer?id=${id}`);
    },
    getImage(item) {
      if (item.logo_url) return item.logo_url;
      else return getUiAvatar(item.name);
    },
  },
};
</script>
  
  <style scoped>
.photographer-card {
  /* width: 23.33%; */
  cursor: pointer;
  text-align: center;
  width: 158px;
}

img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

h4 {
  color: #151740;
}

p {
  color: #1d1b1b;
  opacity: 0.5;
}

@media only screen and (max-width: 600px) {
  .photographer-card {
    width: 50% !important;
    height: 150px;
    margin-right: 0 !important;
  }

  img {
    width: 100px;
    height: 100px;
  }

  h4 {
    /* font-size: 1.2rem; */
  }

  p {
    font-size: 0.8rem;
  }
}
</style>