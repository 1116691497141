<template>
  <div>
    <img src="../../assets/images/coming-soon.png" alt="" class="d-block mx-auto coming-soon-img" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .coming-soon-img {
    width: 100%;
  }
}
</style>