// import { REGEX_EMAIL } from "../../common/constants";

export default {
  data() {
    return {
      emailRules: [
        (v) => !!v || "Email or phone number is required",
        // (v) => REGEX_EMAIL.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
    };
  },
};
